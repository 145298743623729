import React from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";

const ReadingTypeFilter = ({ onChange, value }) => {
  
  const { readingTypeForExpectation } = useSelector(state => state.auth);
    
  const customStyles = {
    control: base => ({
      ...base,
      height: 40,
      minHeight: 40
    })
  };

  return (
    <FormGroup className="reading_select">
      <Select
        isClearable={true}
        placeholder="Select Reading Type"
        name="readingType"
        onChange={(obj) => { onChange({ readingType: obj && obj._id != null ? obj._id : "" })}}        
        value={ value? readingTypeForExpectation.find((item) => item._id === value) : ""}
        options={readingTypeForExpectation}
        getOptionLabel={(option) => option["description"]}
        getOptionValue={(option) => option["_id"]}
        styles={customStyles}
      />
    </FormGroup>
  );
};

export default ReadingTypeFilter;
