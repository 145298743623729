import React, { PureComponent } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
  Input
} from "reactstrap";
import userImage from "../../../files/user.png";
import BootstrapTable from 'react-bootstrap-table-next';
import ImageUploader from "react-images-upload";
import { connect } from "react-redux";
import moment from "moment";
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import isChecked from "../../../files/ic_privacy_policy.svg";
import isUnChecked from "../../../files/ic_privacy_policy_un_checked.svg";
import isNoSignature from "../../../files/ic_no_signature.svg";
import Swal from 'sweetalert2';
import LottieLoader from "../../LottieLoader";
import SignatureCanvas from "react-signature-canvas";
import ErrorDisplay from "../../Reusable/ErrorDisplay";
const _ = require("lodash");
class ProfileDetail extends PureComponent {
  constructor(props) {
    super(props);
    let confirmedLogs = [];
    if (this.props.profile && this.props.profile.confirmedLogs && this.props.profile.confirmedLogs.length > 0) {
      confirmedLogs = this.props.profile.confirmedLogs;
    }
    const results = _.chain(confirmedLogs)
      .groupBy("key")
      .map(users => ({
        key: users[0].key,
        status: _.first(_.orderBy(users, ['createdAt'], ['desc'])).status,
      }))
      .value();
    // Signature define
    var signatureLink = this.props.profile ? this.props.profile.signatureImage : undefined;
    if (signatureLink && !signatureLink.indexOf("http") == 0) {
      signatureLink = "/" + signatureLink
    }
    const profileSignature = signatureLink ? signatureLink : undefined;
    this.state = {
      fname: this.props.profile ? this.props.profile.fname : null,
      lname: this.props.profile ? this.props.profile.lname : null,
      nickname: this.props.profile ? this.props.profile.nickname : null,
      username: this.props.profile ? this.props.profile.username : null,
      email: this.props.profile ? this.props.profile.email : null,
      phone: this.props.profile ? this.props.profile.phone : null,
      homeNumber: this.props.profile ? this.props.profile.homeNumber : null,
      workNumber: this.props.profile ? this.props.profile.workNumber : null,
      fax: this.props.profile ? this.props.profile.fax : null,
      address: this.props.profile ? this.props.profile.address : null,
      city: this.props.profile ? this.props.profile.city : null,
      state: this.props.profile ? this.props.profile.state : null,
      zip: this.props.profile ? this.props.profile.zip : null,      
      gender: this.props.profile ? this.props.profile.gender : null,
      bday: this.props.profile ? this.props.profile.bday : null,
      pin: this.props.profile ? this.props.profile.pin : null,      
      profileImage:
        this.props.profile && this.props.profile.profileImage
          ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + this.props.profile.profileImage
          : null,      
      isAgreedAggrement: this.props.profile ? this.props.profile.isAgreedAggrement : false,
      isAgreedPrivacyPolicy: this.props.profile ? this.props.profile.isAgreedPrivacyPolicy : false,
      signatureImage: profileSignature,
      isEditable: true,
      errorSignature: undefined,
      baseSignature: undefined,
      errorEmail: undefined,
      errorFname: undefined,
      errorLname: undefined,
      errorNickName: undefined,
      errorCellularPhoneNumber: undefined,
      errorAlternatePhoneNumber: undefined,
      errorWorkNumber: undefined,
      errorFaxNumber: undefined,
      errorStreetAddress: undefined,
      errorCity: undefined,
      errorState: undefined,
      errorZipCode: undefined,
      uploadImage : null
    };
    this.submitUser = this.submitUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clickViewLogs = this.clickViewLogs.bind(this);
    this.sigCanvas = React.createRef();    
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  async onDrop(pictureFiles, pictureDataURLs) {
    try {
      const fileBase64 = await this.getBase64(pictureFiles[0]);

      this.setState({
        profileImage: fileBase64,
        uploadImage : pictureFiles[0]
      });
    } catch (error) {
      this.setState({
        profileImage: null,
        uploadImage : null
      });
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    const phoneNumberRegex = /^\+?[0-9]{9,15}$/;
    const faxNumberRegex = /^[0-9]+$/;
    switch (name) {
      case "email":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorEmail: "Please enter a valid email"
          });
        } else {
          this.setState({
            errorEmail: undefined
          });
        }
        break;
      case "fname":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorFname: "Please enter a first name"
          });
        } else if (value.trim().length < 4 || value.trim().length > 150) {
          this.setState({
            errorFname: 'The first name must be between 4 and 150 characters.'
          });
        } else {
          this.setState({
            errorFname: undefined
          });
        }
        break;
      case "lname":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorLname: "Please enter a last name"
          });
        } else if (value.trim().length < 4 || value.trim().length > 150) {
          this.setState({
            errorLname: 'The last name must be between 4 and 150 characters.'
          });
        } else {
          this.setState({
            errorLname: undefined
          });
        }
        break;
      // case "nickname":
      //   if (value === undefined || value === null || value.trim().length > 0) {
      //     this.setState({
      //       errorNickName: "Please enter a nick name"
      //     });
      //   } else if (value.trim().length <= 4 || value.trim().length >= 150) {
      //     this.setState({
      //       errorNickName: 'The nick name must be between 4 and 150 characters.'
      //     });
      //   } else {
      //     this.setState({
      //       errorNickName: undefined
      //     });
      //   }
      //   break;
      case "phone":        
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorCellularPhoneNumber: "Please enter a cellular phone number"
          });
        } else if (!phoneNumberRegex.test(value)) {
          this.setState({
            errorCellularPhoneNumber: 'Cellular number must be between 9 and 15 digits and contain only numbers.'
          });
        } else {
          this.setState({
            errorCellularPhoneNumber: undefined
          });
        }
        break;
      case "homeNumber":
        if (value.trim().length > 0 && !phoneNumberRegex.test(value)) {
          this.setState({
            errorAlternatePhoneNumber: 'Alternate number must be between 9 and 15 digits and contain only numbers.'
          });
        } else {
          this.setState({
            errorAlternatePhoneNumber: undefined
          });
        }
        break;
      case "workNumber":
        if (value.trim().length > 0 && !phoneNumberRegex.test(value)) {
          this.setState({
            errorWorkNumber: 'Alternate number must be between 9 and 15 digits and contain only numbers.'
          });
        } else {
          this.setState({
            errorWorkNumber: undefined
          });
        }        
        break;
      case "fax":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorFaxNumber: "Please enter a fax number"
          });
        } else if (!faxNumberRegex.test(value)) {
          this.setState({
            errorFaxNumber: 'Invalid fax number! Must be a number!'
          });
        } else if (value.trim().length <= 6 || value.trim().length >= 12) {
          this.setState({
            errorFaxNumber: 'Must be min 6 and max 12 number!'
          });
        } else {
          this.setState({
            errorFaxNumber: undefined
          });
        }
        break;
      case "address":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorStreetAddress: "Please enter a street address"
          });
        } else {
          this.setState({
            errorStreetAddress: undefined
          });
        }
        break;
      case "city":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorCity: "Please enter a city"
          });
        } else {
          this.setState({
            errorCity: undefined
          });
        }
        break;
      case "state":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorState: "Please enter a state"
          });
        } else {
          this.setState({
            errorState: undefined
          });
        }
        break;
      case "zip":
        if (value === undefined || value === null || value.trim().length == 0) {
          this.setState({
            errorZipCode: "Please enter a zip"
          });
        } else {
          this.setState({
            errorZipCode: undefined
          });
        }
        break;
    }
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;
      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  setStateAsync(obj, state) {
    return new Promise((resolve) =>
      obj.setState(state, resolve)
    )
  }

  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      fname: "",
      lname: "",
      nickname: "",
      email: "",
      username: ""
    });
  }
  /**
   * Signature Submit's
   * @returns 
   */
  onSubmitSignature(){
    if(this.sigCanvas.current.isEmpty()){
      this.setState({
        errorSignature: "Please sign your digital signature."
      });
      return;
    }
    this.setState({
      errorSignature: undefined
    });
    this.toggleSignature();
    const signature = this.sigCanvas.current.getSignaturePad().toDataURL("image/png");
    this.props.dispatch({
      type: "DO_UPDATE_SIGNATURE",
      payload: {
        user: this.props.currentUserId,
        signature: signature
      }
    });
    if (this.props.isClient) {
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId, isClient: true }
        });
      }, 1000);        
    } else {        
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
      }, 1000); 
    }
  }
  submitUser(e) {
    e.preventDefault();
    if (this.state.bodytraceBPError != null && this.state.bodytraceBPError.length > 0 || this.state.bodytraceWSError != null && this.state.bodytraceWSError.length > 0) {
      return;
    }
    if (this.state.errorEmail != undefined || this.state.errorFname != undefined || this.state.errorLname != undefined || this.state.errorNickName != undefined ||
      this.state.errorCellularPhoneNumber != undefined || this.state.errorAlternatePhoneNumber != undefined || this.state.errorWorkNumber != undefined || this.state.errorFaxNumber != undefined ||
      this.state.errorStreetAddress != undefined || this.state.errorCity != undefined || this.state.errorState != undefined || this.state.errorZipCode != undefined) {
      return;
    }
    if(this.state.uploadImage){
      const formData = new FormData();
      formData.append('profileImage', this.state.uploadImage);
      formData.append('user', this.props.currentUserId);
      this.props.dispatch({
        type: "DO_SAVE_PROFILE_IMAGE",
        payload : formData
    })
    }
    if (!this.props.hideTitle) {
      this.props.dispatch({
        type: "DO_SAVE_PROFILE",
        payload: {
          fname: this.state.fname,
          // profileImage: this.state.profileImage,
          lname: this.state.lname,
          nickname: this.state.nickname,
          username: this.state.username,
          email: this.state.email,
          phone: this.state.phone,
          homeNumber: this.state.homeNumber,
          workNumber: this.state.workNumber,
          fax: this.state.fax,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          weight: this.state.weight,          
          gender: this.state.gender,
          bday: this.state.bday,
          organization: this.state.organization,
          pin: this.state.pin,          
        }
      });
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE"
        });
      }, 1000);
    } else {
      let addClient;
      if (this.props.isClient) {
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_PATIENT",
          payload: {
            user: this.props.currentUserId,
            fname: this.state.fname,
            profileImage: this.state.profileImage,
            lname: this.state.lname,
            nickname: this.state.nickname,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            homeNumber: this.state.homeNumber,
            workNumber: this.state.workNumber,
            fax: this.state.fax,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,            
            gender: this.state.gender,
            bday: this.state.bday,
            pin: this.state.pin,
          }
        });
      } else {
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_PATIENT",
          payload: {
            user: this.props.currentUserId,
            fname: this.state.fname,
            profileImage: this.state.profileImage,
            lname: this.state.lname,
            nickname: this.state.nickname,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            homeNumber: this.state.homeNumber,
            workNumber: this.state.workNumber,
            fax: this.state.fax,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,            
            gender: this.state.gender,
            bday: this.state.bday,            
            pin: this.state.pin            
          }
        });
      }

      if (this.props.isClient) {
        setTimeout(() => {
          this.props.dispatch({
            type: "DO_GET_PROFILE_PATIENT",
            payload: { user: this.props.currentUserId, isClient: true }
          });
        }, 1000);
      } else {
        setTimeout(() => {
          this.props.dispatch({
            type: "DO_GET_PROFILE_PATIENT",
            payload: { user: this.props.currentUserId }
          });
        }, 1000);
      }
    }
  }

  async toggleSignature() {
    this.setState({
      signatureModal: !this.state.signatureModal
    });
    this.setState({
      errorSignature: undefined
    });
  }

  componentDidMount() {
    if (this.props.isClient) {
      if (!this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE",
          payload: { isClient: true }
        });
      }
      if (this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId, isClient: true }
        });
      }
    } else {
      if (!this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE"
        });
      }
      if (this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
      }
    }
  }
  componentDidUpdate() {
    // if (!this.state.fname) {
    //   this.setState({
    //     fname: this.props.profile ? this.props.profile.fname : null,
    //     lname: this.props.profile ? this.props.profile.lname : null,
    //     nickname: this.props.profile ? this.props.profile.nickname : null,
    //     username: this.props.profile ? this.props.profile.username : null,
    //     email: this.props.profile ? this.props.profile.email : null,
    //     phone: this.props.profile ? this.props.profile.phone : null,
    //     homeNumber: this.props.profile ? this.props.profile.homeNumber : null,
    //     workNumber: this.props.profile ? this.props.profile.workNumber : null,
    //     fax: this.props.profile ? this.props.profile.fax : null,
    //     address: this.props.profile ? this.props.profile.address : null,
    //     city: this.props.profile ? this.props.profile.city : null,
    //     state: this.props.profile ? this.props.profile.state : null,
    //     zip: this.props.profile ? this.props.profile.zip : null,
    //     gender: this.props.profile ? this.props.profile.gender : null,
    //     bday: this.props.profile ? this.props.profile.bday : null,       
    //   });
    // }
  }
  clickViewLogs() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }

  render() {
    return (
      <div
        className={this.props.hideTitle ? "col-12 mx-auto remove-table-padding" : "col-12 mx-auto"}>
        {!this.props.hideTitle && (
          <Row className="header-device-list">
            <Col sm="12" className="center-label remove-table-padding div-row-center">
              <h3 className="text-center title-device">
                <i class="mdi mdi-account"></i> Update your profile details
              </h3>
            </Col>
          </Row>
        )}
        {this.props.hideTitle && (
          <>
            <Row className="header-device-list">
              <Col sm="12" className="center-label remove-table-padding">
                <h3 className="text-center title-device">
                  <i class="mdi mdi-account"></i> Profile Details
                </h3>
              </Col>
            </Row>
          </>
        )}
        {this.props.ongoingPatientProfileRequest && (
          <LottieLoader
            loadLoop={true}
            autoplayLoop={true}
            source={lottieLoaderFile}
            Lheight={150}
            Lwidth={150}
            subTitle={"Please Wait ..."}
          />
        )}
        {!this.props.ongoingPatientProfileRequest && this.props.profile && (
          <Form onSubmit={this.submitUser}>
            <Row>
              <Col sm="5" className="center-label">
                <FormGroup className="text-left" style={{ width: "96%", float: "left" }}>
                  <Label for="exampleText" className="profile-title">Profile Image</Label>
                  <ImageUploader
                    fileContainerStyle={{ backgroundColor: "#f5f5f5" }}
                    singleImage={true}
                    withIcon={true}
                    withPreview
                    defaultImages={
                      this.state.profileImage ? [this.state.profileImage] : []
                    }
                    buttonText="Choose image"
                    onChange={this.onDrop}
                    imgExtension={[".jpg", ".gif", ".png"]}
                    maxFileSize={2097152}
                    label="Max file size: 2mb, accepted: jpg|gif|png"
                    buttonStyles={{
                      display: this.state.profileImage ? "none" : "block"
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="7" className="profile-signture-image">
                <FormGroup className="remove-margin responsive-form-group">
                  <Label className="profile-title">
                    Agreement's Status
                  </Label>
                  <div className="display-auth-status-div">
                    <Row>
                      <Col sm="6" className="center-label-aggrement">
                        <FormGroup className="div-text" style={{ marginLeft: "7%", marginTop: 24 }}>
                          {this.state.isAgreedPrivacyPolicy && (
                            <img src={isChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          {!this.state.isAgreedPrivacyPolicy && (
                            <img src={isUnChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          <Label className="form-check-label pr-4 margin-for-label">
                            Privacy Policy
                          </Label>
                        </FormGroup>
                        <FormGroup className="div-text" style={{ marginLeft: "7%", marginTop: 24 }}>
                          {this.state.isAgreedAggrement && (
                            <img src={isChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          {!this.state.isAgreedAggrement && (
                            <img src={isUnChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                            HIPPA Agreement
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="text-left" style={{ width: "96%", float: "left" }}>
                          <div className="signature-view-profile">
                            <Label for="exampleText" className="profile-title" style={{ fontWeight: 600, margin:0 }}>Signature on the Agreement</Label>
                            <div className="pull-right">
                              <a
                                onClick={() => {
                                  this.toggleSignature();
                                }}
                                className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom signature-edit-btn"
                                style={{ float: 'right' }}>
                                <i class="mdi mdi-pencil" style={{marginRight:5}}></i>
                                Edit
                              </a>
                            </div>
                          </div>
                          <div className="signature-display">
                            {this.props.profileSignature && (
                              <img
                                width={155}
                                height={112}
                                src={this.props.profileSignature}
                              />
                            )}
                            {!this.props.profileSignature && (
                              <>
                                <div>
                                  <img className="no-signature"
                                    src={isNoSignature} 
                                  />
                                  <Label className="no-signature-text">
                                    Signature could not be found!
                                  </Label>
                                </div>
                              </>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>            
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Email</Label>
                </Col>                
              </Row>
              <Input
                type="email"
                name="email"
                placeholder="Enter Email Address"
                className="input-date-filter remove-bottom-margin"
                disabled={this.props.enableUserEdit}
                value={this.state.email}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorEmail && (
                <ErrorDisplay message={this.state.errorEmail} />
              )}              
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Username</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="username"
                placeholder="Enter Username"
                disabled={true}
                value={this.state.username}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>            
            <FormGroup>
              <Label for="exampleText">Legal First Name</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="fname"
                placeholder="Enter First Name"
                value={this.state.fname}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorFname && (
                <ErrorDisplay message={this.state.errorFname} />
              )}
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Legal Last Name</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="lname"
                placeholder="Enter Last Name"
                value={this.state.lname}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorLname && (
                <ErrorDisplay message={this.state.errorLname} />
              )}
            </FormGroup>

            <FormGroup>
              <Label for="exampleText">Legal Nickname</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="nickname"
                placeholder="Enter Nicknames"
                value={this.state.nickname}
                required={false}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorNickName && (
                <ErrorDisplay message={this.state.errorNickName} />
              )}
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">DOB</Label>
                </Col>
              </Row>
              <Input
                type="date"
                className="input-date-filter remove-bottom-margin"
                name="bday"
                placeholder="Enter Date Of Birth"
                required={true}
                max={moment().format("YYYY-MM-DD")}
                value={moment(this.state.bday).format("YYYY-MM-DD")}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Cellular Phone Number</Label>
                </Col>                
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="phone"
                keyboardType="numeric"
                placeholder="Enter Cellular Phone Number"
                value={this.state.phone}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorCellularPhoneNumber && (
                <ErrorDisplay message={this.state.errorCellularPhoneNumber} />
              )}
            </FormGroup>
            <div className="form-group">
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Alternate Phone Number</Label>
                </Col>                
              </Row>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Home Number"
                name="homeNumber"
                value={this.state.homeNumber}
                onChange={this.handleInputChange}
              />
              {this.state.errorAlternatePhoneNumber && (
                <ErrorDisplay message={this.state.errorAlternatePhoneNumber} />
              )}
            </div>
            <div className="form-group">
              <label>Work Number</label>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Work Number"
                name="workNumber"
                value={this.state.workNumber}
                onChange={this.handleInputChange}
              />
              {this.state.errorWorkNumber && (
                <ErrorDisplay message={this.state.errorWorkNumber} />
              )}
            </div>
            <div className="form-group">
              <label>Fax Number</label>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Fax Number"
                name="fax"
                value={this.state.fax}
                onChange={this.handleInputChange}
              />
              {this.state.errorFaxNumber && (
                <ErrorDisplay message={this.state.errorFaxNumber} />
              )}
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select
                name="gender"
                className="form-control input-date-filter remove-bottom-margin"
                value={this.state.gender}
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Street Address</Label>
                </Col>                
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                value={this.state.address}
                name="address"
                placeholder="Enter Street Address"
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorStreetAddress && (
                <ErrorDisplay message={this.state.errorStreetAddress} />
              )}
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">City</Label>
                </Col>               
              </Row>
              <Input
                type="text"
                name="city"
                placeholder="Enter City"
                className="input-date-filter remove-bottom-margin"
                value={this.state.city}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorCity && (
                <ErrorDisplay message={this.state.errorCity} />
              )}
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">State</Label>
                </Col>                
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="state"
                placeholder="Enter State"
                value={this.state.state}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorState && (
                <ErrorDisplay message={this.state.errorState} />
              )}
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Zip Code</Label>
                </Col>                
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="zip"
                placeholder="Enter Zip Code"
                value={this.state.zip}
                onChange={this.handleInputChange}
                rows={10}
              />
              {this.state.errorZipCode && (
                <ErrorDisplay message={this.state.errorZipCode} />
              )}
            </FormGroup>                        
            <button type="submit" className="btn btn-block btn-info">
              SAVE CHANGES
            </button>
          </Form>
        )}
         <Modal
          style={{ width: "35%" }}
          isOpen={this.state.signatureModal}
          toggle={this.toggleSignature}>
          <ModalHeader
            style={{ float: 'right' }}
            toggle={this.toggleSignature}
            className="close_button">
            <Label className="align-items-center justify-content-center text-center">Edit Signature</Label>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.toggleSignature()
              }}
              className="btn btn-info ml-2 close-configration"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20,
                marginRight:8
              }}></i>
              Close
            </button>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                //this.toggleSignature();
                this.onSubmitSignature();
              }}
              className="btn btn-info ml-2 save-configration"
            >
              <i class="mdi mdi-check-circle" style={{
                fontSize: 20
              }}></i>
              Save
            </button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <div className="signature-div-center">
              <div>
                <div className="signature-div-style signature-margin">
                  <SignatureCanvas
                    ref={this.sigCanvas}
                    penColor="black"
                    canvasProps={{ width: 272, height: 150 }}
                  />
                  {this.state.isEditable && (
                    <>
                      <code><hr className="header-line-style" />
                        <button className="clear-signature" onClick={() => {
                          this.sigCanvas.current.clear()
                        }}>Clear</button></code>
                    </>
                  )}
                </div>
                {this.state.errorSignature && (
                  <div style={{ textAlign: "center" }}>
                    <ErrorDisplay message={this.state.errorSignature} />
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>       
      </div>
    );
  }

}
const mapStateToProps = ({ user, careprovider }) => {
  const { usersOfferedAccess, timerStarts, ongoingRequest, ongoingPatientProfileRequest } = careprovider;
  const { authenticated, userDetails, isRegister, profileOwner } = user;
  const isPatientLogin = userDetails.role === "patient" && !userDetails.role.isCareProvider && !userDetails.role.isStaff;
  var signatureLink = userDetails ? userDetails.signatureImage : undefined;
  if (signatureLink && !signatureLink.indexOf("http") == 0) {
    signatureLink = "/" + signatureLink +"?" + new Date()
  }
  const profileSignature = signatureLink ? signatureLink :  undefined;
  return {
    authenticated,
    userDetails,
    isPatientLogin,
    ongoingRequest,
    isRegister,
    usersOfferedAccess,
    timerStarts,
    profile: userDetails,
    ongoingPatientProfileRequest,
    profileSignature
  };
};

export default connect(mapStateToProps)(ProfileDetail);
