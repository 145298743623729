// Decalred All Violation Helper Functions
import React from "react";
import AdherenceIcon from "../../../files/adherence.svg";
import ReminderIcon from "../../../files/reminder.jpg";
import TimeIcon from "../../../files/timeadherence.jpg";
import TrendingIcon from "../../../files/trending.svg";
import userImage from "../../../files/user.png";
import VitalsIcon from "../../../files/vitals.jpg";
import WeightIcon from "../../../files/weight.svg";
import QuestionnaireIcon from ".././../../files/questionnaire.svg";
import Link from "components/ExtendLink";
import moment from "moment";
import { Col, Row } from "reactstrap";

export function withIcon(cell) {
  let icon;
  switch (cell) {
    case "Vitals":
      icon = VitalsIcon;
      break;
    case "Time Adherence":
      icon = TimeIcon;
      break;
    case "Adherence":
      icon = AdherenceIcon;
      break;
    case "Reminder":
      icon = ReminderIcon;
      break;
    case "Trending Violation":
      icon = TrendingIcon;
      break;
    case "Weight":
      icon = WeightIcon;
      break;
    case "Questionnaire":
      icon = QuestionnaireIcon;
      break;
    default:
      break;
  }
  return (
    <span>
      <img src={icon} className="mr-2" alt="image" />
      {cell}
    </span>
  );
}

export const MyExportCSV = (onExport) => {
  return (
    <Row>
       <button
          className="btn btn-info ml-2 download-click-cls"
          onClick={onExport}
        >
          <i className="mdi mdi-download" /> Export to CSV
        </button>
    </Row>
  );
};

export function fullname(cell) {
  if (cell) {
    const profileImage = cell.profileImage
      ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + cell.profileImage
      : userImage;
    return (
      <div>
        <img src={profileImage} className="mr-2" alt="image" />
        {cell.fname} {cell.lname}{" "}
        {cell.mainCareProvider
          ? "(" +
            cell.mainCareProvider.fname +
            " " +
            cell.mainCareProvider.lname +
            ")"
          : ""}
      </div>
    );
  } else {
    return "";
  }
}

export function nickname(row) {
  if (row) {
    return <div>{row ? row : "-"}</div>;
  } else {
    return "-";
  }
}

export function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}

export function priceFormatter(
  column,
  colIndex,
  { sortElement, filterElement }
) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

export const vitalReading = (cell, row) => {
  if (row) {
    if (
      cell ||
      row.ruleType == "Questionnaire" ||
      row.ruleType == "Trending Violation"
    ) {
      const { violatedBy, createdBy } = row;
      if (createdBy && row.ruleType != "Questionnaire") {
        return (
          <Link
            to={`/violation/details?identifier=${createdBy._id}tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
      if (row.ruleType == "Questionnaire") {
        return (
          <Link
            to={`/violation/questionnairedetails?identifier=${createdBy._id}tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
    }
  }
  return "";
};

export const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {" "}
    Showing {from} to {to} of {size} Results
  </span>
);
