import { takeEvery, takeLatest, all } from "redux-saga/effects";
import {
  loginSaga,
  logoutSaga,
  emailChanged,
  passwordChanged,
  fnameChanged,
  lnameChanged,
  nicknameChanged,
  registerSaga,
  changeCode,
  verifyEmailSaga,
  resetSaga,
  forgotSaga,
  registerFinalSaga,
  phoneChanged,
  registerClient,
  loginClient,
  clientInfo,
  aggrementApproved
} from "./authSaga";
import {
  submitOfferSaga,
  genericError,
  requestAccess,
  usersGivenAccessMyReading,
  usersOfferedAccess,
  assignedUsersList,
  usersOfferedAccessOrganization,
  getAssignedCareProviders,
  currentMonthReport,
  getReminderPatientList,
  getReminderList,
  approvePermission,
  deletePermission,
  getMyRules,
  getCareProviderExpectations,
  getRuleSetByOthers,
  saveVitalRule,
  savePatientExpectationRule,
  saveTimeAdherenceRule,
  saveAdherenceRule,
  saveReminderRule,
  saveTrendingViolationRule,
  getMyQuestionnaire,
  getMyViolations,
  getUserViolations,
  deleteRule,
  getVideoToken,
  timerStarts,
  savePMTime,
  getPMTime,
  getInbox,
  saveInbox,
  getUserReadings,
  getUserDexcomGlucoseData,
  sendInvite,
  getStaff,
  deleteStaff,
  readInbox,
  deleteInbox,
  getNotes,
  getPatientConditions,
  getPatientCCMAnswers,
  saveNotes,
  deleteNotes,
  saveStaff,
  saveUser,
  saveSupport,
  savePatientCCMAnswers,
  addConditions,
  deleteCondition,
  createCareProviderCondition,
  updateCareProviderCondition,
  deleteCareProviderCondition,
  getProfile,
  getDevices,
  saveProfile,
  changePassword,
  getPatientProfile,
  savePatientProfile,
  removeProfileData,
  filterPopulation,
  removePopulation,
  addOrDeleteStaffPatient,
  autoAddAllPatients,
  addOrDeleteExpectation,
  saveClientDetails,
  getClientDetails,
  deleteDevice,
  getCareProviders,
  getCareProviderPatients,
  savePatientPermission,
  getCareProviderStaff,
  getSupport,
  resetPatientPassword,
  getNoReadings,
  getClients,
  getConditions,
  deleteUser,
  disableUser,
  enableUser,
  approvedUser,
  rejectedUser,
  disableDemoUser,
  enableDemoUser,
  saveWeightRule,
  getCareProvidersList,
  sendPMSessionReport,
  updatePMTime,
  getMyQuestionnaireCareProvider,
  saveQuestionnaire,
  deleteQuestionnaire,
  updateQuestionnaire,
  sendQuestionnaireMail,
  getMyQuestionnaireNameAndRepeted,
  allPatientOfferedAccess,
  allPatientCount,
  getNewPatient,
  exportAdminPatientList,
  updatePatientListPath,
  getPMTimeNew,
  exportCCMRecodes,
  printCCMRecodes,
  getPatientExpectationCarePlan,
  getOnBoardingCategories,
  allOnBoarding,
  getAllDevicesList,
  onBoardingDataCount,
  getDeviceListForOnBoarding,
  getPatientList,
  getCareProviderList,
  getClientList,
  readAllInbox,
  deleteAllInbox,
  getStaffList,
  getInboxUnreadCount,
  exportReadingRecordsList,
  getThirtyDaysSummaryPatient,
  saveProfileLogs,
  exportOnboardingList,
  savePatientFrequency,
  getPatientFrequency,
  onBoardingCCMDataCount,
  allOnBoardingCCM,
  exportOnboardingCCM,
  getAdminList,
  checkCreatePatientPermission,
  getSharedAccount,
  allFaxReports,
  saveCCMFormInformation,
  getPatientCCMSubmission,
  getPatientSubmissionDetails,
  deleteReading,
  getNotificationLogs,
  getNotificationLogsExportCSV,
  updateNotificationLogsFilePath,
  getProfileDefaultConfigration,
  saveProfileConfigration,
  allDeviceList,
  exportDeviceReport,
  getAdditionalCareProviderList,
  addOrUpdateMainCarepoviderStaff,
  getAllStaffPatientList,
  deleteAllStaffPatient,
  removeAllAssignedCareprovider,
  exportSharedAccountList,
  saveProfileConfigrationNotify,
  exportAdminPatientPopulateList,
  getCareProviderExpectationsReports,
  getGlobalThresholdCareProvider,
  getPmSessionTime,
  sentCCMCarePlanFormReport,
  updateSignature,
  emailReadingReport,
  getAllChatMessages,
  sentChatMessage,
  addChatMessages,
  uploadUserImage,
  versionReports,
  versionReportsExport,
  getAppVersions,
  sentUpdateAppNotification,
  getUserViolationsRecordsCount,
  saveShared,
  getAssignedUsersListNew,
  saveVideoCallNotes,
  getUserRoleAndDetails
} from "./careProviderSaga";
import { GET_VIDEO_TOKEN } from "../actions/types";

const watchAuth = [
  takeEvery("DO_LOGIN", loginSaga), // Encrypted
  takeEvery("DO_GET_CLIENT_INFO", clientInfo),
  takeEvery("DO_LOGOUT", logoutSaga),
  takeEvery("DO_REGISTER", registerSaga),
  takeEvery("DO_REGISTER_FINAL", registerFinalSaga),
  takeEvery("DO_REGISTER_CLIENT", registerClient),
  takeEvery("DO_LOGIN_CLIENT", loginClient),
  takeEvery("DO_RESET", resetSaga),
  takeEvery("DO_FORGOT", forgotSaga),
  takeEvery("DO_CHANGE_EMAIL", emailChanged),
  takeEvery("DO_CHANGE_FNAME", fnameChanged),
  takeEvery("DO_CHANGE_LNAME", lnameChanged),
  takeEvery("DO_CHANGE_NICKNAME", nicknameChanged),
  takeEvery("DO_CHANGE_PHONE", phoneChanged),
  takeEvery("DO_CHANGE_CODE", changeCode),
  takeEvery("DO_CHANGE_PASSWORD", passwordChanged),
  takeEvery("DO_VERIFY", verifyEmailSaga),
  takeEvery("DO_AGGREMENT_APPROVED", aggrementApproved),
];

const watchCareProvider = [
  takeLatest("DO_PATIENT_CCM_SUBMISSION", getPatientSubmissionDetails),
  takeLatest("DO_GET_NO_READINGS", getNoReadings),
  takeLatest("DO_GET_NEW_PATIENT", getNewPatient),
  takeLatest("DO_RESET_PATIENT_PASSWORD", resetPatientPassword),
  takeLatest("DO_SUBMIT_OFFER_READING", submitOfferSaga),
  takeLatest("DO_REMOVE_PATIENT_LINK_PATH", updatePatientListPath),  
  takeLatest("DO_REMOVE_PATIENT_LINK_PATH",updateNotificationLogsFilePath),
  takeLatest("DO_SUBMIT_REQUEST_ACCESS_READING", requestAccess),
  takeEvery("GENERIC_ERROR", genericError),
  takeLatest("DO_GET_USERS_YOU_SHARED", usersGivenAccessMyReading),
  takeLatest("DO_GET_ALL_DEVICE", getAllDevicesList),
  takeLatest("DO_GET_ALL_DEVICE_FOR_ON_BOARDING", getDeviceListForOnBoarding),
  takeLatest("DO_GET_USERS_YOU_HAVE_ACCESS", usersOfferedAccess),
  takeLatest("DO_GET_ALL_PATIENT", allPatientOfferedAccess),
  takeLatest("DO_GET_ALL_PATIENT_COUNT", allPatientCount),
  takeLatest("DO_EXPORT_PATIENT_LIST",exportAdminPatientList),
  takeLatest("DO_EXPORT_ONBOARDING_LIST",exportOnboardingList),
  takeLatest("DO_EXPORT_ONBOARDING_CCM_LIST",exportOnboardingCCM),
  takeLatest("DO_EXPORT_CCM_RECODES",exportCCMRecodes),
  takeLatest("DO_PRINT_CCM_RECODES",printCCMRecodes),
  takeLatest("DO_GET_ALL_ON_BOARDING_DATA",allOnBoarding),
  takeLatest("DO_GET_ALL_ON_BOARDING_CCM_DATA",allOnBoardingCCM),
  takeLatest("DO_GET_ALL_FAX_REPORT_DATA",allFaxReports),
  takeLatest("DO_GET_ALL_CHAT_MESSAGES",getAllChatMessages),
  takeLatest("DO_GET_ON_BOARDING_COUNT", onBoardingDataCount),
  takeLatest("DO_GET_ON_BOARDING_CCM_COUNT", onBoardingCCMDataCount),
  takeLatest("DO_GET_USERS_DEXCOM_GLUCOSE_DATA", getUserDexcomGlucoseData),
  takeLatest("DO_GET_USERS_LIST", assignedUsersList), // encrypted
  takeLatest("DO_GET_CURRENT_MONTH_REPORT", currentMonthReport),
  takeLatest("DO_GET_REMINDER_PATIENT_LIST", getReminderPatientList),
  takeLatest("DO_GET_REMINDER_LIST", getReminderList),
  takeLatest("DO_GET_NOTIFICATION_LOGS", getNotificationLogs),
  takeLatest("DO_GET_NOTIFICATION_LOGS_EXPORT_CSV", getNotificationLogsExportCSV),
  takeLatest(
    "DO_GET_USERS_READING_UNDER_YOUR_ORGANIZATION",
    usersOfferedAccessOrganization
  ),
  takeLatest("DO_GET_ASSIGNED_CAREPROVIDERS", getAssignedCareProviders),
  takeLatest("DO_APPROVE_PERMISSION", approvePermission),
  takeLatest("DO_DELETE_PERMISSION", deletePermission),
  takeLatest("DO_GET_MY_RULES", getMyRules),
  takeLatest("DO_GET_CAREPROVIDER_EXPECTATIONS", getCareProviderExpectations),
  takeLatest("DO_GET_CAREPROVIDER_EXPECTATIONS_REPORT", getCareProviderExpectationsReports),
  takeLatest("DO_GET_RULES_SET_BY_OTHERS", getRuleSetByOthers),
  takeLatest("DO_SAVE_PATIENT_EXPECTATION_RULE", savePatientExpectationRule),
  takeLatest("DO_SAVE_VITAL_RULE", saveVitalRule),
  takeLatest("DO_SAVE_QUESTIONNAIRE", saveQuestionnaire),
  takeLatest("DO_SAVE_TIME_ADHERENCE_RULE", saveTimeAdherenceRule),
  takeLatest("DO_SAVE_ADHERENCE_RULE", saveAdherenceRule),
  takeLatest("DO_SAVE_REMINDER_RULE", saveReminderRule),
  takeLatest("DO_SAVE_TRENDING_VIOLATION_RULE", saveTrendingViolationRule),
  takeLatest("DO_UPDATE_QUESTIONNAIRE", updateQuestionnaire),
  takeLatest("DO_SAVE_WEIGHT_RULE", saveWeightRule),
  takeLatest("DO_GET_MY_QUESTIONNAIRES", getMyQuestionnaire),
  takeLatest("DO_GET_MY_QUESTIONNAIRES_NAME_REPETED", getMyQuestionnaireNameAndRepeted),
  takeLatest("DO_GET_MY_QUESTIONNAIRES_CARE_PROVIDER", getMyQuestionnaireCareProvider),
  takeLatest("DO_GET_MY_VIOLATIONS", getMyViolations),
  takeLatest("DO_GET_USER_VIOLATIONS", getUserViolations),
  takeLatest("DO_GET_USER_VIOLATIONS_RECORD_COUNT", getUserViolationsRecordsCount),
  takeLatest("DO_DELETE_RULE", deleteRule),
  takeLatest("DO_DELETE_QUESTIONNAIRE", deleteQuestionnaire),
  takeLatest("DO_GET_VIDEO_TOKEN", getVideoToken),
  takeLatest("START_TIMER", timerStarts),
  takeLatest("DO_SAVE_TIMER", savePMTime),
  takeLatest("DO_SAVE_TIMER_VIDEO_CALL", saveVideoCallNotes),
  takeLatest("DO_SEND_CHAT_MESSAGE", sentChatMessage),
  takeLatest("DO_CHAT_MESSAGE", addChatMessages),
  takeLatest("DO_SEND_QUESTIONNAIRE_EMAIL", sendQuestionnaireMail),
  takeLatest("DO_UPDATE_TIMER", updatePMTime),
  takeLatest("DO_GET_CCM_RECORDS", getPMTime),
  takeLatest("DO_GET_PATIENT_FREQUENCY",getPatientFrequency),
  takeLatest("DO_DEFAULT_PROFILE_CONFIGRATION",getProfileDefaultConfigration),
  takeLatest("DO_GET_CCM_RECORDS_NEW", getPMTimeNew),
  takeLatest("DO_GET_INBOX", getInbox),
  takeLatest("DO_SAVE_INBOX", saveInbox),
  takeLatest("DO_GET_USER_READINGS", getUserReadings),
  takeLatest("DO_GET_EXPECTATION_CAREPLAN_PATIENT", getPatientExpectationCarePlan),
  takeLatest("DO_SEND_INVITE", sendInvite),
  takeLatest("DO_GET_SHARED_ACCOUNT", getSharedAccount),
  takeLatest("DO_GET_STAFF", getStaff),
  takeLatest("DO_GET_STAFF_LIST_NEW",getStaffList),
  takeLatest("DO_GET_PATIENT",getPatientList),
  takeLatest("DO_GET_ADMIN",getAdminList),
  takeLatest("DO_GET_CAREPROVIDER",getCareProviderList),
  takeLatest("DO_GET_CLIENT_LIST_NEW",getClientList),
  takeLatest("DO_DELETE_STAFF", deleteStaff),
  takeLatest("DO_READ_INBOX", readInbox),
  takeLatest("DO_READ_ALL_INBOX", readAllInbox),
  takeLatest("DO_DELETE_INBOX", deleteInbox),
  takeLatest("DO_DELETE_ALL_INBOX",deleteAllInbox),
  takeLatest("DO_GET_USER_PMSESSION_TIME", getPmSessionTime),
  takeLatest("DO_GET_USER_NOTES", getNotes),
  takeLatest("DO_GET_ON_BOARDING_STATUS", getOnBoardingCategories),
  takeLatest("DO_SAVE_USER_NOTES", saveNotes),
  takeLatest("DO_DELETE_USER_NOTES", deleteNotes),
  takeLatest("DO_DELETE_USER_READING", deleteReading),
  takeLatest("DO_SAVE_STAFF", saveStaff),
  takeLatest("DO_SAVE_USER", saveUser),
  takeLatest("DO_SAVE_PATIENT_FREQUENCY",savePatientFrequency),
  takeLatest("DO_SAVE_PROFILE_CONFIGRATION",saveProfileConfigration),
  takeLatest("DO_SAVE_PROFILE_CONFIGRATION_NOTIFY",saveProfileConfigrationNotify),
  takeLatest("DO_SAVE_SUPPORT_USER", saveSupport),
  takeLatest("DO_SAVE_PATIENT_CCMANSWERS", savePatientCCMAnswers),
  takeLatest("DO_ADD_CONDITIONS", addConditions),
  takeLatest("DO_DELETE_CONDITION", deleteCondition),
  takeLatest("DO_CREATE_CAREPROVIDER_CONDITION", createCareProviderCondition),
  takeLatest("DO_UPDATE_CAREPROVIDER_CONDITION", updateCareProviderCondition),
  takeLatest("DO_DELETE_CAREPROVIDER_CONDITION", deleteCareProviderCondition),
  takeLatest("DO_GET_PROFILE", getProfile),
  takeLatest("DO_SAVE_PROFILE", saveProfile),
  takeLatest("DO_SAVE_PROFILE_INFO_LOGS", saveProfileLogs),
  takeLatest("DO_GET_PROFILE_PATIENT", getPatientProfile),
  takeLatest("DO_GET_GLOBAL_THRESHOLD_CARE_PROVIDER", getGlobalThresholdCareProvider),
  takeLatest("DO_SAVE_PROFILE_PATIENT", savePatientProfile),
  takeLatest("DO_UPDATE_SIGNATURE", updateSignature),
  takeLatest("DO_REMOVE_PROFILE_DATA", removeProfileData),
  takeLatest("DO_GET_PATIENT_CONDITIONS", getPatientConditions),
  takeLatest("DO_GET_PATIENT_CCM_ANSWERS", getPatientCCMAnswers),

  takeLatest("DO_CHANGE_PASSWORD_PROFILE", changePassword),
  takeLatest("DO_GET_DEVICES", getDevices),
  takeLatest("DO_GET_PATIENT_CCM_SUBMISSION", getPatientCCMSubmission),
  takeLatest("DO_FILTER_USER_POPULATION", filterPopulation),
  takeLatest("DO_REMOVE_POPULATION", removePopulation),
  takeLatest("DO_ADD_OR_DELETE_STAFF_PATIENT", addOrDeleteStaffPatient),
  takeLatest("DO_ADD_ALL_PATIENTS", autoAddAllPatients),
  takeLatest("DO_ADD_OR_DELETE_EXPECTATION", addOrDeleteExpectation),

  takeLatest("DO_SAVE_CLIENT_DETAILS", saveClientDetails),
  takeLatest("DO_GET_CLIENT_DETAILS", getClientDetails),
  takeLatest("DO_DELETE_DEVICE", deleteDevice),

  takeLatest("DO_GET_CAREPROVIDERS", getCareProviders),
  takeLatest("DO_GET_CAREPROVIDER_PATIENTS", getCareProviderPatients),
  takeLatest("DO_SAVE_PATIENT_PERMISSION", savePatientPermission),
  takeLatest("DO_GET_CAREPROVIDER_STAFF", getCareProviderStaff),
  takeLatest("DO_GET_SUPPORTS", getSupport),
  takeLatest("DO_GET_CLIENT_LIST", getClients),
  takeLatest("DO_GET_CAREPROVIDERS_LIST", getCareProvidersList),
  takeLatest("DO_GET_CONDITIONS", getConditions),
  takeLatest("DO_DELETE_USER", deleteUser),
  takeLatest("DO_DISABLE_USER", disableUser),
  takeLatest("DO_ENABLE_USER", enableUser),
  takeLatest("DO_APPROVED_USER", approvedUser),
  takeLatest("DO_REJECTED_USER", rejectedUser),
  takeLatest("DO_DISABLE_DEMO_USER", disableDemoUser),
  takeLatest("DO_ENABLE_DEMO_USER", enableDemoUser),

  takeLatest("DO_SEND_PM_SESSION_FAX", sendPMSessionReport),
  takeLatest("DO_EXPORT_READING_RECORDS", exportReadingRecordsList),
  takeLatest("DO_READING_REPROT_EMAIL", emailReadingReport),
  takeLatest("DO_REMOVE_READING_LINK_PATH", updatePatientListPath),
  takeLatest("DO_GET_UNREAD_COUNT",getInboxUnreadCount), // Encrypted 
  takeLatest("DO_GET_CREATE_PATIENT_PERMISSION",checkCreatePatientPermission),
  takeLatest("DO_GET_THIRTY_DAYS_SUMMARY",getThirtyDaysSummaryPatient),
  takeLatest("DO_SAVE_CCM_FOMR_IINFORMATION", saveCCMFormInformation),
  takeLatest("DO_SENT_CCM_CARE_PLAN_FAX_REPORT", sentCCMCarePlanFormReport),
  takeLatest("DO_EXPORT_PATIENT_POPULATE_LIST",exportAdminPatientPopulateList),
  takeLatest("DO_SAVE_PROFILE_IMAGE", uploadUserImage),
  takeLatest("DO_GET_VERSION_REPORTS", versionReports),
  takeLatest("DO_GET_VERSION_REPORTS_EXPORTS", versionReportsExport),
  takeLatest("DO_GET_APP_VERSIONS", getAppVersions),
  takeLatest("DO_SENT_UPDATE_APP_NOTIFICATION", sentUpdateAppNotification),
  takeLatest("DO_EXPORT_SHARED_ACCOUNT_LIST",exportSharedAccountList),
  takeLatest("DO_SAVE_SHARED", saveShared),
  takeLatest("DO_DELETE_ALL_STAFF_PATIENT", deleteAllStaffPatient),
  takeLatest("DO_GET_THIRTY_DAYS_SUMMARY",getThirtyDaysSummaryPatient),
  takeLatest("DO_GET_ADDITIONAL_CAREPROVIDER_LIST", getAdditionalCareProviderList),
  takeLatest("DO_ADD_OR_UPDATE_MAINCAREPROVIDER_STAFF", addOrUpdateMainCarepoviderStaff),
  takeLatest("DO_REMOVE_ALL_ASSIGNED_CAREPROVIDER_STAFF", removeAllAssignedCareprovider),
  takeLatest("DO_GET_ALL_STAFF_PATIENT_LIST", getAllStaffPatientList),
  takeLatest("DO_GET_USER_DETAILS_AND_ROLE", getUserRoleAndDetails),
  takeLatest("DO_GET_ASSIGNED_USERS_LIST_NEW",getAssignedUsersListNew)
];

export default function* rootSaga() {
  yield all([...watchAuth, ...watchCareProvider]);
}
