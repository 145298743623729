import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from "prop-types";
import Link from "components/ExtendLink";
import {
  Col,
  Row
} from "reactstrap";
import callingIcon from "../../../files/called.png";
import userImage from "../../../files/user.png";
import LottieLoader from '../../LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import React, { useState,useEffect } from "react";
const _ = require("lodash");
function fullname(cell, row) {
  const profile = row.profileImage || userImage;
  return (
    <div className={row.isActive ? "" : " text-danger"}>
      <span>
        <img src={profile} className="mr-2" alt="image"   
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = userImage;
        }}/>
        {capitalize(row.lname)}
        {" "}
        {capitalize(row.fname)}

        {row?.ccmTrackings?.length > 0 && <img src={callingIcon} />}
      </span>
    </div>
  );
}

function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

const PatientList = (props, context) => {
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const initiallySelectedIds = props.data.filter(item => item.isSelected).map(item => item._id);
    const itemsPerPage = props.sizePerPage || 10;
    const totalPages = Math.ceil(props.totalSize / itemsPerPage);
    const allPagesSelection = {};
    for (let i = 1; i <= totalPages; i++) {
      allPagesSelection[i] = initiallySelectedIds;
    }
    setSelected(allPagesSelection);
  }, [props.data]);

  const handleRowSelect = (row, isSelect) => {
    setSelected((prevSelected) => {
      const pageSelected = { ...prevSelected };
      const currentPageSelection = pageSelected[currentPage] || [];
      if (isSelect) {
        pageSelected[currentPage] = [...currentPageSelection, row._id];
      } else {
        pageSelected[currentPage] = currentPageSelection.filter((id) => id !== row._id);
      }
      return pageSelected;
    });
    props.userInputChange([row._id],props.staffId,isSelect);
  };

  const handleSelectAll = (isSelect, rows) => {
    setSelected((prevSelected) => {
      const pageSelected = { ...prevSelected };
      pageSelected[currentPage] = isSelect ? rows.map((row) => row._id) : [];
      return pageSelected;
    });
    const allRowIds = rows.map((rows) => rows._id);
    const allRecordsId = _.flatten(allRowIds);
    props.userInputChange(allRecordsId,props.staffId,isSelect);
  };
  const handlePageChange = (page, sizePerPage) => {
    setCurrentPage(page);
  };
  function showButtons(cell, row, rowIndex, formatExtraData) {
    return (
      <div className='div-center'>
         <Link
          to={`/dashboard/${row.fname
            .replace(/\s+/g, "-")
            .toLowerCase()}-${row.lname.replace(/\s+/g, "-").toLowerCase()}/${row._id}`}
          className="btn pull-center more-btn"
          style={{ marginTop: 10 }}
        >
          <i class="mdi mdi-account-card-details"></i> More Details
        </Link>
      </div>
    );
  }
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    onPageChange: handlePageChange,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      }
    ]
  };
  const columns = [
    {
      dataField: "lname",
      text: "Last, First Name",
      formatter: fullname,
      csvFormatter: (cell, row) => {
        return (
          row.lname +
          ", " +
          row.fname +
          ` (${row.isActive ? "ACTIVE" : "INACTIVE"})`
        );
      },
      sort: true,
      headerFormatter: priceFormatter
    },
    {
        dataField: "email",
        text: "Email",
        formatter: (cell, row) => {
          return (row.email? row.email :"-");
        },
        csvFormatter: (cell, row) => {
          return (row.email ? row.email : "-");
        },
        sort: true,
        headerFormatter: priceFormatter
    },
    {
      dataField: "nickname",
      text: "Nickname",
      formatter: (cell, row) => {
        return (row.nickname? row.nickname :"-");
      },
      csvFormatter: (cell, row) => {
        return (row.nickname ? row.nickname : "-");
      },
      sort: true,
      headerFormatter: priceFormatter
    },
    {
        dataField: "username",
        text: "Username",
        formatter: (cell, row) => {
          return (row.username? row.username :"-");
        },
        csvFormatter: (cell, row) => {
          return (row.username ? row.username : "-");
        },
        sort: true,
        headerFormatter: priceFormatter
    },
    {
      dataField: "mainCareProvider.lname",
      text: "Main Care Provider",      
      headerFormatter: priceFormatter,
      formatter: (cell, row) => {
        if (row.mainCareProvider) {
          return capitalize(row.mainCareProvider.lname) + "  " + capitalize(row.mainCareProvider.fname);
        }else{
          return <div className="text-center">-</div>
        }
      },
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: showButtons,
      headerFormatter: priceFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '100px', textAlign: 'center' };
      },
      csvExport: false,
    },
  ];
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No patient found :("}
          subTitle={"We can't find any patient."}
        />
      )}              
    </div>
  );
  return (
    <Row className="col-sm-12 main-cls-modal remove-table-padding">
      <Col sm="12" className="mt-12">
      <div className="scrollbar-table-cls">  
      <BootstrapTable
          keyField="_id"
          loading={props.loading}
          data={props.data}
          columns={columns}
          pagination={paginationFactory(options)}
          noDataIndication={ () => <NoDataIndication /> }
          selectRow={{
            mode: 'checkbox',
            clickToSelect: false,
            onSelect: handleRowSelect,
            selected: selected[currentPage] || [],
            selectAll: true,
            onSelectAll: handleSelectAll,
          }}
          hover={props.totalSize > 0 ? true : false}
          condensed
          bordered
          wrapperClasses="table-responsive"
        />
      </div>
      </Col>
    </Row>
  );
};
PatientList.propTypes = {
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
export default PatientList;