import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { Col, Row } from "reactstrap";
const { ExportCSVButton } = CSVExport;
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <span>
      <img
        src="https://cdn-icons-png.flaticon.com/512/194/194915.png"
        className="mr-2"
        alt="image"
      />
      {capitalize(row.fname)} {capitalize(row.lname)}
    </span>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}

function formatDate(cell, row) {
  return moment(row.deviceReadingDate).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function readingValue(reading, row, rowIndex, formatExtraData) {
  const { unitMeasurement, bodyTraceWS, isBloodPressureReading } = formatExtraData;
  const data = _.map(formatExtraData.subType, function (v, i) {
    if(isBloodPressureReading && v._id == '5be56cbe3863cd0647d79896'){
      return;
    }
    let theValue;
    if (v.name == "Mean Arterial") {
      theValue = Math.round((1 / 3) * reading[0] + (2 / 3) * reading[1]);
    } else {
      if (v.name === "Temperature") {
        let tempValue;

        if (unitMeasurement.umTemperature === "C") {
          tempValue = reading[i] + unitMeasurement.umTemperature;
        } else {
          tempValue =
            ((reading[i] * 9) / 5 + 32).toFixed(2) +
            unitMeasurement.umTemperature;
        }
        theValue = tempValue;
      } else if (v.name === "Glucose") {
        
        let event;
        switch (reading[1]) {
          case 0:
            event = " (No Event)";
            break;
          case 3:
            event = " (After Meal)";
            break;
          case 4:
            event = " (Before Meal)";
            break;
          case 5:
            event = " (Control Solution)";
            break;
          default:
            event = " (No Event)";
            break;
        }
        if (unitMeasurement.umGlucose === "mg/dL") {
          theValue = Math.round(reading[i]) + unitMeasurement.umGlucose + event;
          const insulinDosage = row.insulinDosage
            ? ` Insulin Dosage: ${row.insulinDosage}`
            : "";
          theValue += insulinDosage;
        } else {
          theValue =
            Math.round(reading[i] / 18) + unitMeasurement.umGlucose + event;
          const insulinDosage = row.insulinDosage
            ? ` Insulin Dosage: ${row.insulinDosage}`
            : "";
          theValue += insulinDosage;
        }
      } else if (v.name === "Weight") {
        let weightValue;

        if (bodyTraceWS) {
          weightValue = reading[i] + unitMeasurement.umWeight;
        } else {
          if (unitMeasurement.umWeight === "kg") {
            weightValue = Math.round(reading[i]) + unitMeasurement.umWeight;
          } else {
            weightValue =
              Math.round(reading[i] * 2.2046) + unitMeasurement.umWeight;
          }
        }

        theValue = weightValue;
      } else if (v.name == "BMI") {
        if (row.readingValue) {
          const {
            bmi = null,
            bmiDescription = null,
            weight
          } = row.readingValue;

          theValue = bmi ? `${bmi} (${bmiDescription})` : "";
        }
      } else if (v.name == "Systolic" || v.name == "Diastolic") {
        let pressureValue;

        if (unitMeasurement.umBloodPressure === "mmHg") {
          pressureValue =
            Math.round(reading[i]) + unitMeasurement.umBloodPressure;
        } else {
          pressureValue =
            Math.round(reading[i] * 0.133322) + unitMeasurement.umBloodPressure;
        }
        theValue = pressureValue;
      } else {
        theValue = Math.round(reading[i]) + v.unit;
      }
    }
    return (
      <div style={{padding:4}}>
        <b>{v.name} :</b> {theValue}
      </div>
    );
  });
  return data;
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  return (
    <div>
      <button type="button" className="btn btn-info btn-fw">
        Comment
      </button>
    </div>
  );
}

const TableReadingPrint = React.forwardRef((props, ref) => {   
  const printLogoValue = props.printLogoValue;
  const printHeaderValue = props.printHeaderValue;
  //console.log('requestValue',printLogoValue);
  const printPageStyle = "@page { size: A4 portrait; }";
  return (
    <div ref={ref} className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" style={{padding:0}}> 
     {!printHeaderValue &&
      <style>{printPageStyle}</style>
      }
       {!printLogoValue &&
       <img
          src={`${process.env.REACT_APP_VIDEO_CALL_REQUEST}/static/media/smarthealth.c3ca62dd.jpg`}
          alt="new"
          width="290px"
        />   
       }
         {!printHeaderValue &&
      <Row className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" style={{ margin: 10, padding: 0 }}>
        <Col className="notes-pdf-title">
          <div className="text-left reading-card-for-all-reading float-right">
            <img
              src={require(`../../files/Reading/${props.readingType.abbrev}.svg`)}
              alt="profile-image"
              className="profile rounded-circle img-fluid mr-2 reading-icon"
            /> <div>
              <h4 class="reading-card-title">{props.readingType.description}</h4>
              <h6 class="reading-card-subtitle">{`${props.lname} ${props.fname}`}</h6>
            </div>
          </div>
        </Col>
        <Col className="notes-pdf-details">
          <table>
            <tbody>
              <tr>
                <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> Start Date :</td>
                <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                  {moment(props.startDate).format("LL")}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#ff5722" }}></i> End Date :</td>
                <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}{moment(props.endDate).format("LL")}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>   
      }     
      <ToolkitProvider
        keyField="_id"        
        data={_.orderBy(props.reading, ['deviceReadingDate'], ['desc'])}
        columns={[
          {
            dataField: "ddd",
            text: "Date",
            sort: false,
            formatter: formatDate,            
            headerFormatter: priceFormatter
          },
          {
            dataField: "value",
            text: "Value",
            sort: false,
            formatter: readingValue,            
            headerFormatter: priceFormatter,
            formatExtraData: {
              subType: props.subType,
              readingValue: props.readingValue,
              unitMeasurement: props.unitMeasurement,
              bodyTraceWS: props?.bodyTraceWS ? props.bodyTraceWS : false,
              isBloodPressureReading: props.isBloodPressureReading
            }
          },
          {
            dataField: "source",
            text: "Source",
            sort: false,
            formatter: (cell, row) => {
              const { description = "" } = cell || "";
              return description;
            },
            headerFormatter: priceFormatter,
            formatExtraData: {
              subType: props.subType,
              readingValue: props.readingValue,
              unitMeasurement: props.unitMeasurement
            }
          },{
            style: { width: "30%" },
            dataField: "comments",
            text: "Comment",
            sort: false,
            formatter: (cell, row) => {
              return cell || "-";
            },
            csvFormatter: (reading, row, rowIndex, formatExtraData) => {
              return reading || "-";
            },
            headerFormatter: priceFormatter,
          },
        ]}
      >
        {(toolKitProp) => (
          <div className="scrollbar-table-cls">
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              {...toolKitProp.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
});

export default TableReadingPrint;
