import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import LottieLoader from '../../LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import { Row, Col } from "reactstrap";
import UserName from "../../Reusable/UserName";
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <UserName user={row} />
  );
}

const defaultSorted = [
  {
    dataField: "lname", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc" // desc or asc
  }
];

function formatPhone(cell, row) {
  return <h4 class="font-weight-bold">{cell}</h4>;
}
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Row>
      <Col>
        <button className="btn btn-info ml-2 download-click-cls float-left" onClick={handleClick}>
        <i class="mdi mdi-download" style={{
                fontSize: 20
        }}></i> Export to CSV
        </button>
      </Col>
    </Row>
  );
};
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch((input.value).trim());
  };
  return (
    <div>
      <div class="form-group remove-bottom-margin">
        <input
          type="text"
          class="form-control input-date-filter"
          id="exampleInputName1Test"
          style={{ fontSize: "20px" }}
          placeholder="Search Last Name, First Name, Nickname, Email, etc..."
          ref={(n) => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};
const NoReading = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: props.usersGivenAccessMyReading.length
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      window.location.href = `/dashboard/${row.fname
        .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
        .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${
        row._id
      }`;
    }
  };

  function priceFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className="mb-2">{filterElement}</span>  
        {column.text}
        {sortElement}
      </div>
    );
  }
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No patient found :("}
          subTitle={"We can't find any patient."}
        />
      )}              
    </div>
  );
  return (
    <ToolkitProvider
      keyField="_id"
      data={props.usersGivenAccessMyReading}
      columns={[
        {
          dataField: "lname",
          text: "Last, First Name",
          formatter: fullname,
          sort: true,
          searchable:true,
          csvFormatter: (cell, row) => {
            return (
              row.lname +
              ", " +
              row.fname +
              ` (${row.isActive ? "ACTIVE" : "INACTIVE"})`
            );
          },
          filter: textFilter({
            className:"input-date-filter"
          }),
          filterValue: (cell, row) => {
            return row.lname + ", " + row.fname;
          },
          headerFormatter: priceFormatter,          
        },
        {
          dataField: "nickname",
          text: "Nickname",
          sort: true,
          searchable:true,
          formatter: (cell, row) => {
            return (row.nickname? row.nickname :"-");
          },
          csvFormatter: (cell, row) => {
            return (row.nickname? row.nickname :"-");
          },
          filter: textFilter({
            className:"input-date-filter"
          }),
          filterValue: (cell, row) => {
            return row.nickname;
          },
          headerFormatter: priceFormatter,          
        },
        {
          dataField: "bday",
          text: "DOB",
          formatter: (cell, row) => {
            return moment(row.bday).format("MMMM DD, YYYY");
          },
          csvFormatter: (cell, row) => {
            return moment(row.bday).format("MMMM DD, YYYY");
          },
          sort: true,
        },
        {
          dataField: "createdAt",
          text: "Enrolled Date",
          sort: true,
          formatter: (cell, row) => {
            return moment(row.createdAt).format("MMMM DD, YYYY");
          },
          headerFormatter: priceFormatter,
          csvFormatter: (cell, row, rowIndex) => {
            return moment(row.createdAt).format("MMMM DD, YYYY");
          },        
        },
        {
          dataField: "deviceReadingDate",
          text: "Last Upload Date",
          sort: true,
          searchable:true,
          formatter: (cell, row) => {
            return (row.deviceReadingDate?moment(row.deviceReadingDate).format("MMMM DD, YYYY") :"NONE");
          },
          csvFormatter: (cell, row) => {
            return (row.deviceReadingDate?moment(row.deviceReadingDate).format("MMMM DD, YYYY") :"NONE");
          },
        },
        {
          dataField: "organization",
          text: "Organization",
          sort: true,
          searchable:true,
          csvFormatter: (cell, row) => {
            return row.organization ? row.organization : "-";
          }
        },
        {
          dataField: "mainCareProvider",
          text: "Main Care Provider",
          sort: true,
          searchable:true,          
          headerFormatter: priceFormatter,
          formatter: (cell, row) => {
            return row.mainCareProvider
              ? row.mainCareProvider.lname + ", " + row.mainCareProvider.fname
              : "";
          },
          csvFormatter: (cell, row) => {
            return row.mainCareProvider
              ? row.mainCareProvider.lname + ", " + row.mainCareProvider.fname
              : "";
          },   
          filterValue: (cell, row) => {
            return row.mainCareProvider
            ? row.mainCareProvider.lname + ", " + row.mainCareProvider.fname
            : "";
          },       
        },
        {
          dataField: "enrolledPrograms",
          text: "Enrolled Program(s)",
          sort: true,
          searchable:true, 
          formatter: (cell, row) => {
            return row.enrolledPrograms ? row.enrolledPrograms : "NONE";
          },   
          csvFormatter: (cell, row) => {
            return row.enrolledPrograms ? row.enrolledPrograms : "NONE";
          }      
        },
        {
          dataField: "expectationsToFollow",
          text: "Client Expectation to Follow",
          sort: true,
          searchable:true, 
          formatter: (cell, row) => {
              return row.expectationsToFollow ? row.expectationsToFollow : '-';
          },   
          csvFormatter: (cell, row) => {
              return row.expectationsToFollow ? row.expectationsToFollow : '-';
          }      
        },
        {
          dataField: "email",
          text: "Email Address",
          sort: true,
          searchable:true,
          csvFormatter: (cell, row) => {
            return row.email;
          }
        },
        {
          dataField: "username",
          text: "Username",
          searchable:true,
          csvFormatter: (cell, row) => {
            return row.username;
          },
          sort: true
        },
        {
          dataField: "phone",
          text: "Phone",
          searchable:true,
          sort: true,          
          formatter: formatPhone,
          csvFormatter: (cell, row) => {
            return row.phone;
          }
        }
      ]} 
      search 
      exportCSV={{ fileName: "NoReadingsPast6Days.csv" }}
    >
      {(toolKitProp) => (
        <div>
          <div>
            <Row className={`text-center col-md-12 mx-auto float-right`}>
              <Col md={10}>
                <MySearch {...toolKitProp.searchProps} />
              </Col>
              <Col md={2}>
                <MyExportCSV {...toolKitProp.csvProps}>
                  Export CSV
                </MyExportCSV>
              </Col>
            </Row>
          </div>
          <div className="scrollbar-table-cls">
          <BootstrapTable
            wrapperClasses="table-responsive"
            {...toolKitProp.baseProps}
            noDataIndication={ () => <NoDataIndication /> }
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            rowEvents={rowEvents}
            {...toolKitProp.baseProps}
            rowStyle={{ cursor: "pointer" }}
          />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default NoReading;
