import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "2e35f242a46d67eeb74aabc37d5e5d05";

const encryptRequest = (data) => {
    if (data === undefined || data === null) {
        data = '';  // Or some default value like null, depending on your needs
      }
    const encodedData = encodeURIComponent(JSON.stringify(data));
    const encryptData = CryptoJS.AES.encrypt(encodedData, ENCRYPTION_KEY).toString();
    const base64EncodedData = encodeURIComponent(encryptData);
    return base64EncodedData;
}

export default encryptRequest;