import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axiosClient from "../../axios/axios";

const useFetch = (url, options, needAuth = true) => {
  const authToken = useSelector(state => state.user.userDetails.token);
  const [response, setResponse] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(options);
  const [random, setrandom] = useState(0);
  const refetchAgain = () => {
    setrandom(Math.floor(Math.random() * 100 + 1));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
        const response = await axiosClient.get(url, {
           headers: { "x-auth-token": authToken }
        })
        // console.log("This is Response of useFetch:", response);
        if(response?.status == 200 && response?.data){
          setResponse(response?.data);
        }else{
          setResponse({});
        }
        setIsLoading(false);
    } catch (error) {
      console.log("useFetch Error:", error);
      setError(error);
    }
  }
  
  useEffect(() => {
    fetchData();
  }, [ random ]);

  return { response, error, isLoading, refetchAgain };
};

function formatDuration(duration) {
  // return [
  //   ("0" + Math.floor(duration.asHours())).slice(-2),
  //   ("0" + duration.minutes()).slice(-2),
  //   ("0" + duration.seconds()).slice(-2)
  // ].join(":");
  return duration;
}

function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export { useFetch, formatDuration };
