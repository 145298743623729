import CryptoJS from "crypto-js";

const ENCRYPTION_KEY = "2e35f242a46d67eeb74aabc37d5e5d05";

const decryptResponse = (data) => {
    try {
        // First, attempt to decode the data
        const decodedData = decodeURIComponent(data);
        
        // Try to decrypt the data
        var decrypted = CryptoJS.AES.decrypt(decodedData, ENCRYPTION_KEY);
        
        // If decryption was successful, parse and return the JSON object
        const parsedData = JSON.parse(decodeURIComponent(decrypted.toString(CryptoJS.enc.Utf8)));
        return parsedData;
    } catch (e) {
        // If decryption fails, return the original data (assumed to be plaintext)
        return data;
    }
};


export default decryptResponse;