import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Form, FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import Select from "react-select";
import Heading from "./Heading";
import List from "./List";
import CareproviderList from "./CareproviderList";
import ReminderList from "./ReminderList";
import NotificationLogs from "./NotificationLogs";
import moment from "moment";
import { notificationType, ruleTypesNotificationLogs, months, years, adminReportType, appStatus, devicePlatform, userStatus } from "../../constants/general.constant";
import VersionReport from "./vesionReport";
const _ = require("lodash");

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMonth: "",
      selectedYear: "",
      selectedReport: "",
      selectedPatient: "",
      selectedNotificationType : [],
      selectedRuleType : '',
      selectedAppVersion : null,
      link: undefined,
      versionReportLink: undefined,
      page : 1,
      limit : 25,
      sortColumn : 'messageDate',
      sort : 'desc',
      startDate : '',
      endDate : '',
      endDateError: undefined,
      startDateError: undefined,
      search : null, 
      sortFiled : null
    };

  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    if (this.props && this.props.patientProfile) {
      const currentDate = new Date();
      const currentYear = (currentDate.getFullYear()).toString();
      const currentMonth = (currentDate.getMonth() + 1).toString();
      this.setState({ selectedMonth: currentMonth});
      this.setState({ selectedYear:  currentYear});
      this.setState({ selectedReport: "2" })
      this.setState({ selectedPatient: this.props.patientId });
      this.props.dispatch({
        type: "DO_GET_NOTIFICATION_LOGS",
        payload: {
          page: 1,
          limit: 25, patientId: this.props.patientId, month : currentMonth, year : currentYear
        }
      });
    }
    this.props.dispatch({
      type: "DO_GET_APP_VERSIONS"
    });
  }

  fetchCurrentMonthData(month,year) {
    try {
      let startDate = moment()
      .month(month - 1)
      .year(year)
      .startOf("month").format();
      let endDate = moment()
      .month(month - 1)
      .year(year)
      .endOf("month").format();    
      this.props.dispatch({
        type: "DO_GET_CURRENT_MONTH_REPORT",
        payload: {
          startDate: moment(startDate).tz(moment.tz.guess()).utc().format(),
          endDate: moment(endDate).tz(moment.tz.guess()).utc().format(),
          selectedYear: year,
          selectedMonth: month,
          timezone: moment.tz.guess()
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ search: value });

    // Clear any previous timeout if the user keeps typing
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Set a new timeout to call handleClick after 500ms
    this.timeoutId = setTimeout(() => {
      this.getVersionReports({...this.state});
    }, 500); // 500ms delay
  };

  getVersionReports = (query = {}) => {
    const data = {
      page: 1,
      limit: 25
    }
    if (query.page) {
      data.page = query.page;
    } if (query.limit) {
      data.limit = query.limit;
    } if (query.sortFiled) {
      data.sortFiled = query.sortFiled;
    }if(query.selectedAppVersion){
      data.applicationVersion = query.selectedAppVersion && query.selectedAppVersion.applicationVersion;
      data.applicationVersionPlatform = query.selectedAppVersion && query.selectedAppVersion.platform;
    }
    if (query.sort) {
      data.sort = query.sort;
    }if(query.selectedDevicePlatform){
      data.platform = query.selectedDevicePlatform;
    }if(query.selectedAppStatus){
      data.appStatus = query.selectedAppStatus;
    }if(query.selectedUserStatus){
      data.userStatus = query.selectedUserStatus;
    }if(this.state.search){
      data.search = this.state.search;
    }else{
      this.setState({search : null})
    }
    this.props.dispatch({
      type: "DO_GET_VERSION_REPORTS",
      payload: data
    });
  }
  versionReportExports = () => {
    const data = {
      page: 1,
      limit: 25,
      tz :moment.tz.guess(),
      getAll : true
    }
    if (this.state.page) {
      data.page = this.state.page;
    } if (this.state.limit) {
      data.limit = this.state.limit;
    } if (this.state.sortFiled) {
      data.sortFiled = this.state.sortFiled;
    }
    if (this.state.sort) {
      data.sort = this.state.sort;
    }if(this.state.selectedDevicePlatform){
      data.platform = this.state.selectedDevicePlatform;
    }if(this.state.selectedAppStatus){
      data.appStatus = this.state.selectedAppStatus;
    }if(this.state.selectedUserStatus){
      data.userStatus = this.state.selectedUserStatus;
    }if(this.state.search){
      data.search = this.state.search;
    }else{
      this.setState({search : null})
    }
    if(this.state.selectedAppVersion){
      data.applicationVersion = this.state.selectedAppVersion && this.state.selectedAppVersion.applicationVersion;
      data.applicationVersionPlatform = this.state.selectedAppVersion && this.state.selectedAppVersion.platform;
    }
    this.props.dispatch({
      type: "DO_GET_VERSION_REPORTS_EXPORTS",
      payload: data
    });
  }
  render() {
    if (this.props.notificationLogsFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.notificationLogsFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }

    } else {
      this.state.link = undefined;
    }
    // version report export file path
    if (this.props.versionReportsLogsFilePath) {
      if (this.state.versionReportLink === undefined) {
        this.setState({
          versionReportLink: this.props.versionReportsLogsFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.versionReportLink, "_self");
        }
        );
      }
    } else {
      this.state.versionReportLink = undefined;
    }
    const handleReportChange = (event) => {
      const value = event.value;
      if(value == "3"){
        this.setState({ selectedAppStatus: "", selectedDevicePlatform: "", selectedUserStatus : "", search : null, selectedAppVersion : null });
        this.getVersionReports({})
      }
      if (this.state.selectedYear && this.state.selectedMonth && value == "1") {
        this.fetchCurrentMonthData(this.state.selectedMonth,this.state.selectedYear);        
      }
      this.setState({ selectedReport: value, selectedPatient: "", selectedNotificationType: [], selectedRuleType: "", startDate: "", endDate: ""});
      this.setState({ selectedMonth: "",selectedYear: "" });
      if (value == "2") {
        const currentDate = new Date();
        const currentYear = (currentDate.getFullYear()).toString();
        const currentMonth = (currentDate.getMonth() + 1).toString();
        this.setState({ selectedMonth: currentMonth,selectedYear:  currentYear}, () => {
          getPatientReminder()
          getNotificationLogs({ ...this.state, sort: 'desc'})
        });
      }
    };

    const handleMonthChange = (event) => {
      const value = event.value;
      this.setState({ selectedMonth: value });

      if (this.state.selectedYear && value) {
        this.fetchCurrentMonthData(value,this.state.selectedYear);        
      }
    };

    const handleYearChange = (event) => {
      const value = event.value;
      this.setState({ selectedYear: value });

      if (this.state.selectedMonth && value) {
        this.fetchCurrentMonthData(this.state.selectedMonth,value);        
      }
    };

    const handlePatientSelected = (selectedPatient) => {
      if(!_.isEmpty(selectedPatient)){
        const value = selectedPatient["_id"] 
        this.setState({ selectedPatient: value});
        getNotificationLogs({...this.state,selectedPatient : value})
      }else{
        this.setState({selectedPatient: ""}, () =>{
          getNotificationLogs({...this.state})
        })
      }
    };
    
    const handleNotificationType = (value) => {
      this.setState({ selectedNotificationType: value });
      getNotificationLogs({...this.state,selectedNotificationType : value})
    }

    const handleRuleType = (event) => {
      if(_.isEmpty(event)){
        this.setState({ selectedRuleType: "" });
        getNotificationLogs({...this.state,selectedRuleType : null})
      }else{
      const value = event.value
      this.setState({ selectedRuleType: value });
      getNotificationLogs({...this.state,selectedRuleType : value})
      }
    }
    const handleAppVersion = (event) => {
      if(_.isEmpty(event)){
        this.setState({ selectedAppVersion: null });
         this.getVersionReports({...this.state,selectedAppVersion : null})
      }else{
      this.setState({ selectedAppVersion: event });
       this.getVersionReports({...this.state,selectedAppVersion : event})
      }
    }
    const handleAppStatus = (event) => {
      if(_.isEmpty(event)){
        this.setState({ selectedAppStatus: "" });
        this.getVersionReports({...this.state,selectedAppStatus : null})
      }else{
      const value = event.value
      this.setState({ selectedAppStatus: value });
      this.getVersionReports({...this.state,selectedAppStatus : value})
      }
    }
    const handleDevicePlatform = (event) => {
      if(_.isEmpty(event)){
        this.setState({ selectedDevicePlatform: "" });
        this.getVersionReports({...this.state,selectedDevicePlatform : null})
      }else{
      const value = event.value
      this.setState({ selectedDevicePlatform: value });
      this.getVersionReports({...this.state,selectedDevicePlatform : value})
      }
    }
    const handleUserStatus = (event) => {
      if(_.isEmpty(event)){
        this.setState({ selectedUserStatus: "" });
        this.getVersionReports({...this.state,selectedUserStatus : null})
      }else{
      const value = event.value
      this.setState({ selectedUserStatus: value });
      this.getVersionReports({...this.state,selectedUserStatus : value})
      }
    }
    const paginationNotificationLogs = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
      let data = {
        page: page, limit: sizePerPage
      }
      if(type === 'sort' && (this.state.sortColumn === sortField)){
        if(this.state.sort == "desc"){
          data.sort = "asc"
        }else{
          data.sort = "desc"
        }
      }
      if(sortField){
        data.sortColumn = sortField
      }
      this.setState(data, () => {
        getNotificationLogs(this.state);
      });
    }
    const paginationVersionReports = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
      let data = {
        page: page, limit: sizePerPage
      }
      if(type === 'sort' && (this.state.sortFiled === sortField)){
        if(this.state.sort == "desc"){
          data.sort = "asc"
        }else{
          data.sort = "desc"
        }
      }
      if(sortField){
        data.sortFiled = sortField
      }
      this.setState(data, () => {
        this.getVersionReports(this.state);
      });
    }
    const isEndDateValid = (value) => {
      const startDate = this.state.startDate;
      const endDate = this.state.endDate;

      this.setState({
        endDateError: undefined,
        startDateError: undefined
      });
      
      if((!startDate) && (!endDate)){
        this.setState({
          endDateError: undefined,
          startDateError: undefined
        }, () => {
          getNotificationLogs(this.state);
          getPatientReminder()
        });
        return false;
      }else{
        const dateParts = startDate.split('-');
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[2], 10);

        
        const todayDate = moment().format('YYYY-MM-DD');
        let errorFlag = false;
        if(startDate && startDate > todayDate){
          errorFlag = true;
            this.setState({ startDateError : "Please select start date less than today." }, () => {
              getNotificationLogs(this.state);
            });
        }
        if(endDate && endDate > todayDate){
          errorFlag = true;
            this.setState({ endDateError: "Please select end date less than today." }, () => {
              getNotificationLogs(this.state);
            });
        }
        if(endDate && startDate){
          if(startDate > endDate){
            errorFlag = true;
            this.setState({ startDateError: "Please select start date less than end date." }, () => {
              getNotificationLogs(this.state);
            });
          }
          if(endDate < startDate){
            errorFlag = true;
            this.setState({ endDateError: "Please select end date greater than start date." }, () => {
              getNotificationLogs(this.state);
            });
          }
        }
        const isValid = !isNaN(year) && !isNaN(month) && !isNaN(day);
        if(!isValid && year.toString().length == 4){
          errorFlag = true;
        }
        if(!errorFlag){
          this.setState({
            endDateError: undefined,
            startDateError: undefined
          }, () => {
            getNotificationLogs(this.state);
            getPatientReminder()
          });
        }
      }
    };
    const handleDateChange = (event) => {
      const value = event.target.value;
      const name = event.target.name;
      this.setState(
        { [name]: value }, () => {
          isEndDateValid(this.state.endDate);
        }
      );
    }
    const getNotificationLogs = (query = {}) => {
      if(this.state.endDateError || this.state.startDateError){
        return;
     }
      const data = {
        page: 1,
        limit: 25
      }
       if (!_.isEmpty(query.startDate)) {
        data.startDate = query.startDate;
      } if (!_.isEmpty(query.endDate)) {
        data.endDate = query.endDate;
      } if (!_.isEmpty(query.selectedNotificationType)) {
        data.notificationTypes = query.selectedNotificationType
      } if (!_.isEmpty(query.selectedRuleType)) {
        data.ruleType = query.selectedRuleType
      } if (!_.isEmpty(query.selectedPatient)) {
        data.patientId = query.selectedPatient
      } if (query.page) {
        data.page = query.page;
      } if (query.limit) {
        data.limit = query.limit;
      } if (query.sortColumn) {
        data.sortColumn = query.sortColumn;
      }
      if (query.sort) {
        data.sort = query.sort;
      }
        this.props.dispatch({
          type: "DO_GET_NOTIFICATION_LOGS",
          payload: data
        });
      
    }
    const getPatientReminder = () =>{
      if(this.state.endDateError){
        return;
     }
      const data = { getAll : true}
      if (!_.isEmpty(this.state.startDate)) {
        data.startDate = this.state.startDate;
      } if (!_.isEmpty(this.state.endDate)) {
        data.endDate = this.state.endDate;
      }if(_.isEmpty(this.state.startDate) && _.isEmpty(this.state.endDate)){
        data.getAll =  true;
      }
     if(!(this.props && this.props?.patientProfile)){
       this.props.dispatch({
         type: "DO_GET_REMINDER_PATIENT_LIST",
         payload: data
        });
      }
    }
    const notificationLogsExportCSV = () => {
      const data = {
        page: 1,
        limit: 25,
        tz :moment.tz.guess(),
        getAll : true
      }
     
      if (!_.isEmpty(this.state.selectedMonth)) {
        data.month = this.state.selectedMonth
      } if (!_.isEmpty(this.state.selectedYear)) {
        data.year = this.state.selectedYear
      } if (!_.isEmpty(this.state.selectedNotificationType)) {
        data.notificationTypes = this.state.selectedNotificationType
      } if (!_.isEmpty(this.state.selectedRuleType)) {
        data.ruleType = this.state.selectedRuleType
      } if (!_.isEmpty(this.state.selectedPatient)) {
        data.patientId = this.state.selectedPatient
      } if(!_.isEmpty(this.state.startDate)){
        data.startDate = this.state.startDate
      }
      if(!_.isEmpty(this.state.endDate)){
        data.endDate = this.state.endDate
      }
        this.props.dispatch({
          type: "DO_GET_NOTIFICATION_LOGS_EXPORT_CSV",
          payload: data
        });
    }
    const clearFilter = () => {
      if(this.state.selectedReport === "2"){    
        const currentDate = new Date();
        const currentYear = (currentDate.getFullYear()).toString();
        const currentMonth = (currentDate.getMonth() + 1).toString();
        this.setState({ selectedMonth: currentMonth,selectedYear:  currentYear}, () => {
          getPatientReminder()
        });
        if(!this.props.patientProfile){
          this.setState({ selectedPatient : ''});
        }
        this.setState({ selectedNotificationType: [], selectedRuleType: "", endDate : '', startDate : '', endDateError : undefined, startDateError: undefined }, () => {
          getNotificationLogs({ ...this.state, selectedYear: currentYear, selectedMonth: currentMonth })
        });
      }
      if(this.state.selectedReport === "3"){
        this.setState({ selectedAppStatus: "", selectedDevicePlatform: "", selectedUserStatus : "", search : null, selectedAppVersion : null });
        this.getVersionReports({})
      }
    }
    const dispalyClearButton = (!_.isEmpty(this.state.selectedReport)) && (!_.isEmpty(this.state.selectedRuleType) || !_.isEmpty(this.state.selectedPatient) || !_.isEmpty(this.state.selectedNotificationType) || !_.isEmpty(this.state.endDate) || !_.isEmpty(this.state.startDate) || !_.isEmpty(this.state.selectedAppStatus) || !_.isEmpty(this.state.selectedDevicePlatform) || !_.isEmpty(this.state.search) || !_.isEmpty(this.state.selectedUserStatus && this.state.selectedUserStatus.toString()) || !_.isEmpty(this.state.selectedAppVersion)) ? true : false;
    const dispalyPatientClearButton = (!_.isEmpty(this.state.selectedRuleType) || !_.isEmpty(this.state.selectedNotificationType) || !_.isEmpty(this.state.endDate) || !_.isEmpty(this.state.startDate)) ? true : false;

    if (this.props.userRole != "admin" && this.props.userRole != "support" && !this.props.patientProfile) {
      return (
        <div>
          <div className="row">
            <Heading
              title="MONTHLY REPORT"
              description=""
              backbutton="d-none"
              mainbutton="d-block"
            ></Heading>

            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body project-tab pt-2">
                  <Row>
                    <Col sm="12" className="grid-margin mt-4">
                      <Row>
                        <Col md={2}>
                          <Form>
                            <FormGroup>
                              <Label>Select a month</Label>
                              <Select
                                onChange={handleMonthChange}
                                options={months}
                                getOptionLabel={(option) =>  option.label}
                                getOptionValue={(option) => option.value}
                                value={months.find(month => month.value === this.state.selectedMonth)}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label>Select a year</Label>
                              <Select
                                onChange={handleYearChange}
                                options={years}
                                getOptionLabel={(option) =>  option.label}
                                getOptionValue={(option) => option.value}
                                value={years.find(year => year.value === this.state.selectedYear)}
                              />
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row>
                      {this.state.selectedMonth != "" &&
                        this.props.currentMonthReport && (
                          <CareproviderList
                            currentMonthReport={this.props.currentMonthReport}
                          />
                        )}
                    </Col>
                    {this.state.selectedReport == 2 &&
                      this.state.selectedMonth != "" &&
                      this.state.selectedYear != "" &&
                      this.state.selectedPatient != "" &&
                      this.props.patientReminderList && (
                        <ReminderList
                          patientReminderList={this.props.patientReminderList}
                        />
                      )}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row">
            <Heading
              title="MONTHLY REPORT"
              description=""
              backbutton="d-none"
              mainbutton="d-block"
            ></Heading>

            <div className="col-12 grid-margin">
              <div className={this.props.patientProfile ? '' : 'card'}>
                <div className= {this.props.patientProfile ? '' : 'card-body project-tab pt-2'}>
                  <Row className="grid-margin mt-4">
                  {!(this.props && this.props.patientProfile) && (
                    <Col md={12}>
                        {adminReportType && adminReportType.find(item => item.value === this.state.selectedReport).label == "Notifications & Messages" && (
                          <Alert color="success">
                            Here, by default we are viewing past 6 months of notifications and messages data. You can use the filters for retrieving specific data.
                          </Alert>
                        )}
                    <Row className="mt-4">
                    <Col md={dispalyClearButton && this.state.selectedReport != 3 ? 10 : 12}>
                    <Row>
                    <Col md={4}>
                    <Form>
                      <FormGroup>
                        <Label style={{fontWeight:600, color:"black"}}>Report</Label>
                        <Select
                                 onChange={handleReportChange}
                                 options={adminReportType}
                                 getOptionLabel={(option) =>  option.label}
                                 getOptionValue={(option) => option.value}
                                 value={adminReportType.find(item => item.value === this.state.selectedReport)}
                                 styles={{
                                  // Style the placeholder, option and value container to have cursor pointer
                                  placeholder: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  })
                                }}
                               />
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col md={4}>
                   {this.state.selectedReport == 2 ? (<Form>
                    <FormGroup>
                      <Label style={{fontWeight:600, color:"black"}}>Filter By Start Date</Label>
                      <Input
                      className="input-date-filter remove-bottom-margin"
                      type="date"
                      name="startDate"
                      max={this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                      onChange={handleDateChange}
                      value={this.state.startDate}
                      style={{height : '54px'}}
                    />
                    <Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5
                  }}>{this.state.startDateError}</Label>
                    </FormGroup>
                  </Form>) : this.state.selectedReport == 3 ? (<Form>
                    <FormGroup>
                      <Label style={{fontWeight:600, color:"black"}}>App Status </Label>
                      <Select
                      onChange={handleAppStatus}
                      isClearable={true}
                      options={appStatus}
                      getOptionLabel={(option) =>
                        option.label
                      }
                      getOptionValue={(option) => option.value}
                      styles={{
                        // Style the placeholder, option and value container to have cursor pointer
                        placeholder: (provided) => ({
                          ...provided,
                          cursor: 'pointer'
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          cursor: 'pointer'
                        }),
                        option: (provided) => ({
                          ...provided,
                          cursor: 'pointer'
                        })
                      }}
                      value={this.state.selectedAppStatus ? appStatus.find(item => item.value === this.state.selectedAppStatus) : ''}
                    />
                    </FormGroup>
                  </Form>)  : (<Form>
                    <FormGroup>
                      <Label style={{fontWeight:600, color:"black"}}>Month</Label>
                      <Select
                               onChange={handleMonthChange}
                               options={months}
                               getOptionLabel={(option) =>  option.label}
                               getOptionValue={(option) => option.value}
                               value={months.find(month => month.value === this.state.selectedMonth)}
                             />
                    </FormGroup>
                  </Form>) } 
                  </Col>
                  <Col md={4}>
                  {this.state.selectedReport == 2 ? ( 
                    <Form>
                      <FormGroup>
                        <Label style={{fontWeight:600, color:"black"}}>Filter By End Date</Label>
                        <Input
                        className="input-date-filter remove-bottom-margin"
                        type="date"
                        name="endDate"
                        max={moment().format("YYYY-MM-DD")}
                        onChange={handleDateChange}
                        value={this.state.endDate}
                        style={{height : '54px'}}
                      />
                        <Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5
                  }}>{this.state.endDateError}</Label>
                      </FormGroup>
                    </Form>) : this.state.selectedReport == 3 ? (<Form>
                      <FormGroup>
                        <Label style={{fontWeight:600, color:"black"}}>Device Platform </Label>
                        <Select
                        onChange={handleDevicePlatform}
                        isClearable={true}
                        options={devicePlatform}
                        getOptionLabel={(option) =>
                          option.label
                        }
                        getOptionValue={(option) => option.value}
                        styles={{
                          // Style the placeholder, option and value container to have cursor pointer
                          placeholder: (provided) => ({
                            ...provided,
                            cursor: 'pointer'
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            cursor: 'pointer'
                          }),
                          option: (provided) => ({
                            ...provided,
                            cursor: 'pointer'
                          })
                        }}
                        value={this.state.selectedDevicePlatform ? devicePlatform.find(item => item.value === this.state.selectedDevicePlatform) : ''}
                      />
                      </FormGroup>
                    </Form>)  : (<Form>
                      <FormGroup>
                       <Label style={{fontWeight:600, color:"black"}}>Year</Label>
                    <Select
                    onChange={handleYearChange}
                    options={years}
                    getOptionLabel={(option) =>  option.label}
                    getOptionValue={(option) => option.value}
                    value={years.find(year => year.value === this.state.selectedYear)}/>
                    </FormGroup>
                  </Form>)}
                  </Col>
                  </Row>
                  </Col>
                {(dispalyClearButton && this.state.selectedReport != 3) && (<Col md={2}>
                  <button
                  onClick={() => {
                    clearFilter()
                  }}
                  className="btn btn-info close-click-cls mt-4 mr-0"
                  style={{  width: 145, height: 37 }}
                >
                <i class="mdi mdi-close" style={{
                 fontSize: 20
               }}></i>
                  Clear Filters
                </button></Col>) }
                 </Row> </Col>
                   )}
                    {this.state.selectedReport == 1 &&
                    this.state.selectedMonth != "" &&
                    this.state.selectedYear != "" &&
                    this.props.ongoingRequest && (
                      <List
                        currentMonthReport={[]}
                        selectedMonth={this.state.selectedMonth}
                        selectedYear={this.state.selectedYear}
                        loading={this.props.ongoingRequest}
                      />
                      )}
                    {this.state.selectedReport == 1 &&
                      this.state.selectedMonth != "" &&
                      this.state.selectedYear != "" &&
                      !this.props.ongoingRequest && (
                        <List
                          currentMonthReport={this.props.currentMonthReport}
                          selectedMonth={this.state.selectedMonth}
                          selectedYear={this.state.selectedYear}
                          loading={this.props.ongoingRequest}
                        />
                      )}
                    {this.state.selectedReport == 2  && !this.props.patientProfile && (
                        <Col md={12}>
                          <Form>
                          <Row className="mt-1">
                          <Col md={10}>
                          <Row>
                          <Col md={4}>
                            <FormGroup>
                              <Label style={{fontWeight:600, color:"black"}} for="exampleSelect">Pick a patient</Label>
                              <Select
                                onChange={handlePatientSelected}
                                options={this.props.reminderPatientList}
                                isMulti={false}
                                getOptionLabel={(option) =>
                                  option
                                    ? option["lname"] +
                                      " " +
                                      option["fname"]
                                    : ""
                                }
                                getOptionValue={(option) => option}
                                value={!_.isEmpty(this.state.selectedPatient) ? this.props.reminderPatientList.find((item) => {
                                  let name = '';
                                  if(item.recepient && item.recepient._id === this.state.selectedPatient){
                                  name = item["lname"] +
                                      " " +
                                      item["fname"]
                                  }
                                  return name
                                }) : ''}
                                styles={{
                                  // Style the placeholder, option and value container to have cursor pointer
                                  placeholder: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  })
                                }}
                                isClearable={true}
                              />
                            </FormGroup>
                            </Col>
                            <Col md={4}>
                            <FormGroup>
                            <Label style={{fontWeight:600, color:"black"}} for="exampleSelect">Notification type</Label>
                            <Select
                                onChange={handleNotificationType}
                                options={Object.values(notificationType)}
                                isMulti={true}
                                getOptionLabel={(option) =>
                                  option
                                }
                                getOptionValue={(option) => option}
                                value={this.state.selectedNotificationType}
                                styles={{
                                  // Style the placeholder, option and value container to have cursor pointer
                                  placeholder: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  })
                                }}
                              />
                          </FormGroup></Col>
                            <Col md={4} style={{ cursor: 'pointer' }} >
                            <FormGroup>
                            <Label style={{fontWeight:600, color:"black"}} for="exampleSelect">Rule type</Label>
                            <Select
                            onChange={handleRuleType}
                            isClearable={true}
                            options={ruleTypesNotificationLogs}
                            getOptionLabel={(option) =>
                              option.label
                            }
                            getOptionValue={(option) => option.value}
                            styles={{
                              // Style the placeholder, option and value container to have cursor pointer
                              placeholder: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              })
                            }}
                            value={this.state.selectedRuleType ? ruleTypesNotificationLogs.find(item => item.value === this.state.selectedRuleType) : ''}
                          />
                          </FormGroup></Col>
                          </Row>
                          </Col>
                          <Col md={2}>
                              <button
                                style={{ marginTop: 1, width: 145, height: 37 }}
                                className="btn btn-info mt-4 download-click-cls float-right"
                                type="button"
                                onClick={() => notificationLogsExportCSV()}
                                disabled={this.props.ongoingNotificationAndMessagesExportRequest ? true : false}
                              >
                              {(this.props.ongoingNotificationAndMessagesExportRequest) ? (
                                <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> // Loader icon
                              ) : (
                                  <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>
                              )}Export to CSV
                              </button>
                          </Col>
                        </Row>
                          </Form>
                        </Col>
                      )}
                    {this.state.selectedReport == 3 && (
                      <Col md={12}>
                      <Form>
                      <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label style={{fontWeight:600, color:"black"}} for="exampleSelect">App Version</Label>
                          <Select
                          onChange={handleAppVersion}
                          isClearable={true}
                          options={this.props.appVersions ? this.props.appVersions : [] }
                          getOptionLabel={(option) =>
                            option.label
                          }
                          getOptionValue={(option) => option.value}
                            styles={{
                              // Style the placeholder, option and value container to have cursor pointer
                              placeholder: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              })
                            }}
                          value={this.state.selectedAppVersion ? this.props.appVersions.find(item => item.value == this.state.selectedAppVersion && this.state.selectedAppVersion.value) : ''}
                          />
                        </FormGroup>
                        </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label style={{fontWeight:600, color:"black"}} for="exampleSelect">User Status</Label>
                          <Select
                          onChange={handleUserStatus}
                          isClearable={true}
                          options={userStatus}
                          getOptionLabel={(option) =>
                            option.label
                          }
                          value={this.state.selectedUserStatus ? userStatus.find(item => item.value === this.state.selectedUserStatus) : ''}
                          getOptionValue={(option) => option.value}
                            styles={{
                              // Style the placeholder, option and value container to have cursor pointer
                              placeholder: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              }),
                              option: (provided) => ({
                                ...provided,
                                cursor: 'pointer'
                              })
                            }}
                          />
                        </FormGroup>
                        </Col>
                      <Col md={4} style={{ display : 'flex', alignItems: 'center' }}>
                      <button
                        style={{ marginTop: 1, width: 145, height: 37, margin : 0 }}
                        className="btn btn-info download-click-cls float-right"
                        type="button"
                        onClick={() => this.versionReportExports()}
                        disabled={!(!this.props?.ongoingNotificationLogsRequest && !this.props.ongoingVersionReportExportLogsRequest && this.props.versionReports && this.props.versionReports.data.length > 0)}
                      >
                        {this.props.ongoingVersionReportExportLogsRequest ? <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i>  : <i class="mdi mdi-download" style={{
                          fontSize: 20
                        }}></i>} Export to CSV
                      </button>
                      {dispalyClearButton && <button
                        onClick={() => {
                          clearFilter()
                        }}
                        className="btn btn-info close-click-cls ml-2"
                        style={{  width: 145, height: 37, margin : 0  }}
                      >
                      <i class="mdi mdi-close" style={{
                        fontSize: 20
                      }}></i>
                        Clear Filters
                      </button>}
                      </Col>
                      </Row>
                      </Form>
                      <Col md={12}>
                      <input
                      type="text"
                      className="form-control input-date-filter remove-bottom-margin mb-2"
                      id="exampleInputName1Test"
                      style={{ fontSize: '20px' }}
                      placeholder="Search Name, Email, etc..."
                      value={this.state.search}
                      onChange={this.handleChange} // Call handleChange on every input change
                      />
                      </Col>
                      </Col>
                    )}
                   
                    {(this.props && this.props.patientProfile) && (
                        <Col md={12}>
                          <Row>
                          <Col md={dispalyPatientClearButton ? 9 : 10}>
                          <Row>
                          <Col md={3}>
                          <Form>
                          <FormGroup>
                            <Label style={{fontWeight:600, color:"black"}}>Filter By Start Date</Label>
                            <Input
                            className="input-date-filter remove-bottom-margin"
                            type="date"
                            name="startDate"
                            max={this.state.endDate ? moment(this.state.endDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")}
                            onChange={handleDateChange}
                            value={this.state.startDate}
                            style={{height : '54px'}}
                          />
                          <Label
                          style={{
                            color: "red",
                            textAlign: "left",
                            padding: 5
                          }}>{this.state.startDateError}</Label>
                          </FormGroup>
                        </Form>
                            </Col>
                            <Col md={3}>
                                <Form>
                                  <FormGroup>
                                    <Label style={{ fontWeight: 600, color: "black" }}>Filter By End Date</Label>
                                    <Input
                                      className="input-date-filter remove-bottom-margin"
                                      type="date"
                                      name="endDate"
                                      max={moment().format("YYYY-MM-DD")}
                                      onChange={handleDateChange}
                                      value={this.state.endDate}
                                      style={{ height: '54px' }}
                                    />
                                    <Label
                                      style={{
                                        color: "red",
                                        textAlign: "left",
                                        padding: 5
                                      }}>{this.state.endDateError}</Label>
                                  </FormGroup>
                                </Form>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="exampleSelect" style={{fontWeight:600, color:"black"}}>Notification type</Label>
                                <Select
                                onChange={handleNotificationType}
                                options={Object.values(notificationType)}
                                isMulti={true}
                                getOptionLabel={(option) =>
                                  option
                                }
                                value={this.state.selectedNotificationType}
                                getOptionValue={(option) => option}
                                styles={{
                                  // Style the placeholder, option and value container to have cursor pointer
                                  placeholder: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  })
                                }}
                              />
                              </FormGroup></Col>
                            <Col md={3}>
                              <FormGroup>
                                <Label for="exampleSelect" style={{fontWeight:600, color:"black"}}>Rule type</Label>
                                <Select
                                isClearable={true}
                                onChange={handleRuleType}
                                options={ruleTypesNotificationLogs}
                                getOptionLabel={(option) =>
                                  option.label
                                }
                                getOptionValue={(option) => option.value}
                                value={this.state.selectedRuleType ? ruleTypesNotificationLogs.find(item => item.value === this.state.selectedRuleType) : ''}
                                styles={{
                                  // Style the placeholder, option and value container to have cursor pointer
                                  placeholder: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  }),
                                  option: (provided) => ({
                                    ...provided,
                                    cursor: 'pointer'
                                  })
                                }}
                              />
                              </FormGroup></Col>
                          </Row>
                          </Col>
                          {(dispalyPatientClearButton) && (<Col md={1}>
                            <button
                            onClick={() => {
                              clearFilter()
                            }}
                            className="btn btn-info close-click-cls mt-4 mr-0"
                            style={{  width: 145, height: 37 }}
                          >
                          <i class="mdi mdi-close" style={{
                           fontSize: 20
                         }}></i>
                            Clear Filters
                          </button></Col>) }
                            
                              <Col md={2}>
                              <button
                              style={{width: 145, height: 37}}
                              className="btn btn-info download-click-cls mt-4 float-right"
                              type="button"
                              onClick={() => notificationLogsExportCSV()}
                            >
                              <i class="mdi mdi-download" style={{
                                fontSize: 20
                              }}></i> Export to CSV
                            </button></Col>
                          </Row>
                            
                        </Col>
                    )}
                      {this.state.selectedReport == 2 && 
                        this.props.ongoingNotificationLogsRequest && (
                          <NotificationLogs
                          loading={this.props.ongoingNotificationLogsRequest}
                            notificationLogs={{data : []}}
                            pagination={paginationNotificationLogs}
                            patientProfile={this.props && this.props.patientProfile ? true : false}
                          />
                        )}

                        {this.state.selectedReport == 2 && 
                          !this.props.ongoingNotificationLogsRequest &&
                          this.props.notificationLogs && (
                            <NotificationLogs
                            loading={this.props.ongoingNotificationLogsRequest}
                              notificationLogs={this.state.endDateError || this.state.startDateError ? [] : this.props.notificationLogs}
                              pagination={paginationNotificationLogs}
                              patientProfile={this.props && this.props.patientProfile ? true : false}
                            />
                          )}
                          {this.state.selectedReport == 3 && 
                            this.props.ongoingNotificationLogsRequest && (
                              <VersionReport
                              loading={this.props.ongoingNotificationLogsRequest}
                                versionReports={{data : []}}
                                pagination={paginationVersionReports}
                                patientProfile={this.props && this.props.patientProfile ? true : false}
                              />
                          )}
    
                          {this.state.selectedReport == 3 && 
                            !this.props.ongoingNotificationLogsRequest &&
                            this.props.versionReports && (
                              <VersionReport
                              loading={this.props.ongoingNotificationLogsRequest}
                              versionReports={this.state.endDateError || this.state.startDateError ? [] : this.props.versionReports}
                                pagination={paginationVersionReports}
                                patientProfile={this.props && this.props.patientProfile ? true : false}
                              />
                          )}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    currentMonthReport,
    reminderPatientList,
    patientReminderList,
    ongoingRequest,
    usersOfferedAccess,
    notificationLogs,
    versionReports,
    notificationLogsFilePath,
    ongoingNotificationLogsRequest,
    versionReportsLogsFilePath,
    ongoingVersionReportExportLogsRequest,
    appVersions,
    ongoingNotificationAndMessagesExportRequest
  } = careprovider;
  const userRole = user.userDetails.role;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    currentMonthReport,
    reminderPatientList,
    patientReminderList,
    usersOfferedAccess,
    ongoingRequest,
    userRole,
    notificationLogs,
    notificationLogsFilePath,
    ongoingNotificationLogsRequest,
    versionReports,
    versionReportsLogsFilePath,
    ongoingVersionReportExportLogsRequest,
    appVersions,
    ongoingNotificationAndMessagesExportRequest
  };
};

export default connect(mapStateToProps)(Reports);
