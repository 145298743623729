import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import callingIcon from "../../files/called.png";
import UserName from "../Reusable/UserName";

const _ = require("lodash");
function fullname(cell) {
  return <UserName user={cell} />;
}
function formatPatient(cell, row) {
  return <UserName user={row.userObject}>({cell})</UserName>;
}
function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
function formatDuration(duration) {
  return [
    ("" + Math.floor(duration.asHours())).slice(-2),
    ("0" + duration.minutes()).slice(-2),
    ("0" + duration.seconds()).slice(-2)
  ].join(":");
}

function formatGrand(cell, row, index, extra) {
  const finalGrandRPM = totalRPM(row);
  const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  const dateTo = moment(row.dateTo).endOf("day").format("MMMM DD, YYYY");
  return (
    <div className="text-center">
      <h2 className="mb-0 ccmTimeText">{finalGrandRPM}</h2>
      <small className="ccm-small-text">
        <b>HOUR:MINUTE:SECOND</b>
      </small>
      <div className="pt-1">
        ({dateFrom}- {dateTo})
      </div>
    </div>
  );
}
function formatGrandCCM(cell, row, index, extra) {
  const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  const dateTo = moment(row.dateTo).endOf("day").format("MMMM DD, YYYY");

  const addOneDay = moment(row.dateTo).add(1, "day").format("MMMM DD, YYYY");
  const filterSession = _.filter(row.trackTime, function (value) {
    return moment(value.startTime).isBetween(dateFrom, addOneDay);
  });

  const rpm = _.filter(filterSession, { pmMode: "RPM MODE" });
  const ccm = _.filter(filterSession, { pmMode: "CCM MODE" });

  const getTimeOnlyRPM = _.map(rpm, "time");
  const getTimeOnlyCCM = _.map(ccm, "time");
  // const totalSessionRPM = getTimeOnlyRPM.reduce((sum, string) => {
  //   var mins, secs;
  //   [mins, secs] = string
  //     .split(":")
  //     .slice(-2)
  //     .map(n => parseInt(n, 10));
  //   return sum + mins * 60 + secs;
  // }, 0);
  const totalSessionRPM = getTimeOnlyRPM.reduce(
    (acc, time) => acc.add(moment.duration(time)),
    moment.duration()
  );
  const totalSessionCCM = getTimeOnlyCCM.reduce(
    (acc, time) => acc.add(moment.duration(time)),
    moment.duration()
  );
  // const totalSessionCCM = getTimeOnlyCCM.reduce((sum, string) => {
  //   var mins, secs;
  //   [mins, secs] = string
  //     .split(":")
  //     .slice(-2)
  //     .map(n => parseInt(n, 10));
  //   return sum + mins * 60 + secs;
  // }, 0);
  const finalGrandRPM = formatDuration(totalSessionRPM);
  const finalGrandCCM = formatDuration(totalSessionCCM);
  return (
    <div className="text-center">
      <h2 className="mb-0 ccmTimeText">{finalGrandCCM}</h2>
      <small className="ccm-small-text">
        <b>HOUR:MINUTE:SECOND</b>
      </small>
      <div className="pt-1">
        ({dateFrom}- {dateTo})
      </div>
    </div>
  );
}
function formatDate(cell) {
  return moment(cell).tz("America/New_York").format("MMMM DD, YYYY hh:mm:ss A") + " (America/New York)";
}
const defaultSorted = [
  {
    dataField: "endTime",
    order: "desc"
  }
];

const printStyling = `@media print {
  @page { size: landscape; }
}`;

const rowComponent = (row) => (
  <div style={{ printStyling }}>
    <h4 className="text-center">{row.fullname}</h4>
    <div className="scrollbar-table-cls">
    <BootstrapTable
      wrapperClasses="table-responsive"
      keyField="_id"
      noDataIndication="No Data Found"
      data={row.trackTime}
      defaultSorted={defaultSorted}
      filter={filterFactory()}
      columns={[
        {
          dataField: "patient",
          text: "Patient Name",
          sort: true,
          formatter: fullname
        },
        {
          dataField: "patient.organization",
          text: "Patient Organization",
          sort: true
        },
        {
          dataField: "careProvider",
          text: "Tracked By",
          sort: true,
          formatter: fullname
        },

        {
          dataField: "pmMode",
          text: "Patient Monitor Mode",
          sort: true
        },
        {
          dataField: "startTime",
          text: "Start Time",
          sort: true,
          formatter: formatDate
        },
        {
          dataField: "endTime",
          text: "End Time",
          sort: true,
          formatter: formatDate
        },
        {
          dataField: "time",
          text: "Total Collected Time",
          sort: true
        },
        {
          dataField: "comment",
          text: "Note",
          formatter: (cell, row) => {
            return <p>{cell}</p>;
          }
        },
        {
          dataField: "hadPhoneConsultation",
          text: "Call Included",
          sort: true,
          formatter: (cell, row) => {
            return row.hadPhoneConsultation ? "Yes" : "No";
          }
        }
      ]}
    />
    </div>
  </div>
);
function totalRPM(row) {
  const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  const dateTo = moment(row.dateTo).endOf("day").format("MMMM DD, YYYY");

  const addOneDay = moment(row.dateTo).add(1, "day").format("MMMM DD, YYYY");
  const filterSession = _.filter(row.trackTime, function (value) {
    return moment(value.startTime).isBetween(dateFrom, addOneDay);
  });

  const rpm = _.filter(filterSession, { pmMode: "RPM MODE" });
  const ccm = _.filter(filterSession, { pmMode: "CCM MODE" });

  const getTimeOnlyRPM = _.map(rpm, "time");
  const getTimeOnlyCCM = _.map(ccm, "time");
  // const totalSessionRPM = getTimeOnlyRPM.reduce((sum, string) => {
  //   var mins, secs;
  //   [mins, secs] = string
  //     .split(":")
  //     .slice(-2)
  //     .map(n => parseInt(n, 10));
  //   return sum + mins * 60 + secs;
  // }, 0);
  const totalSessionRPM = getTimeOnlyRPM.reduce(
    (acc, time) => acc.add(moment.duration(time)),
    moment.duration()
  );
  return formatDuration(totalSessionRPM);
}

function getCurrentCCMTrackings(row) {
  // const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  // const dateTo = moment(row.dateTo)
  //   .endOf("day")
  //   .add(1, "day")
  //   .format("MMMM DD, YYYY");

  const currentCcmTracking = _.filter(row.ccmLogs, { hadPhoneConsultation: true });
  return currentCcmTracking;
}

function billable(cell, row) {
  const readingCount = row.readingCount ? row.readingCount : 0; 
  const currentCcmTracking = getCurrentCCMTrackings(row);
  const finalGrandRPM = totalRPM(row);
  const finalGrandCCM = totalCCM(row);
  let cptcode99457 = "";
  let cptcode99458 = "";
  let cptCode99490 = "";
  let cptCode99439 = "";

  var b = "00:20:00".split(":");
  var rpmseconds = moment.duration(finalGrandRPM).asSeconds();
  var twentyminutes = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];
  var aforty = finalGrandRPM.split(":");
  var bforty = "00:40:00".split(":");
  var rpmsecondsforty = +aforty[0] * 60 * 60 + +aforty[1] * 60 + +aforty[2];
  var twentyminutesforty = +bforty[0] * 60 * 60 + +bforty[1] * 60 + +bforty[2];
  if (rpmsecondsforty >= twentyminutesforty && readingCount >= 2 && currentCcmTracking && currentCcmTracking.length > 0) {
    const twentyMinutesInSeconds = 1200;
    const numberOfRepeats = rpmsecondsforty / twentyMinutesInSeconds;

    var loopedCode = [];
    for (var k = 2; k < numberOfRepeats; k++) {
      loopedCode.push("99458");
    }
    if (numberOfRepeats > 1) {
      cptcode99458 = loopedCode.toString();
    } else {
      cptcode99458 = "99458";
    }
  }
  if (rpmseconds >= twentyminutes && readingCount >= 2 && currentCcmTracking && currentCcmTracking.length > 0) {
    cptcode99457 = "99457";
  }

  // CCM CODES CHECK START
  var ccmseconds = moment.duration(finalGrandCCM).asSeconds();
  var ccmb = "00:20:00".split(":");
  var ccmtwentyminutes = +ccmb[0] * 60 * 60 + +ccmb[1] * 60 + +ccmb[2];
  var ccmaforty = finalGrandCCM.split(":");
  var ccmbforty = "00:40:00".split(":");
  var ccmsecondsforty =
    +ccmaforty[0] * 60 * 60 + +ccmaforty[1] * 60 + +ccmaforty[2];
  var ccmtwentyminutesforty =
    +ccmbforty[0] * 60 * 60 + +ccmbforty[1] * 60 + +ccmbforty[2];
  if (ccmseconds >= ccmtwentyminutes) {
    cptCode99490 = "99490";
  }
  if (ccmsecondsforty >= ccmtwentyminutesforty) {
    const twentyMinutesInSeconds = 1200;
    const numberOfRepeats = ccmsecondsforty / twentyMinutesInSeconds;

    var loopedCode = [];
    for (var k = 0; k < 2; k++) {
      loopedCode.push("99439");
    }
    if (numberOfRepeats > 1) {
      cptCode99439 = loopedCode.toString();
    } else {
      cptCode99439 = "99439";
    }
  }

  let noteCreated;
  const startDate = moment(row.dateFrom).startOf("month").format("YYYY-MM-DD");
  if (row) {
    noteCreated = moment(row.noteCreatedAt)
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  const is99454Eligible =
    row.checkEligibleDate &&
    moment(row.checkEligibleDate).startOf("month").format("YYYY-MM-DD") <=
      startDate;
  const isEqualDate = startDate == noteCreated;
  const isEligible99454 =
    row && row.isEligible99454 && is99454Eligible ? "99454" : "";
  const isEligible99453 =
    row && row.isEligible99453 && isEqualDate ? "99453" : "";
  return (
    <div className="mx-auto text-center">
      {(cptCode99490 ? cptCode99490 + ", " : "") +
        (cptCode99439 ? cptCode99439 : "") +
        // (isEligible99453 ? isEligible99453 + ", " : "") +
        // (isEligible99454 ? isEligible99454 + ", " : "") +
        (cptcode99457 ? cptcode99457 + ", " : "") +
        (cptcode99458 ? cptcode99458 + ", " : "")}
    </div>
  );
}
function totalCCM(row) {
  const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
  const dateTo = moment(row.dateTo).endOf("day").format("MMMM DD, YYYY");

  const addOneDay = moment(row.dateTo).add(1, "day").format("MMMM DD, YYYY");
  const filterSession = _.filter(row.trackTime, function (value) {
    return moment(value.startTime).isBetween(dateFrom, addOneDay);
  });

  const ccm = _.filter(filterSession, { pmMode: "CCM MODE" });

  const getTimeOnlyCCM = _.map(ccm, "time");
  const totalSessionRPM = getTimeOnlyCCM.reduce(
    (acc, time) => acc.add(moment.duration(time)),
    moment.duration()
  );
  return formatDuration(totalSessionRPM);
}
const interventionButton = (cell, row, rowIndex, extra) => {
  const finalGrandRPM = totalRPM(row);
  if (finalGrandRPM == "00:00:00") {
    return "";
  }
  return (
    <div className="text-center">
      <button
        className="btn btn-info"
        onClick={() => {
          extra.openInterventionReport(row.trackTime[0]);
        }}
      >
        <i className="fa fa-th-list" aria-hidden="true" />
      </button>
    </div>
  );
};

/*
 * Print UI of table UI
 */
class CCMListClass extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const printPageStyle = "@page { size: A4 landscape; }";
    const props = this.props.data;
    return (
      <div id="printCCM" className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print">
        <style>{printPageStyle}</style>
        <h2 className="text-center">
          {" "}
          PM SESSIONS {moment(props.startDate).format("LL")} -{" "}
          {moment(props.endDate).format("LL")}
        </h2>
        <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="userId"
          noDataIndication="No Data Found"
          data={_.orderBy(props.pmRecords, "fullname", "asc")}
          filter={filterFactory()}
          columns={[
            {
              dataField: "fullname",
              text: "Last, First Name",
              sort: true,
              formatter: formatPatient,
              csvFormatter: (cell, row) => {
                return row.fullname;
              },
              headerStyle: (colum, colIndex) => {
                return { width: '80px', textAlign: 'left' };
              }
            },
            {
              dataField: "bday",
              text: "DOB",
              formatter: (cell, row) => {
                return moment(row.userObject.bday).format("MM/DD/YY");
              },
              csvFormatter: (cell, row) => {
                return moment(row.userObject.bday).format("MM/DD/YY");
              },
              sort: true,
              style: {
                width: '10px'
              }
            },
            // {
            //   dataField: "icd10",
            //   text: "ICD 10 Code",
            //   formatter: (cell, row) => {
            //     return "";
            //   },
            //   csvFormatter: (cell, row) => {
            //     return "";
            //   },
            //   sort: true
            // },
            {
              dataField: "conditions",
              text: "Condition(s) Managed",
              formatter: (cell, row) => {
                if (row.conditions && row.conditions.length > 0) {
                  var x = row.conditions.toString();

                  var a = [];
                  var i = 15;

                  do {
                    a.push(x.substring(0, i));
                  } while ((x = x.substring(i, x.length)) != "");

                  a = a.join("\n");
                  return a;
                }
                return "";
              },
              csvFormatter: (cell, row) => {
                if (row.conditions && row.conditions.length > 0) {
                  return row.conditions.toString();
                }
                return "";
              },
              sort: true,
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px'
              }
            },
            /*{
              dataField: "conditions",
              text: "Condition(s) Managed",
              headerFormatter: (column) => {
                return <div className="col-sm-8">{column.text}</div>;
              },
              formatter: (cell, row) => {
                if (row.conditions && row.conditions.length > 0) {
                  return (
                    <div className="col-sm-8">{row.conditions.toString()}</div>
                  );
                }
                return "";
              },
              csvFormatter: (cell, row) => {
                if (row.conditions && row.conditions.length > 0) {
                  return row.conditions.toString();
                }
                return "";
              },
              sort: true,
              classes: "col-sm-8"
            },*/
            {
              dataField: "mainCareProvider",
              text: "Main Care Provider",
              sort: true,
              formatter: (cell, row) => {
                return row.userObject.mainCareProvider
                  ? row.userObject.mainCareProvider.lname +
                      ", " +
                      row.userObject.mainCareProvider.fname
                  : "";
              },
              csvFormatter: (cell, row) => {
                return row.userObject.mainCareProvider
                  ? row.userObject.mainCareProvider.lname +
                      ", " +
                      row.userObject.mainCareProvider.fname
                  : "";
              },
              headerClasses: "ccmHeaderStyle",
            },

            {
              dataField: "organization",
              text: "Organization",
              sort: true,
              formatter: (cell, row) => {
                return row.userObject.organization;
              },
              csvFormatter: (cell, row) => {
                return row.userObject.organization;
              },
              style: {
                width: '10px',
              },
              headerClasses: "ccmHeaderStyle",
            },

            {
              dataField: "enrolledPrograms",
              text: "Enrolled Program(s)",
              sort: true,
              formatter: (cell, row) => {      
                return (
                  <div className="text-center">
                    <span style={{textAlign:"center"}}>{row.userObject.enrolledPrograms ? row.userObject.enrolledPrograms : "-"}</span>
                  </div>
                );
              },
              csvFormatter: (cell, row) => {
                return row.userObject.enrolledPrograms;
              },
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
              }
            },
            {
              dataField: "tesasdft",
              text: "Total CCM Time", //"Grand Total for CCM Sessions",
              sort: true,
              formatter: formatGrandCCM,
              csvFormatter: (cell, row) => {
                const dateFrom = moment(row.dateFrom).format("MMMM DD, YYYY");
                const addOneDay = moment(row.dateTo)
                  .add(1, "day")
                  .format("MMMM DD, YYYY");
                const filterSession = _.filter(row.trackTime, function (value) {
                  return moment(value.startTime).isBetween(dateFrom, addOneDay);
                });

                const ccm = _.filter(filterSession, { pmMode: "CCM MODE" });

                const getTimeOnlyCCM = _.map(ccm, "time");
                const totalSessionCCM = getTimeOnlyCCM.reduce(
                  (acc, time) => acc.add(moment.duration(time)),
                  moment.duration()
                );
                return formatDuration(totalSessionCCM);
              },
              formatExtraData: props,
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
                fontSize: '10px'
              }
            },
            {
              dataField: "test",
              text: "Total RPM Time", //"Grand Total for RPM Sessions",
              sort: true,
              formatter: formatGrand,
              csvFormatter: (cell, row) => {
                return totalRPM(row);
              },
              formatExtraData: props,
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px',
                fontSize: '10px'
              }
            },
            {
              dataField: "asdassd",
              text: "Billable CPT Code(s)", // RPM
              sort: true,
              formatter: billable,
              // csvFormatter: billableCSV,
              formatExtraData: props,
              style: {
                width: '10px'
              }
            },
            {
              dataField: "insuranceProvider",
              text: "Primary Insurance Provider",
              formatter: (cell, row) => {
                if (row.userObject.insuranceProvider) {
                  return <div style={{textAlign:"center"}}>{row.userObject.insuranceProvider}</div>;
                }
                return <div style={{textAlign:"center"}}> - </div>;
              },
              sort: true,
              formatExtraData: props,
              headerClasses: "ccmHeaderStyle"
            },
            {
              dataField: "ccmTrackings",
              text: "Called",
              sort: true,
              formatter: (cell, row, rowIndex, extra) => {
                const currentCcmTracking = getCurrentCCMTrackings(row);

                return (
                  <div className="text-center">
                    {currentCcmTracking?.length > 0 && (
                      <img src={callingIcon} />
                    )}
                  </div>
                );
              },
              formatExtraData: props,
              headerClasses: "ccmHeaderStyle",
              style: {
                width: '10px'
              }
            }
          ]}
        />
      </div>
    );
  }
}
export default CCMListClass;