import { useState, useEffect } from 'react';

const useResponsiveView = () => {
  const [view, setView] = useState('desktop'); // Default to mobile view

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) {
        setView('mobile'); // Mobile view: up to 768px
      } else if (width > 768 && width <= 1024) {
        setView('tablet'); // Tablet view: from 769px to 1024px
      } else {
        setView('desktop'); // Web view: larger than 1024px
      }
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);
    
    // Initial check
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return view;
};

export default useResponsiveView;
