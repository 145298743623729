
import React, { useState, useEffect, useContext } from "react";

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axiosClient from '../../axios/axios';

const useFetch2 = initialSettings => {
  
  const auth = useSelector(state => state.user.userDetails);
  const { role, region } = auth;
  // const role = auth.role;
  // const isClient = auth.region;

  const token = isEmpty(auth) ? null : auth.token;
  const careprovider = isEmpty(auth) ? "initial" : auth.isCareProvider;

  let path = "/";
  if (careprovider !== "initial") {
    if (role === "support" || role === "admin") {
      path = "/admin";
    } else if (region) {
      path = "/client";
    } else if (careprovider) {
      path = "/careprovider";
    } else if (!careprovider) {
      path = "/patient";
    }
  }

  const [response, setResponse] = useState(null);
  const [error, setError] = useState({ error: null, message: null });
  const [settings, setSettings] = useState(initialSettings);
  const [isLoading, setIsLoading] = useState(false);
  
  let settingsUrl = settings ? settings.url : "";
  // if(settingsUrl == undefined && settingsUrl === ""){
  //   return;
  // }
  
  let requestURL = path + settingsUrl;

  const axiosProvider = async ( settings, abortController ) => {
    const { method = "GET", body = {} } = settings; // Steup default Request GET
    let response = null;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": token
    }
    switch(method){
      case "GET" : 
        response = await axiosClient.get(requestURL, {
          headers: headers,
          signal: abortController.signal
        })
      break;

      case "POST" :
        response = await axiosClient.post(requestURL, body, {
          headers: headers,
          signal: abortController.signal
        })
      break;

      case "PUT" :
        response = await axiosClient.put(requestURL, body, {
          headers: headers,
          signal: abortController.signal
        })
      break;

      case "DELETE" : 
        response = await axiosClient.delete(requestURL, {
          headers: headers,
          signal: abortController.signal
        })
      break;
      default : response = null;
    }
    return response;
  }


  useEffect(() => {
    
    const abortController = new AbortController();
    let mounted = true;

    const fetchData = async () => {
      if (mounted) setIsLoading(true);
      try {
        //console.log('settings', settings,requestURL)

        // Encrypt Payload Request Via AxiosClient
        const response = await axiosProvider(settings, abortController);

        // console.log("This is useFetch2 Response:", response);

        // const res = await fetch(requestURL, {
        //   signal: abortController.signal,
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //     "x-auth-token": token
        //   },
        //   ...settings
        // });
        // const json = await res.json();
        
        if (response?.status == 200 && response?.data) {
          if (mounted) setResponse(response?.data);
          if (settings.method != "GET") {
            toast.success("Success");
            setIsLoading(false);
          }
        } else {
          toast.error("Action not successful");
          if (mounted) setError(response);
          if (mounted) setIsLoading(false);
        }
        if (mounted) setIsLoading(false);
      } catch (error) {
        if (error.name === "AbortError") {
          if (settings.disableWait) {
          } else {
            toast.warning("Please Wait");
          }
        } else {
          toast.error("Action not successful");
        }
        if (mounted) setError(error);
      }
    };
    if (settings) {
      fetchData();
    }
    const cleanup = () => {
      mounted = false;
      abortController.abort();
    };
    return cleanup;
  }, [settings]);
  return [{ response, error, isLoading }, setSettings, setError];
};

function formatDuration(duration) {
  function pad(number) {
    return strPad(number, 2);
  }

  let hours = (duration / 3600) | 0;
  let minutes = ((duration % 3600) / 60) | 0;
  let seconds = duration % 60;
  let minsSecs = `${pad(minutes)}:${pad(seconds)}`;

  return hours > 0 ? `${hours}:${minsSecs}` : "00:" + minsSecs;
}
function strPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
export { useFetch2, formatDuration };
