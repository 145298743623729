import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import Swal from 'sweetalert2';

const VideoCallNoteModal = ({
  isOpen,
  onClose,
  videoCallModel,
  careProvider,
  patient,
  dispatch
}) => {
  const [message, setComment] = useState("");
  const [canclePopup, setCanclePopup] = useState(false);
  const [subjectTitle, setNoteTitle] = useState("");
  const [errorComment, setErrorComment] = useState(true);

  /**
   * DEFAULT MESSAGE TITLE AND MESSAGE'S
   */
  const mList = [
    {
      name: "Other",
      message: "Other"
    },
  ];

  /**
   * INPUT CHANGE EVENT
   * @param {*} event EVENT DETAILS
   */
  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name == "subject") {
      const model = _.find(mList, model => model.name == value);
      if (model && model.message && model.message.length > 0) {
        setErrorComment(null);
        setComment(model.message)
        setNoteTitle(model.name)
      } else {
        setComment("")
        setNoteTitle("")
      }
    } else {
      setErrorComment(null);
      setComment(value)
    }
  }
  /**
   * SAVE PM SESSION NOTES
   */
  function submit() {
    console.log(subjectTitle);
    if (message.length > 0) {
      dispatch({
        type: "DO_SAVE_TIMER_VIDEO_CALL",
        payload: _.extend({}, videoCallModel, {
          subject: subjectTitle,
          message: message.trim()
        })
      });
      onClose();
      Swal.fire({
        title: "Saved!",
        text: "The virtual visit details have been successfully saved!",
        icon: "success"
      });      
    } else {
      setErrorComment("Please enter a message!");
    }
  }

  // CLOSE SESSION POPUP
  useEffect(() => {
    if (canclePopup) {
      onClose();
    }
  }, [canclePopup]);

  return (
    <Modal isOpen={isOpen} size="md" backdrop="static" data-backdrop="static">
      <ModalHeader charCode="">
        Add a note describing the virtual visit call.
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            className="input-date-filter remove-bottom-margin"
            type="select"
            name="subject"
            onChange={handleInputChange}
            id="exampleSelect">
            <option value="">Select Subject</option>
            {mList.map((value) => {
              return <option value={value.name}>{value.name}</option>;
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Message</Label>
          <Input
            className="input-date-filter remove-bottom-margin input-message-style"
            type="textarea"
            name="body"
            readOnly={false}
            value={message}
            onChange={handleInputChange}
            rows={10}
          />
          {errorComment && (
            <p style={{ color: "red" }}>{errorComment}</p>
          )}
        </FormGroup>
        <div className="div-row-center">
          {/* <Button
            className="close-click-cls modify-cancel-btn"
            color="white"
            onClick={cancelVideoSession} style={{padding:"15px 27px"}}>
            <i class="mdi mdi-close" style={{
              fontSize: 20
            }}></i>Cancel
          </Button>{" "} */}
          <Button
            color="info"
            className="save-click-cls"
            onClick={submit}
          >
            <i class="mdi mdi-content-save"></i>Save Session
          </Button>
        </div>
      </ModalBody>

    </Modal>
  );
};

export default VideoCallNoteModal;
