import React, { Component, useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";
import NewViolationList from "./NewViolationList";

const MainViolationsComponent = ({ isAdminPatient = false, isClientPatient = false, patientId }) => {
  const {
    myViolations,
    usersViolations,
    totalRecord,
    myViolationsTotalCount,
    ongoingUserViolationRequest,
  } = useSelector((state) => state.careprovider);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("");
  const [modal, setModal] = useState(false);
  const [activeCard, setActiveCard] = useState("Events Exceptions");
  const [searchQuery, setSearchQuery] = useState("");
  const [filterData, setFilterData] = useState({
    search: "",
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    ruleType: "",
    ruleTypes: "",
    readingType: "",
    limit: 10,
    page: 1,
    sortOrder: "",
    sortField: "",
  });
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;

  const debouncedSearch = useDebounce(searchQuery, 500);

  const handleTabClick = (tabId) => {
    if (activeTab !== tabId) {
      setActiveTab(tabId);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilterData((prevData) => ({
      ...prevData,
      ...newFilters,
    }));
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder }
  ) => {
    try {
      var sortNewOrder;
      const payload = {
        page: page,
        limit: sizePerPage,
      };
      if (sortField && !_.isEmpty(sortField)) {
        payload.sortField = sortField;
      }
      if (type === "sort") {
        if (filterData.sortOrder === "desc") {
          if (sortField && !_.isEmpty(sortField)) {
            sortNewOrder = "asc";
          } else {
            sortNewOrder = "desc";
          }
          sortNewOrder = "asc";
          payload.sortOrder = sortNewOrder;
          handleFilterChange(payload);
        } else {
          if (sortField && !_.isEmpty(sortField)) {
            sortNewOrder = "desc";
          } else {
            sortNewOrder = "asc";
          }
          sortNewOrder = "desc";
          payload.sortOrder = sortNewOrder;
          handleFilterChange(payload);
        }
      } else {
        handleFilterChange(payload);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const fetchExpectationCount = () => {
    try {
      let payload = {};
      if(isAdminPatient || isClientPatient){
        payload = { patientId }
      }
      dispatch({ type: "DO_GET_USER_VIOLATIONS_RECORD_COUNT", payload });
    } catch (error) {
      console.log("error:", error);
    }
  };

  const fetchViolationList = () => {
    try {
      let type = "";
      if (isCareProvider || isAdminPatient || isClientPatient) {
        type = "DO_GET_USER_VIOLATIONS"; // For CareProvider & Staff
      } else {
        type = "DO_GET_MY_VIOLATIONS";
      }
      let payload = filterData;
      // For Admin and Client Portal
      if((isAdminPatient || isClientPatient) && patientId){
          payload = { ...payload, patientId: patientId }
      }
      dispatch({ type, payload });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchExpectationCount();
    if(isCareProvider || isAdminPatient || isClientPatient){
      handleTabClick("2");
    }else{
      handleTabClick("1");
    }
  }, []);

  useEffect(() => {
    fetchViolationList();
  }, [filterData]);

  useEffect(() => {
    if (debouncedSearch !== "") {
      handleFilterChange({ search: searchQuery, page: 1 });
    }else{
      handleFilterChange({ search: "" });
    }
  }, [debouncedSearch]);

  console.log("usersViolations", usersViolations);

  return (
    <div>
      <div className="row">
        <Heading
          title={!isCareProvider ? "EXCEPTIONS" : "PATIENTS EXCEPTIONS"}
          description=""
          backbutton="d-none"
          mainbutton="d-none"
          toggleModal={() => setModal(!modal)}
        >
          <span className="page-title-icon bg-gradient-danger   text-white mr-2">
            <i className="mdi mdi-account-convert" />
          </span>
        </Heading>
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body project-tab pt-2">
              {
                (!isAdminPatient && !isClientPatient && !patientId) &&
                  <Nav tabs className="justify-content-center">
                  {!isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => handleTabClick("1")}
                      >
                        MY EXCEPTIONS
                      </NavLink>
                    </NavItem>
                  )}
                  {isCareProvider && (
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => handleTabClick("2")}
                      >
                        EVENTS/EXCEPTIONS
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              }
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {myViolations && (
                    <NewViolationList
                      handleFilterChange={handleFilterChange}
                      filterData={filterData}
                      usersViolations={myViolations}
                      userId={userId}
                      sizePerPage={filterData.limit}
                      page={filterData.page}
                      onTableChange={handleTableChange}
                      totalSize={myViolationsTotalCount}
                      isCareProvider={isCareProvider}
                      isAdminPatient={isAdminPatient}
                      activeCard={activeCard}
                      setActiveCard={setActiveCard}
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      loading={ongoingUserViolationRequest}
                    />
                  )}
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12" className="grid-margin">
                      {usersViolations && (
                        <NewViolationList
                          handleFilterChange={handleFilterChange}
                          filterData={filterData}
                          usersViolations={usersViolations}
                          userId={userId}
                          sizePerPage={filterData.limit}
                          page={filterData.page}
                          onTableChange={handleTableChange}
                          totalSize={totalRecord}
                          isCareProvider={isCareProvider}
                          isAdminPatient={isAdminPatient}
                          activeCard={activeCard}
                          setActiveCard={setActiveCard}
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          loading={ongoingUserViolationRequest}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainViolationsComponent;
