import React, { Component } from "react";
import { Line, Chart } from "react-chartjs-2";
import Heading from "./Heading";
import moment from "moment";

class LineGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image: ""
    };
  }

  printGraph(i) {
    const reference = this[`gwapoko${i}`].chartInstance.toBase64Image();

    var newTab = window.open();
    newTab.document.body.innerHTML = `<div><img src="${reference}" style="width: 100%;"></div>`;
    newTab.document.title = "Print Report";
    setTimeout(function () {
      newTab.print();
    }, 100);
    newTab.onfocus = function () {
      setTimeout(function () {
        newTab.close();
      }, 100);
    };
    // return reference.toDataUrl();
  }
  handleImageValue(event){
    //console.log(event);
  }

  componentDidMount() {
    if (this.props.value && !this.props.dontRender && this[`gwapoko${this.props.value}`] != undefined && this[`gwapoko${this.props.value}`].chartInstance != null)
      setTimeout(() => {
        const reference =
          this[`gwapoko${this.props.value}`].chartInstance.toBase64Image();
        this.setState({ image: reference });
      }, 500);
  }
  componentWillMount() {
    Chart.pluginService.register({
      beforeDraw: function (chartInstance) {
        var ctx = chartInstance.chart.ctx;
        ctx.fillStyle = "white";
        ctx.fillRect(
          0,
          0,
          chartInstance.chart.width,
          chartInstance.chart.height
        );
      }
    });
  }
  render() {
    const textArray = this.props.textArray || [];
    const isAxisText = this.props.yAxisTextLabel;
    const imageget = this.props.getImageValue;
    const height = this.props.height || 180;
    const isMobile = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    return (
      <div>
        {/* <div className="text-right">
          <a
            href={this.state.image}
            download
            className={`btn btn-info ${this.props.hidePrint}`}
          >
            <i className="mdi mdi-image" />
          </a>
          <a
            onClick={() => this.print(this.props.value)}
            className={`btn btn-success text-white ${this.props.hidePrint}`}
          >
            <i className="mdi mdi-printer" />
          </a>
        </div> */}
        {isMobile ?
          <Line
            ref={(el) => (this[`gwapoko${this.props.value}`] = el)}
            height={window.innerWidth < 768 ? 300 : 180} // Adjust height for mobile
            width={window.innerWidth < 768 ? 300 : 600}
            // height={180}
            data={{
              labels: this.props.readingDate,
              datasets: this.props.readingDataSet
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 10,
                  bottom: 10,
                },
              },
              // title: {
              //   display: true,
              //   text: this.props.title,
              //   fontSize: 25
              // },
              tooltips: {
                callbacks: {
                  title: function (tooltipItem) {
                    return moment(this._data.labels[tooltipItem[0].index]).tz(moment.tz.guess()).format(
                      "MMMM DD, YYYY hh:mm A"
                    );
                  },
                  footer: function (tooltipItem, data) {
                    return data.datasets[0].data[tooltipItem[0].index]
                      ? data.datasets[0].data[tooltipItem[0].index]
                        .eventDescription
                      : "";
                  }
                }
              },
              legend: {
                rowCount: 4,
                columnCount: 4,
                display: window.innerWidth > 768, // Show legend only on larger screens
                position: "top",
                align: "center",
                maxHeight: 100,
                maxWeight: 400,
                fullSize: true,
                labels: {
                  color: 'rgb(28, 28, 1)',
                  fontSize: 15,
                  boxHeight: 10,
                  usePointStyle: false,
                  generateLabels: function (chart) {
                    var labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                    for (var key in labels) {
                      labels[key].fillStyle = "#00000000";
                      labels[key].lineWidth = 2;
                    }
                    return labels;
                  }
                }
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      autoSkip: true,
                      maxTicksLimit: 5,
                      color: 'rgb(28, 28, 1)',
                      fontSize: window.innerWidth < 768 ? 10 : 15,
                      callback: function (label, index, labels) {
                        return moment(label).tz(moment.tz.guess()).format("MMM DD");
                      }
                    }
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                      color: 'rgb(28, 28, 1)',
                      fontSize: window.innerWidth < 768 ? 10 : 15,
                      beginAtZero: true,
                      callback: function (value) {
                        return isAxisText ? textArray[value] : value;
                      }
                    }
                  }
                ]
              }
            }}
          /> :
        <Line
          ref={(el) => (this[`gwapoko${this.props.value}`] = el)}
          height={height}
          data={{
            labels: this.props.readingDate,
            datasets: this.props.readingDataSet
          }}
          options={{
            // title: {
            //   display: true,
            //   text: this.props.title,
            //   fontSize: 25
            // },
            tooltips: {
              callbacks: {
                title: function (tooltipItem) {
                  return moment(this._data.labels[tooltipItem[0].index]).format(
                    "MMMM DD, YYYY hh:mm A"
                  );
                },
                footer: function (tooltipItem, data) {
                  return data.datasets[0].data[tooltipItem[0].index]
                    ? data.datasets[0].data[tooltipItem[0].index]
                        .eventDescription
                    : "";
                }
              }
            },
            legend: {
              rowCount: 4,
              columnCount: 4,
              display: true,               
              position : "top",
              align:"center",
              maxHeight : 100,
              maxWeight : 400,
              fullSize : true,
              labels: {
                color: 'rgb(28, 28, 1)',
                fontSize: 15,
                boxHeight: 10,
                usePointStyle : false,
                generateLabels: function(chart) {
                  var labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                  for (var key in labels) {                    
                    labels[key].fillStyle  = "#00000000";     
                    labels[key].lineWidth  = 2;
                  }
                  return labels;
                }
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    color: 'rgb(28, 28, 1)',
                fontSize: 15,
                    callback: function (label, index, labels) {
                      return moment(label).format("MMM DD");
                    }
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    color: 'rgb(28, 28, 1)',
                    fontSize: 15,
                    beginAtZero: true,
                    callback: function (value) {
                      return isAxisText ? textArray[value] : value;
                    }
                  }
                }
              ]
            }
          }}
        />
        }
      </div>
    );
  }
}

export default LineGraph;
