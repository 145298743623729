import {
  SUBMIT_OFFER_FAIL,
  SUBMIT_OFFER_SUCCESS,
  REQUEST_ACCESS_SUCCESS,
  REQUEST_ACCESS_FAIL,
  GET_USERS_YOU_SHARED_SUCCESS,
  GET_ALL_DEVICE_LIST,
  GET_ALL_DEVICE_LIST_FOR_ON_BOARDING,
  GET_USERS_YOU_HAVE_ACCESS_SUCCESS,
  GET_All_PATIENT_OFFERED_ACCESS_SUCCESS,
  GET_All_ON_BORADING_DATA_SUCCESS,
  GET_All_ON_BORADING_CCM_DATA_SUCCESS,
  GET_All_FAX_REPORT_DATA_SUCCESS,
  GET_ASSIGNED_CAREPROVIDERS_SUCCESS,
  GET_USERS_LIST_SUCCESS,
  GET_CURRENT_MONTH_REPORT_SUCCESS,
  GET_REMINDER_PATIENT_LIST_SUCCESS,
  GET_REMINDER_LIST_SUCCESS,
  GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS,
  GET_MY_RULES_SUCCESS,
  GET_CAREPROVIDER_EXPECTATIONS_SUCCESS,
  GET_RULES_SET_BY_OTHERS_SUCCESS,
  GET_MY_QUESTIONNAIRES,
  GET_MY_QUESTIONNAIRES_NAME_REPETED,
  GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS,
  GET_MY_VIOLATIONS,
  GET_USER_VIOLATIONS,
  GET_VIDEO_TOKEN,
  TIMER_STARTS,
  GET_PM_RECORD,
  GET_PM_RECORD_NEW,
  GET_INBOX,
  GET_USER_READINGS,
  GET_PATIEN_EXPECTATION_CARE_PLAN,
  GET_STAFF_LIST,
  GET_SHARED_ACCOUNT_LIST,
  LOGOUT_USER,
  GET_USER_NOTES,
  GET_ON_BOARDING_STATUS,
  DO_GET_USER_PMSESSION_TIME,
  GET_USER_PMSESSION_TIME,
  SAVE_POPULATION,
  REMOVE_POPULATION,
  SAVE_POPULATION_EXCEPTION,
  REMOVE_POPULATION_EXCEPTION,
  ONGOING_REQUEST,
  ONGOING_BOTH_READING_REQUEST,
  GET_CONDITIONS,
  GET_PATIENT_CONDITIONS,
  GET_PATIENT_CCM_ANSWERS,
  RESET_CCM,
  GET_DEXCOMDATA,
  GET_BODYTRACE,
  GET_DEXCOM_GLUCOSE_DATA,
  GET_CAREPROVIDERS_LIST_SUCCESS,
  GET_CAREPROVIDER_STAFF,
  GET_AUTENTICATE_FAX_PLUS,
  GET_All_PATIENT_COUNT_SUCCESS,
  GET_EXPORT_ALL_PATIENT_REPORT,
  GET_EXPORT_ALL_ONBOARING_REPORT,
  GET_EXPORT_ALL_ONBOARING_CCM_REPORT,
  GET_EXPORT_CCM_RECODES,
  GET_PRINT_CCM_RECODES,
  GET_REMOVE_PATIENT_PATH,
  GET_REMOVE_CCM_PATH,
  GET_COUNT_ON_BORADING_DATA_SUCCESS,
  GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS,
  GET_REMOVE_READING_PATH,
  GET_EXPORT_READING_RECODES,
  ONGOING_PATIENT_LIST_REQUEST,
  ONGOING_CHAT_MESSAGE_LOAD_REQUEST,
  ONGOING_SHARED_ACCOUNT_LIST_REQUEST,
  ONGOING_NOTE_LIST_REQUEST,
  ONGOING_THIRTY_DAYS_REQUEST,
  ONGOING_SUBMISSION_REQUEST,
  ONGOING_USER_VIOLATION_REQUEST,
  ONGOING_PATIENT_PROFILE_REQUEST,
  ONGOING_PDF_EXCEL_READING_REQUEST,
  GET_PATIENT_LIST,
  GET_ADMIN_LIST,
  GET_CAREPROVIDER_LIST,
  GET_CLIENT_LIST,
  GET_UNREAD_INBOX_COUNT,
  ONGOING_REQUEST_DEVICE,
  ONGOING_INBOX_REQUEST,
  ONGOING_TESTING_FREQUENCY_REQUEST,
  ONGOING_PM_SESSION_REQUEST,
  ONGOING_BOARDING_REQUEST,
  GET_THIRTY_DAY_SUMMARY,
  GET_PATIENT_SUBMISSION_DETAILS,
  GET_PATIENT_SUBMISSION_DETAILS_ERROR,
  GET_PATIENT_FREQUENCY,
  GET_DEFAULT_PROFILE_CONFIGRATION,
  ONGOING_CAREPROVIDER_STAFF_REQUEST,
  GET_CREATE_PATIENT_PERMISSION,
  GET_DEVICES,
  ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION,
  ONGOING_REQUEST_QUESTIONNAIRE,
  ONGOING_REQUEST_CARE_PLAN,
  GET_CCM_SUBMISSION,
  GET_NOTIFICATION_LOGS_SUCCESS,
  GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS,
  GET_NOTIFICATION_LOGS_PATH,
  ONGOING_NOTIFICATION_LOGS_REQUEST,
  ONGOING_SAVE_PROFILE_REQUEST,
  SUBMIT_PROFILE_CONFIGRATION,
  GET_EXPORT_PATIENT_POPULATE_REPORT,
  GET_REMOVE_POPULATION_FILE_PATH,
  ONGOING_POPULATION_REQUEST,
  ONGOING_POPULATION_DATA_REQUEST,
  ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST,
  GET_All_CHAT_MESSAGES_SUCCESS,
  ADD_CHAT_MESSAGE,
  ONGOING_PATIENT_LIST_REQUEST_EXPORT,
  GET_VERSION_REPORTS_SUCCESS,
  GET_VERSION_REPORTS_EXPORT_SUCCESS,
  GET_VERSION_REPORTS_LOGS_PATH,
  ONGOING_VERSION_REPORT_EXPORT_REQUEST,
  GET_APP_VERSION_REPORTS,
  ONGOING_USER_VIOLATION_RECORD_COUNT_REQUEST,
  GET_USER_VIOLATIONS_RECORD_COUNT,
  ONGOING_MY_VIOLATION_REQUEST,
  GET_ADDITIONAL_CAREPROVIDER_LIST,
  ONGOING_ASSIGNED_USER_REQUEST,
  ONGOING_ASSIGNED_CAREPROVIDER_REQUEST,
  GET_ALL_STAFF_PATIENT_LIST,
  GET_EXPORT_ALL_SHARED_ACCOUNT,
  ONGOING_REQUEST_ASSIGNED_USERS_LIST,
  ONGOING_PM_SESSION__EXPORT_REQUEST,
  ONGOING_PM_SESSION_PRINT_REQUEST,
  ONGOING_ON_BOARDING_REQUEST_EXPORT,
  ONGOING_NOTIFICATION_MESSAGES_LIST_EXPORT_REQUEST
} from "../actions/types";
import subType from "./readingSubType.json";
import subTypeForExpectation from "./readingSubTypeForExpectation.json";

import readingType from "./readingType.json";
const INITIAL_STATE = {
  submitOfferFail: false,
  requestAccessFail: false,
  usersGivenAccessMyReading: [],
  usersOfferedAccess: [],
  usersOfferedAccessOrganization: [],
  genericErrorMessage: "",
  isGenericError: false,
  isGenericSuccess: false,
  readingSubTypes: subType,
  readingSubTypesForExpecation: subTypeForExpectation,
  readingType: readingType,
  myViolations: "",
  usersViolations: "",
  videoToken: "",
  timerStarts: false,
  createPatientPermission: false,
  pmRecords: "",
  inbox: "",
  readings: "",
  notes: "",
  staff: "",
  patient:"",
  admin: "",
  unReadCount: "",
  readingFrequency: [],
  totalReadingFrequency: false,
  population: {
    filteredConditionReadings: "",
    totalFilteredCondition: "",
    totalReadingPerType: ""
  },
  dexcomRaw: [],
  populationException: "",
  ongoingRequest: false,
  ongoingbothReadingRequest: false,
  conditions: null,
  devices: "",
  populationFilePath: undefined,
  ongoingAssignedUserRequest: false,
  ongoingAssignedCareproviderRequest: false,
};

const RESET_STATE = {
  submitOfferFail: false,
  requestAccessFail: false,
  usersGivenAccessMyReading: [],
  usersOfferedAccess: [],
  usersOfferedAccessOrganization: [],
  readingFrequency: [],
  totalReadingFrequency: false,
  genericErrorMessage: "",
  isGenericError: false,
  isGenericSuccess: false,
  readingSubTypes: subType,
  readingSubTypesForExpecation: subTypeForExpectation,
  readingType: readingType,
  myViolations: "",
  usersViolations: "",
  videoToken: "",
  timerStarts: false,
  createPatientPermission: false,
  pmRecords: "",
  inbox: "",
  readings: "",
  notes: "",
  staff: "",
  patient:"",
  admin: "",
  unReadCount:"",
  population: undefined,
  dexcomRaw: [],
  populationException: "",
  ongoingRequest: false,
  ongoingInboxRequest: false,
  ongoingTestingFrequencyRequest: false,
  ongoingCareproviderStaffRequest: false,
  ongoingbothReadingRequest: false,
  ongoingPmSessionRequest: false,
  conditions: null,
  devices: "",
  populationFilePath: undefined,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        ...RESET_STATE
      };
    case SUBMIT_OFFER_SUCCESS:
      return {
        ...state,
        isGenericError: false,
        isGenericSuccess: action.payload.isGenericSuccess,
        genericErrorMessage: ""
      };
    case ONGOING_REQUEST:
      return {
        ...state,
        ongoingRequest: action.payload
      };
    case ONGOING_REQUEST_FOR_CCM_QUESTION_SECTION:
      return {
        ...state,
        ongoingRequestCCMQuestions: action.payload
      };
    case ONGOING_REQUEST_QUESTIONNAIRE:
      return {
        ...state,
        ongoingRequestQuesionnaire: action.payload
      };
    case ONGOING_REQUEST_CARE_PLAN:
      return {
        ...state,
        ongoingRequestCarePlan: action.payload
      };      
    case ONGOING_PM_SESSION_REQUEST:
      return {
        ...state,
        ongoingPmSessionRequest: action.payload
      };
    case ONGOING_PM_SESSION__EXPORT_REQUEST :
    return {
      ...state,
      ongoingPmSessionExportRequest: action.payload
    };
    case ONGOING_PM_SESSION_PRINT_REQUEST : 
    return {
      ...state,
      ongoingPmSessionPrintRequest: action.payload
    }
    case ONGOING_ON_BOARDING_REQUEST_EXPORT : 
    return {
      ...state,
      ongoingOnBoardingExportRequest: action.payload
    }
    case ONGOING_NOTIFICATION_MESSAGES_LIST_EXPORT_REQUEST : 
    return {
      ...state,
      ongoingNotificationAndMessagesExportRequest: action.payload
    }
    case ONGOING_BOARDING_REQUEST:
      return {
        ...state,
        ongoingOnBoardingRequest: action.payload
      };
    case ONGOING_REQUEST_DEVICE:
      return {
        ...state,
        ongoingRequestDevice: action.payload
      };
  case ONGOING_BOTH_READING_REQUEST:
      return {
        ...state,
        ongoingbothReadingRequest: action.payload
      };
    case ONGOING_MY_VIOLATION_REQUEST: 
      return {
        ...state,
        ongoingMyViolationRequest: action.payload
      };
    case ONGOING_USER_VIOLATION_REQUEST:
      return {
        ...state,
        ongoingUserViolationRequest: action.payload
      };
    case ONGOING_USER_VIOLATION_RECORD_COUNT_REQUEST: 
      return {
        ...state,
        ongoingUserViolationRecordCountRequest: action.payload
      }
    case ONGOING_INBOX_REQUEST:
      return {
        ...state,
        ongoingInboxRequest: action.payload
      }
    case ONGOING_TESTING_FREQUENCY_REQUEST:
        return {
          ...state,
          ongoingTestingFrequencyRequest: action.payload
    }
    case ONGOING_SAVE_PROFILE_REQUEST:
      return {
        ...state,
        ongoingSaveProfileConfigration: action.payload,        
      }    
    case ONGOING_CAREPROVIDER_STAFF_REQUEST:
      return {
        ...state,
        ongoingCareproviderStaffRequest: action.payload
      }
    case ONGOING_THIRTY_DAYS_REQUEST: 
      return{
        ...state,
        ongoingThirtyDaysRequest: action.payload
      };
    case ONGOING_SUBMISSION_REQUEST: 
      return{
        ...state,
        ongoingSubmissionRequest: action.payload
      };      
    case ONGOING_PDF_EXCEL_READING_REQUEST:
      return {
        ...state,
        ongoingPdfExcelRequest: action.payload
      };
    case ONGOING_PATIENT_PROFILE_REQUEST:
      return {
        ...state,
        ongoingPatientProfileRequest: action.payload
      };
    case ONGOING_CARE_PROVIDER_THRESHOLD_REQUEST:
      return {
        ...state,
        ongoingGlobalThresholdRequest: action.payload
      };
      
    case SUBMIT_OFFER_FAIL:
      return {
        ...state,
        isGenericError: true,
        isGenericSuccess: false,
        genericErrorMessage: action.payload
      };
    case GET_DEVICES:
        return {
          ...state,
          devices: action.payload.data,
        };
    case GET_CCM_SUBMISSION:
        return {
          ...state,
          latestSubmission: action.payload.data && action.payload.data.latestSubmission ? action.payload.data.latestSubmission : [],
          ccmSubmissinLogs: action.payload.data && action.payload.data.ccmSubmissinLogs ? action.payload.data.ccmSubmissinLogs : []
        };
    case REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        isGenericError: false,
        isGenericSuccess: true,
        genericErrorMessage: ""
      };
    case REQUEST_ACCESS_FAIL:
      return {
        ...state,
        isGenericError: true,
        isGenericSuccess: false,
        genericErrorMessage: action.payload
      };
    case GET_USERS_YOU_SHARED_SUCCESS:
      return {
        ...state,
        usersGivenAccessMyReading: action.payload.data
      };
    case SAVE_POPULATION:
      
      return {
        ...state,
        population: action.payload.data
      };
    case REMOVE_POPULATION:
      
      return {
        ...state,
        population: undefined
      };
    case SAVE_POPULATION_EXCEPTION:
      
      return {
        ...state,
        populationException: action.payload.data
      };
    case REMOVE_POPULATION_EXCEPTION:
      return {
        ...state,
        populationException: undefined
      };
    case GET_USERS_YOU_HAVE_ACCESS_SUCCESS:
      return {
        ...state,
        usersOfferedAccess: action.payload.data
      };
    case GET_ALL_DEVICE_LIST:
      return {
        ...state,
        mDeviceList: action.payload
      };
    case GET_ALL_DEVICE_LIST_FOR_ON_BOARDING:
        return {
          ...state,
          mDeviceListOnBoarding: action.payload ? action.payload.data : [],
          isNotApplicable: action.payload && action.payload.isNotApplicable ? action.payload.isNotApplicable : false,
          onBoarding: action.payload && action.payload.onBoarding ? action.payload.onBoarding : undefined,
          displayLabel: action.payload && action.payload.displayLabel ? action.payload.displayLabel : undefined
        };            
    case GET_All_PATIENT_OFFERED_ACCESS_SUCCESS:
      return {
        ...state,
        allPatientOfferedAccess: action.payload ? action.payload.data : [],
        totalRecode: action.payload ? action.payload.totalRecode : 0
      };
    case GET_All_ON_BORADING_DATA_SUCCESS:
      return {
        ...state,
        allOnBoradingAccess: action.payload.data,
        allCategorys: action.payload.allCategorys,
        totalRecode: action.payload.totalRecode,
        allBoardingDevice: action.payload.allBoardingDevice
      };
    case GET_All_ON_BORADING_CCM_DATA_SUCCESS:
      return {
        ...state,
        allOnBoradingCCMAccess: action.payload.data,
        allCategorys: action.payload.allCategorys,
        totalRecode: action.payload.totalRecode
      };
    case GET_All_FAX_REPORT_DATA_SUCCESS:
      return {
        ...state,
        allFaxReport: action.payload.data,
        allCategorys: action.payload.allCategorys,
        totalRecode: action.payload.totalRecode
      };
    case GET_All_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        allChatMessages: action.payload.data
      };
    case ADD_CHAT_MESSAGE:
      return {
        ...state,
        allChatMessages: [...state.allChatMessages, action.payload],
      };
    case GET_COUNT_ON_BORADING_DATA_SUCCESS:
      return {
        ...state,
        onBoardingDataCount: action.payload.data,
      };
    case GET_COUNT_ON_BORADING_CCM_DATA_SUCCESS:
      return {
        ...state,
        onBoardingCCM: action.payload.data,
      };
    case GET_All_PATIENT_COUNT_SUCCESS:
      return {
        ...state,
        allPatientCount: action.payload ? action.payload.data : undefined      
      };
    case GET_EXPORT_ALL_PATIENT_REPORT:
      return {
        ...state,
        patientFilePath: action.payload ? action.payload.data : undefined        
      };
    case GET_EXPORT_ALL_ONBOARING_REPORT:
      return {
        ...state,
        patientFilePath: action.payload ? action.payload.data : undefined        
      };
    case GET_EXPORT_ALL_ONBOARING_CCM_REPORT:
      return {
        ...state,
        patientFilePath: action.payload ? action.payload.data : undefined        
      };
    case GET_EXPORT_CCM_RECODES:
      return {
        ...state,
        ccmFilePath: action.payload ? action.payload.data : undefined, 
        ccmPrintData: undefined,   
      }; 
    case GET_PRINT_CCM_RECODES:
      return {
        ...state,
        ccmPrintData: action.payload ? action.payload.data : undefined,        
      };                  
    case GET_REMOVE_PATIENT_PATH:
      return {
        ...state,
        patientFilePath: undefined
      };
      case GET_NOTIFICATION_LOGS_PATH:
        return {
          ...state,
          notificationLogsFilePath: undefined
        };
        case GET_VERSION_REPORTS_LOGS_PATH:
        return {
          ...state,
          versionReportsLogsFilePath: undefined
        };               
    case GET_REMOVE_CCM_PATH:
      return {
        ...state,
        ccmPrintData: undefined,
        ccmFilePath: undefined
      };      
    case GET_REMOVE_READING_PATH:
      return {
        ...state,
        readingFilePath: undefined
      };   
    case GET_EXPORT_READING_RECODES:
      return {
        ...state,
        readingFilePath: action.payload.data
      } 
    case GET_ASSIGNED_CAREPROVIDERS_SUCCESS:
      return {
        ...state,
        myAssignedCareProviders: action.payload.data
      };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        assignedUsersList: action.payload.data
      };
    case GET_CURRENT_MONTH_REPORT_SUCCESS:
      return {
        ...state,
        currentMonthReport: action.payload.data
      };
    case GET_REMINDER_PATIENT_LIST_SUCCESS:
      return {
        ...state,
        reminderPatientList: action.payload.data
      };
    case GET_REMINDER_LIST_SUCCESS:
      return {
        ...state,
        patientReminderList: action.payload.data
      };
      case GET_NOTIFICATION_LOGS_SUCCESS:
        return {
          ...state,
          notificationLogs: action.payload.data
        };
        case GET_NOTIFICATION_LOGS_EXPORT_CSV_SUCCESS:
          return {
            ...state,
            notificationLogsFilePath: action.payload.data
          };
    case GET_VERSION_REPORTS_SUCCESS:
      return {
        ...state,
        versionReports: action.payload
      };
    case GET_VERSION_REPORTS_EXPORT_SUCCESS:
      return {
        ...state,
        versionReportsLogsFilePath: action.payload.data
      };
    case GET_USERS_READING_UNDER_YOUR_ORGANIZATION_SUCCESS:
      return {
        ...state,
        usersOfferedAccessOrganization: action.payload.data
      };
    case GET_MY_RULES_SUCCESS:
      return {
        ...state,
        myRules: action.payload.data
      };
    case GET_CAREPROVIDER_EXPECTATIONS_SUCCESS:
      return {
        ...state,
        careProviderExpectations: action.payload.data
      };
    case GET_RULES_SET_BY_OTHERS_SUCCESS:
      return {
        ...state,
        rulesSetByOthers: action.payload.data
      };
    case GET_MY_VIOLATIONS:
      return {
        ...state,
        myViolations: action.payload.data,
        myViolationsTotalCount: action.payload ? action.payload.totalRecord : 0
      };
    case GET_MY_QUESTIONNAIRES:
      return {
        ...state,
        myQuestionnaire: action.payload.data,
        questionnarieUserDetails: action.payload.userDetails
      };
    case GET_MY_QUESTIONNAIRES_NAME_REPETED:
      return {
        ...state,
        questionnaireName: action.payload.data.questionnaireName,
        questionnaireRepeted: action.payload.data.questionnaireRepeted
      };
    case GET_MY_QUESTIONNAIRES_CARE_PROVIDER_SUCCESS:
      return {
        ...state,
        questionnaireRule: action.payload.data
      };
    case GET_USER_VIOLATIONS:
      return {
        ...state,
        usersViolations: action.payload.data,
        totalRecord: action.payload ? action.payload.totalRecord : 0
      };
    case GET_USER_VIOLATIONS_RECORD_COUNT: 
      return {
        ...state,
        usersViolationsRecordsCount: action.payload.data,
      };
    case GET_VIDEO_TOKEN:
      return {
        ...state,
        videoToken: action.payload.data
      };
    case GET_STAFF_LIST:
      return {
        ...state,
        staff: action.payload.data
      };
    case GET_SHARED_ACCOUNT_LIST:
      return {
        ...state,
        sharedAccount: action.payload.data,
        totalRecode: action.payload ? action.payload.totalRecode : 0
      };
    case GET_PATIENT_LIST:
      return {
        ...state,
        patient: action.payload.data
      };
    case GET_ADMIN_LIST:
      return {
        ...state,
        admin: action.payload.data
      };
    case GET_CAREPROVIDER_LIST:
      return {
        ...state,
        careProviderAll: action.payload.data
      };
    case GET_CLIENT_LIST:
      return {
        ...state,
        clientAll: action.payload.data
      };
    case TIMER_STARTS:
      return {
        ...state,
        timerStarts: action.payload
      };
    case GET_CREATE_PATIENT_PERMISSION:
        return {
          ...state,
          createPatientPermission: action.payload.data
        };
    case GET_PM_RECORD:
      return {
        ...state,
        pmRecords: action.payload.data
      };
    case GET_PM_RECORD_NEW:
      return {
        ...state,
        pmRecordsNew: action.payload.data,
        totalRecode:action.payload.totalRecode,
        ccmPrintData: undefined,
      };
    case RESET_CCM:
      return {
        ...state,
        pmRecords: ""
      };

    case GET_INBOX:
      return {
        ...state,
        inbox: action.payload.inboxMessages,
        totalRecode:action.payload.totalRecode,
        totalAllRecode: action.payload.totalAllRecode,
        unReadCount: action.payload.unReadCount,
      };
    case GET_UNREAD_INBOX_COUNT : 
      return {
        ...state,
        unReadCount: action.payload.unReadCount,
      };
    case GET_USER_READINGS:
      if (action.payload.data) {
        return {
          ...state,
          readingType: readingType,
          readings: action.payload.data,
          isBloodPressureReading : action.payload && action.payload.isBloodPressureReading ? action.payload.isBloodPressureReading : false
        };
      } else {
        return {
          ...state,
          readingType: readingType,
          readings: action.payload
        };
      }
    case GET_PATIEN_EXPECTATION_CARE_PLAN:
      return {
        ...state,
        patientCarePlanExpectations: action.payload.data,
        patientWeightExpectations: action.payload.weightExpectations
      };
    case GET_DEXCOMDATA:
      return {
        ...state,
        dexcomRaw: action.payload.data
      };
    case GET_BODYTRACE:
      return {
        ...state,
        bodytraceReadings: action.payload.data
      };
    case GET_DEXCOM_GLUCOSE_DATA:
      return {
        ...state,
        dexcomGlucoseData: action.payload.data
      };
    case GET_USER_NOTES:
      return {
        ...state,
        notes: action.payload.data,
        notesPatientDetails: action.payload.userDetails,
        //rpmTotalDuration: action.payload.rpmTotal,
        //ccmTotalDuration: action.payload.ccmTotal,
        latestReadingUser: action.payload.latestReading
      };
    case GET_ON_BOARDING_STATUS:
      return {
        ...state,
        onBoardingStatus: action.payload.data        
      };
    case GET_USER_PMSESSION_TIME:
      return {
        ...state,        
        rpmTotalDuration: action.payload.rpmTotal,
        ccmTotalDuration: action.payload.ccmTotal        
      };      
    case GET_CONDITIONS:
      return {
        ...state,
        conditions: action.payload.data,
        patients: action.payload.patients
      };
    case GET_PATIENT_CONDITIONS:
      return {
        ...state,
        patientConditions: action.payload.data,
        patientExpectations: action.payload.rules,
        questions: action.payload.questions
      };
    case GET_PATIENT_CCM_ANSWERS:
      return {
        ...state,
        ccmAnswers: action.payload.data.pastCCMAnswers,
        latestCCMAnswers: action.payload.data.latestCCMAnswers,
      };
    case GET_CAREPROVIDERS_LIST_SUCCESS:
      return {
        ...state,
        careProviderList: action.payload.data
      };
    case GET_CAREPROVIDER_STAFF:
      return {
        ...state,
        careProviderStaff: action.payload.data
      };
    case GET_AUTENTICATE_FAX_PLUS:
      return {
        ...state,
        authenticationToken: action.payload.data
      };
      case ONGOING_PATIENT_LIST_REQUEST:
        return {
          ...state,
          ongoingPatientListRequest: action.payload
      };
      case ONGOING_PATIENT_LIST_REQUEST_EXPORT:
        return {
          ...state,
          ongoingPatientListRequestExport: action.payload
      };
      case ONGOING_CHAT_MESSAGE_LOAD_REQUEST:
        return {
          ...state,
          isLoadingChatList: action.payload
      };
      case ONGOING_SHARED_ACCOUNT_LIST_REQUEST:
        return {
          ...state,
          ongoingSharedAccountListRequest: action.payload
      };
      case ONGOING_NOTE_LIST_REQUEST:
        return {
          ...state,
          ongoingNoteListRequest: action.payload
      };
      case ONGOING_NOTIFICATION_LOGS_REQUEST:
        return {
          ...state,
          ongoingNotificationLogsRequest: action.payload
      };
      case ONGOING_REQUEST_ASSIGNED_USERS_LIST:
        return {
          ...state,
          ongoingAssignedUsersListsRequest: action.payload
      };
      case ONGOING_VERSION_REPORT_EXPORT_REQUEST:
        return {
          ...state,
          ongoingVersionReportExportLogsRequest: action.payload
      };
      case GET_APP_VERSION_REPORTS:
        return {
          ...state,
          appVersions: action.payload.data
      };
      case GET_THIRTY_DAY_SUMMARY:
        return {
          ...state,
          nonAdhenrenceDay: action.payload.nonAdhenrenceDay,
          readingsThirtyDays: action.payload.readingsThirtyDays ? action.payload.readingsThirtyDays : [],
          bodyTraceReadings: action.payload.bodyTraceReadings ? action.payload.bodyTraceReadings : [],
          isBloodPressureReadingThirtyDaysSummary: action.payload && action.payload.isBloodPressureReading ? action.payload.isBloodPressureReading : false
      };
      case GET_PATIENT_SUBMISSION_DETAILS:
        return {
          ...state,
          ccmSubmission: action.payload.ccmSubmission
      };
      case GET_PATIENT_SUBMISSION_DETAILS_ERROR:
        return {
          ...state,
          submissionErrorTitle: action.payload.errorTitle,
          submissionError: action.payload.message
      };
      
      
      case GET_PATIENT_FREQUENCY:
        return{
          ...state,
          readingFrequency: action.payload.data,
          totalReadingFrequency: action.payload.totalFilledFrequency ?? false, 
        }
      case GET_DEFAULT_PROFILE_CONFIGRATION:
        return{
          ...state,
          defaultProfileConfigration: action.payload.data,
          isSentMonthlyReadingReport: action.payload.isSentMonthlyReadingReport,
          notifyReadingMonthlyReport: action.payload.notifyReadingMonthlyReport ? action.payload.notifyReadingMonthlyReport : undefined,
        }
      case GET_EXPORT_PATIENT_POPULATE_REPORT:
        return {
          ...state,
          populationFilePath: action.payload ? action.payload.data : undefined,
        }
      case GET_REMOVE_POPULATION_FILE_PATH:
        return {
          ...state,
          populationFilePath: undefined,
        }
        case ONGOING_POPULATION_REQUEST:
          return {
            ...state,
            ongoingPopulationRequest: action.payload
          };
          case ONGOING_POPULATION_DATA_REQUEST:
            return {
              ...state,
              ongoingPopulationDataRequest: action.payload
            };
            case ONGOING_ASSIGNED_USER_REQUEST:
              return {
                ...state,
                ongoingAssignedUserRequest: action.payload
              };
          case ONGOING_ASSIGNED_CAREPROVIDER_REQUEST:
            return {
              ...state,
              ongoingAssignedCareproviderRequest: action.payload
            };
          case GET_EXPORT_ALL_SHARED_ACCOUNT: 
            return {
              ...state,
              patientFilePath: action.payload ? action.payload.data : undefined        
            };
          case GET_ADDITIONAL_CAREPROVIDER_LIST: 
          return {
            ...state,
            additionalCareproviderList: action.payload.data,
            userAssignedCareprovider: action.payload.userAssignedCareprovider
          }
          case GET_ALL_STAFF_PATIENT_LIST:
            return {
              ...state,
              allStaffPatientList: action.payload.data
            };
    default:
      return state;
  }
};
