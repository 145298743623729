import React, { useRef } from "react";
import {
  Row, Col, Modal,
  ModalBody,
  Label,
  ModalHeader,
} from "reactstrap";
import ToolkitProvider, { Search }  from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import html2canvas from "html2canvas";
import EmailQuestionnaire from "./emailQuestionnaire";
import { toast } from "react-toastify";
import QuestionnariePrintReport from "../UserDashboard/QuestionnariePrintReport";
import ReactToPrint from "react-to-print";
import LottieLoader from "../LottieLoader";
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';

const _ = require("lodash");
const MySearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  return (
    <div>
      <div class="form-group">
        <input
          type="text"
          class="form-control input-date-filter remove-bottom-margin"          
          id="exampleInputName1Test"
          placeholder="Search by Date, Questionnaire, Frequency...."
          ref={(n) => (input = n)}
          onChange={handleClick}
        />
      </div>
    </div>
  );
};


const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div
      className="text-center">
      <button className="btn btn-success float-left export-csv" onClick={handleClick}>
        <i className="mdi mdi-file-excel" /> Export to CSV
      </button>
    </div>
  );
};

const answerStatus = (cell, row, rowIndex, extra) => {
  const finalGrandRPM = row.isCurrectAnswer;
  return (
    <div className="text-center">
      {finalGrandRPM && (
         <button
         className="btn btn-info correct-click-cls "
         onClick={() => {
           //extra.openInterventionReport(row.trackTime[0]);
         }}>
         Correct
       </button>
      )}

      {!finalGrandRPM && (
          <button
          className="btn btn-info wrong-click-cls"
          onClick={() => {
            //extra.openInterventionReport(row.trackTime[0]);
          }}>
          Incorrect
        </button>
        )}
    </div>
  );
};

const sessionStatus = (isSessionCompleted) => {  
  return (
    <div className="text-center">
      {!isSessionCompleted && (
         <button
         className="btn btn-info correct-session-click-cls "
         onClick={() => {
           //extra.openInterventionReport(row.trackTime[0]);
         }}>
         Running
       </button>
      )}

      {isSessionCompleted && (
          <button
          className="btn btn-info wrong-session-click-cls"
          onClick={() => {
            //extra.openInterventionReport(row.trackTime[0]);
          }}>
          Expired
        </button>
        )}
    </div>
  );
};

/*
 * Main class list is used for table UI, CSV UI (print is located in CCMListClass.js)
 */
const QuestionnaireList = (props, context) => {

  const [showAnswerModal, setShowAnswerModal] = React.useState(false);
  const [showQuestionModal, setShowQuestionModal] = React.useState(false);
  const [answerModel, setAnswerModel] = React.useState(Object)
  const [questionModel, setQuestionModel] = React.useState(Object)
  const [questionName, setQuestionName] = React.useState(Object)
  const [showEmail, setShowEmail] = React.useState(false);  
  const [name, setName] = React.useState(false);  
  const [questionnaireImage, setQuestionnaireImage] = React.useState(false);  
  const { email, password } = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const componentRef = useRef();
  //console.log("LOADING >>>>> " + props.loading);
  return (
    <div className="scrollbar-table-cls">
    <Row className="col-sm-12 main-cls-modal">
      <Col sm="12" className="mt-12">
        {props.myQuestionnaire && (
          <ToolkitProvider
          bootstrap4
          keyField="_id"          
          data={props.loading ? [] : props.myQuestionnaire}
          search={ {
            searchFormatted: true
          }}
          columns={[
            {
              dataField: "createdAt",
              text: "Date",
              sort: true,
              searchable:true,
              formatter: (cell, row) => {
                return new Date(row.createdAt).toString();
              },
              csvFormatter: (cell, row) => {
                return new Date(row.createdAt).toString();
              }
            },
            {
              dataField: "questionnaireName",
              text: "Questionnaire",
              sort: true,
              searchable:true,
              formatter: (cell, row) => {
                return row.questionnaireName;
              },
              csvFormatter: (cell, row) => {
                return row.questionnaireName;
              }
            },
            {
              dataField: "createdByName",
              text: "Created By",
              sort: true,
              searchable:true,
              formatter: (cell, row) => {
                return row.createdByName;
              },
              csvFormatter: (cell, row) => {
                return row.createdByName;
              }
            },
            {
              dataField: "questionnaireRepeatPer",
              text: "Frequency",
              sort: true,
              searchable:true,
              formatter: (cell, row) => {
                return row.questionnaireRepeatPer;
              },
              csvFormatter: (cell, row) => {
                return row.questionnaireRepeatPer;
              }
            },
            {
              dataField: "questions",
              text: "View",
              sort: true,
              formatter: (cell, row, rowIndex, extra) => {
                if (row && row.answers.length > 0) {
                  return (
                    <><button
                        className="btn btn-success answer-display"
                        onClick={() => {
                          setAnswerModel(row)
                          setShowAnswerModal(true)
                          setQuestionName(row.questionnaire.name)
                        }}
                      >
                        <i class="mdi mdi-eye answer-display-icon" style={{
                          fontSize: 20,
                        }}></i> <text style={{ color: "#fffff" }}>Answers</text>
                      </button>
                    </>
                  );
                }
              },
              formatExtraData: props,
              csvExport: false
            }
          ]}         
          exportCSV={{ fileName: `Questionnaires.csv`,  onlyExportFiltered: true, exportAll: false }}
        >
          {(toolKitProp) => (
            <div>
              <div>
                <Row className={`text-center col-md-12 mx-auto float-right`}>
                  <Col md={10}>
                    <MySearch {...toolKitProp.searchProps} />
                  </Col>
                  <Col md={2}>
                    <MyExportCSV {...toolKitProp.csvProps}>
                      Export CSV
                    </MyExportCSV>
                  </Col>
                </Row>
              </div>
              {<BootstrapTable
                className="table"
                wrapperClasses="table-responsive"
                keyField="_id"
                noDataIndication={() => {
                  return <div>
                    {props.loading && (
                      <LottieLoader
                        loadLoop={true}
                        autoplayLoop={true}
                        source={lottieLoaderFile}
                        Lheight={150}
                        Lwidth={150}
                        subTitle={"Please Wait ..."}
                      />
                    )}
                    {!props.loading && (
                      <LottieLoader
                        loadLoop={true}
                        autoplayLoop={true}
                        source={lottieEmptyFile}
                        Lheight={300}
                        Lwidth={300}
                        title={"Sorry! No questionnaire found :("}
                        subTitle={"We can't find any questionnaire."}
                      />
                    )}
                  </div>
                }}
                filter={filterFactory()}                
                {...toolKitProp.baseProps}
              />}
            </div>
          )}
        </ToolkitProvider>
        )}        
      </Col>
      <Modal
        isOpen={showQuestionModal}
        toggle={() => setShowQuestionModal(!showQuestionModal)}
        className="modal-lg modal-dialog-centered"
        style={{ maxWidth: "77%" }}
      >
        <ModalHeader className="close_button" style={{ float: 'right' }} toggle={() => setShowQuestionModal(!showQuestionModal)}>
          <Label className="align-items-center justify-content-center text-center">{questionModel.name}</Label>
          <button
              style={{ float: 'right' }}
              onClick={() => {
                setShowQuestionModal(!showQuestionModal)
              }}
              className="btn btn-info ml-2 close-click-cls"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>

           <button
              className="btn btn-info ml-2 print-click-cls"
              style={{ float: 'right' }}
              onClick={() => {
                window.scrollTo(0, 0);
                html2canvas(
                  document.querySelector("#printQuestionAsnwer")
                ).then((canvas) => {                 
                  var newTab = window.open();
                  newTab.document.body.innerHTML = `<div> <h4 style="padding:5px">${questionModel.name}</h4> <img src="${canvas.toDataURL("image/jpeg")}" style="width: 100%;"></div>`;
                  newTab.document.title = "Print Report";
                  setTimeout(function () {
                    newTab.print();
                  }, 100);
                  newTab.onfocus = function () {
                    setTimeout(function () {
                      newTab.close();
                    }, 100);
                  };
                });                                
              }}              
              >
              <i class="mdi mdi-cloud-print" style={{
                fontSize: 20
              }}></i>
              Print
            </button>  

            <button
              className="btn btn-info ml-2 download-click-cls"
              style={{ float: 'right' }}
              onClick={() => {
                window.scrollTo(0, 0);
                html2canvas(
                  document.querySelector("#printQuestionAsnwer")
                ).then((canvas) => {
                  var a = document.createElement("a");
                  // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                  a.href = canvas
                    .toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                  a.download = "Question.jpg";
                  a.click();
                });                
                window.scrollTo(
                  0,
                  document.body.scrollHeight ||
                  document.documentElement.scrollHeight
                );
              }}              
            >
              <i class="mdi mdi-download" style={{
                fontSize: 20
              }}></i>
              Download
            </button>            
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "white" }}>
          <div
            id="printQuestionAsnwer"
            className="pb-10"
            style={{ backgroundColor: "white" }}
          >
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              data={ questionModel.questions }
              columns={[
                {
                  dataField: "question",
                  text: "Question",
                  sort: true
                },
                {
                  dataField: "_id",
                  text: "Options",
                  sort: true,
                  formatter: (cell, row, rowIndex, extra) => {
                    const session = true;
                    return (
                      <div>
                        {
                          row.answers.map((item, i) => {
                            return (
                              <li style={{padding:8}} className="no-bullets">                                
                              {item.answer}
                              <i class={"mdi mdi-check " + (item.isCurrectAnswer ? '' : 'hide')} style={{
                                  fontSize: 20                                  
                                }}></i>
                              </li>                            
                            )
                          })
                        }
                  
                      </div>
                    );
                  }
                },
                {
                  dataField: "Options",
                  text: "Currect Answer",
                  sort: true,
                  formatter: (cell, row, rowIndex, extra) => {
                    const session = true;
                    var modelList = _.filter(row.answers, {"isCurrectAnswer":true});
                    return (
                      <div>
                        {
                          modelList.map((item, i) => {
                            return (
                              <li style={{padding:8}} className="no-bullets">                                
                              {item.answer}                             
                              </li>                            
                            )
                          })
                        }
                  
                      </div>
                    );                    
                  }
                }
              ]}
            />
          </div>
        </ModalBody>
      </Modal>  
      <Modal
        isOpen={showAnswerModal}
        toggle={() => setShowAnswerModal(!showAnswerModal)}
        className="modal-lg modal-dialog-centered"
        style={{ maxWidth: "77%" }}
        >
        <ModalHeader className="close_button" style={{ float: 'right' }} toggle={() => setShowAnswerModal(!showAnswerModal)}>
          <Label className="align-items-center justify-content-center text-center">{questionName}</Label>
          <button
              style={{ float: 'right' }}
              onClick={() => {
                setShowAnswerModal(!showAnswerModal)
                setLoading(false);
              }}
              className="btn btn-info ml-2 close-click-cls"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>
            <ReactToPrint
                  trigger={() => (
                    <div className="pull-right">
                    <button
                      className="btn btn-info ml-2 print-click-cls"
                      style={{ float: 'right' }}>
                      <i class="mdi mdi-cloud-print" style={{
                        fontSize: 20
                      }}></i>
                      Print
                    </button>
                    </div>
                  )}
                  content={() => componentRef.current}
                />
            <button
              className="btn btn-info ml-2 download-click-cls"
              style={{ float: 'right' }}
              onClick={() => {
                window.scrollTo(0, 0);
                html2canvas(
                  document.querySelector("#question-report"),{
                  onclone: function (clonedDoc) {
                    // I made the div hidden and here I am changing it to visible
                   clonedDoc.getElementById('question-report').style.display = 'block';
                  }
                }).then((canvas) => {
                  var a = document.createElement("a");
                  // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                  a.href = canvas
                    .toDataURL("image/jpeg")
                    .replace("image/jpeg", "image/octet-stream");
                  a.download = "Answers.jpg";
                  a.click();
                });                
                window.scrollTo(
                  0,
                  document.body.scrollHeight ||
                  document.documentElement.scrollHeight
                );
              }}              
            >
              <i class="mdi mdi-download" style={{
                fontSize: 20
              }}></i>
              Download
            </button>            
          <button
            className="btn btn-warning ml-2 email-click-cls"
            style={{ float: 'right' }}
            disabled={loading}
            onClick={() => {
              setLoading(true); // Moved to the beginning
              setTimeout(() =>{
                window.scrollTo(0, 0);
                html2canvas(
                  document.querySelector("#question-report"),
                  {
                    onclone: function (clonedDoc) {
                      // I made the div hidden and here I am changing it to visible
                      clonedDoc.getElementById('question-report').style.display = 'block';
                    }
                  }
                ).then((canvas) => {
                  setLoading(false);
                  setShowEmail(true);
                  setQuestionnaireImage(canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream"));
                  setName(questionName);
                });
              },0)   
            }}
          >
            <i class="mdi mdi-email" style={{ fontSize: 20 }}></i>
            Email
          </button>
           
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "white" }}>
        {loading && (
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
          ) 
        }
        <div className="text-center" style={{display:'none'}} id="question-report">
            <QuestionnariePrintReport 
            ref={componentRef}
            title={questionName}
            endDateTime = {answerModel.createdAt}
            patient = { props.userDetails }
            answers = {answerModel.answers}
            answerStatus = {answerStatus}
            />
        </div>
        <div
            id="printQuestionAsnwer"
            className="pb-10"
            style={{ backgroundColor: "white" }}
          >
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField="_id"
              noDataIndication="No Data Found"
              data={ answerModel.answers }
              columns={[
                {
                  dataField: "questionName",
                  text: "Question",
                  sort: true
                },
                {
                  dataField: "answer",
                  text: "Answer",
                  sort: true
                },
                {
                  dataField: "isCurrectAnswer",
                  text: "Answer status",
                  sort: true,
                  formatter: answerStatus
                },
              ]}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showEmail}
        toggle={() => setShowEmail(!showEmail)}
        className="modal-lg modal-dialog-centered"
        style={{ maxWidth: "32%" }}
        >
        <ModalHeader className="close_button" style={{ float: 'right' }} toggle={() => setShowEmail(!showEmail)}>
          <Label className="align-items-center justify-content-center text-center">Questionnaire Email</Label>          
          <button
              style={{ float: 'right' }}
              onClick={() => {                
                setShowEmail(!showEmail)
              }}
              className="btn btn-info ml-2 close-click-cls"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>                   
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "white" }}>          
          <EmailQuestionnaire 
            name={name}
            image={questionnaireImage}
            dismissModel={() => {
              setShowEmail(!showEmail)              
            }}
          />
        </ModalBody>
      </Modal>
    </Row>
    </div>
  );
};

function formatDate(cell) {
  return moment(cell).format("MMM DD, YYYY hh:mm:ss A");
}
const rowComponent = (row, setShowAnswerModal, setAnswerModel,questionnaireName,setQuestionName) => (
  <div>
    <h4 className="text-center">{questionnaireName}</h4>
    <BootstrapTable
      wrapperClasses="table-responsive"
      keyField="_id"
      noDataIndication="No Data Found"
      data={_.sortBy(row.session, ["start","isSessionCompleted"],['desc'])}
      defaultSorted={[{
        dataField: "start",
        order: "desc"
      }
      ]}
      filter={filterFactory()}
      columns={[
        {
          dataField: "start",
          text: "Start Time",
          sort: true,
          formatter: (cell, row) => {
            return formatDate(row.start);
          }         
        },
        {
          dataField: "end",
          text: "End Time",
          sort: true,
          formatter: (cell, row) => {
            return formatDate(row.sessionEndTime)
          }
        },
        {
          dataField: "",
          text: "Session",
          sort: true,
          formatter: (cell, row) => {
            return sessionStatus(row.isSessionCompleted)
          }
        },
        {
          dataField: "",
          text: "View",
          sort: true,
          formatter: (cell, rows) => {
            if (rows && rows.answers.length > 0) {
              return (
                <><button
                    className="btn btn-success answer-display"
                    onClick={() => {
                      setAnswerModel(rows)
                      setShowAnswerModal(true)
                      setQuestionName(questionnaireName)
                    }}
                  >
                    <i class="mdi mdi-eye answer-display-icon" style={{
                      fontSize: 20,
                    }}></i> Answers
                  </button>
                </>
              );
            }
            return "No Answers";
          },
        },
      ]}
    />
  </div>
);

export default QuestionnaireList;
