import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import _ from "lodash";
import ReactLoading from "react-loading";
import { useFetch2 } from "components/utils/authenticatedHook";
import { useFetch, formatDuration } from "components/utils/fetchHook";
import { size, map, reverse } from "lodash";
import Select from "react-select";
import { useDispatch } from "react-redux";
import RequiredSelect from "../Rules/RequiredSelect";
const ThirdPartyDevices = ({ modal, resetToggle, user }) => {
  const [serial, setserial] = useState("");
  const [device, setdevice] = useState("");
  const [error2, seterror] = useState("");
  const dispatch = useDispatch();
  const {
    response: deviceListASC = [],
    errordevice,
    isLoadingDevice
  } = useFetch(`/__public__/data?type=devices`);

  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);
  useEffect(() => {
    if (response) {
      resetToggle();
    }

    return () => {};
  }, [response]);
  const handleKeyDown = (e) => {
    const inputField = e.target;
    const inputValue = inputField.value;
    const caretPosition = inputField.selectionStart;
    if (e.keyCode === 32) {
      // Check if it's the first character and if there are no non-space characters before it
      if (caretPosition === 0 || /^\s+$/.test(inputValue.substring(0, caretPosition))) {
        e.preventDefault();
      }
    }
  };
  const submitReset = (e) => {
    e.preventDefault();
    doFetch({
      url: "/patient-device",
      method: "POST",
      body: { serial: serial, user: user, device: device._id }
    });
    dispatch({
      type: "DO_GET_DEVICES",
      payload: { id: user }
    });
  };
  let testingFrequency = [];
  for(let i = 1; i <= 10; i++) {
    testingFrequency.push({name: i, value: i});
  };
  const deviceList = reverse(deviceListASC);
  return (
    <Modal
      isOpen={modal}
      toggle={resetToggle}
      backdrop="static"
      className="bastaLang modal-md"
    >
      <ModalHeader toggle={resetToggle}>Add Device</ModalHeader>

      <ModalBody style={{ backgroundColor: "white" }}>
        <Form onSubmit={submitReset}>
          <FormGroup>
            <Label for="exampleText">
              Serial (IMEI number in some devices)
            </Label>
            <Input
              type="text"
              className="input-date-filter remove-bottom-margin"
              name="serial"
              required
              onChange={({ target }) => {
                setserial(target.value);
              }}
              onKeyDown={handleKeyDown}
              rows={10}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Device Manufacturer</Label>
            <RequiredSelect
              SelectComponent={Select}
              required={true}
              value={device}
              isLoading={deviceList && _.size(deviceList) > 0 ? false : true}
              onChange={(selectedOption) => {
                setdevice(selectedOption);
              }}
              getOptionLabel={(option) =>
                option["brand"] + " (" + option["name"] + ")"
              }
              getOptionValue={(option) => option["_id"]}
              options={deviceList && _.size(deviceList) > 0 ? deviceList : []}
            />            
            {" "}
          </FormGroup>
          {isLoading && !error && (
            <ReactLoading
              type="spin"
              className="text-center mx-auto"
              color="blue"
            />
          )}
          {error && <span className="text text-danger">{error.message}</span>}
          <button type="submit" className="btn btn-block btn-info">
            SUBMIT
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ThirdPartyDevices;
