import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";

import Link from "components/ExtendLink";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Form,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  CardImg,
  CardBody,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  CardSubtitle
} from "reactstrap";
import moment from "moment";
const _ = require("lodash");
function fullname(cell, row) {
  return (
    <div>
      {row.fname} {row.lname}
    </div>
  );
}
function countReadings(cell, row) {
  return <div>{_.size(row.readings)}</div>;
}

function viewProfile(cell, row, s, dateRange) {
  return (
    <div>
      <Link
        to={`/refer-population/dashboard/${row.fname
          .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
          .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${
          row._id
        }/${moment(dateRange.startDate).format()}/${moment(
          dateRange.endDate
        ).format()}`}
        target="_blank"
        className="btn btn-rounded btn-success btn-fw btn-sm"
      >
        Visit Profile
      </Link>
    </div>
  );
}

export default class TableSummary extends Component {
  render() {
    let columns;
    if (this.props.exceptionType) {
      if (this.props.hideProfile) {
        columns = [
          {
            dataField: "name",
            text: "Patient Name",
            formatter: fullname
          },
          {
            dataField: "email",
            text: "Patient Email"
          },
          {
            dataField: "_id",
            text: "Total Patient Readings Found",
            formatter: countReadings,
            sort: true
          },
          {
            dataField: "exceptionCount",
            text: "Total Exception  Found"
          }
        ];
      } else {
        columns = [
          {
            dataField: "name",
            text: "Patient Name",
            formatter: fullname
          },
          {
            dataField: "email",
            text: "Patient Email"
          },
          {
            dataField: "_id",
            text: "Total Filtered Readings Found",
            formatter: countReadings,
            sort: true
          },

          {
            dataField: "exceptionCount",
            text: "Total Exception  Found"
          },
          {
            dataField: "asdf",
            text: "Profile",
            formatter: viewProfile,
            formatExtraData: {
              startDate: this.props.startDate,
              endDate: this.props.endDate
            }
          }
        ];
      }
    } else {
      if (this.props.hideProfile) {
        columns = [
          {
            dataField: "name",
            text: "Patient Name",
            formatter: fullname
          },
          {
            dataField: "email",
            text: "Patient Email"
          },
          {
            dataField: "_id",
            text: "Total Filtered Readings Found",
            formatter: countReadings,
            sort: true
          }
        ];
      } else {
        columns = [
          {
            dataField: "name",
            text: "Patient Name",
            formatter: fullname
          },
          {
            dataField: "email",
            text: "Patient Email"
          },
          {
            dataField: "_id",
            text: "Total Filtered Readings Found",
            formatter: countReadings,
            sort: true
          },
          {
            dataField: "asdf",
            text: "Profile",
            formatter: viewProfile,
            formatExtraData: {
              startDate: this.props.startDate,
              endDate: this.props.endDate
            }
          }
        ];
      }
    }
    const removeZeroReadings = _.filter(this.props.users, function(o) {
      return _.size(o.readings) > 0;
    });
    return (
      <Card>
        <CardBody>
          <CardTitle className="text-center">
            {this.props.exceptionType
              ? "Analyze By Exception Result"
              : "Analyze By Condition Result"}
          </CardTitle>
          <CardSubtitle className="text-center">
            Date Range :{" "}
            {this.props.exceptionType ? (
              <span>
                {" "}
                {moment(this.props.prevFilter.from2).format(
                  "MMM DD, YYYY"
                )} - {moment(this.props.prevFilter.to2).format("MMM DD, YYYY")}
              </span>
            ) : (
              <span>
                {" "}
                {moment(this.props.prevFilter.from).format(
                  "MMM DD, YYYY"
                )} - {moment(this.props.prevFilter.to).format("MMM DD, YYYY")}
              </span>
            )}
          </CardSubtitle>
          {this.props.exceptionType && (
            <CardSubtitle className="text-center">
              Expectation {this.props.prevFilter.readingType2Value}
            </CardSubtitle>
          )}
          {!this.props.exceptionType && (
            <CardSubtitle className="text-center">
              Reading Type ({this.props.prevFilter.readingTypeValue.description}
              ) SubType(
              {this.props.prevFilter.readingSubTypeValue.name})
            </CardSubtitle>
          )}
          {!this.props.exceptionType && (
            <CardSubtitle className="text-center">
              Condition: ({this.props.prevFilter.condition}) Value: (
              {this.props.prevFilter.condition === ">=" &&
                this.props.prevFilter.value}
              {this.props.prevFilter.condition === "<=" &&
                this.props.prevFilter.value}
              {this.props.prevFilter.condition === "between" &&
                this.props.prevFilter.minValue +
                  " to " +
                  this.props.prevFilter.maxValue}
              {this.props.prevFilter.condition === "notBetween" &&
                this.props.prevFilter.minValue +
                  " to " +
                  this.props.prevFilter.maxValue}
              )
            </CardSubtitle>
          )}
          <div className="scrollbar-table-cls">
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="_id"
            data={removeZeroReadings}
            columns={columns}
          />
          </div>
        </CardBody>
      </Card>
    );
  }
}
