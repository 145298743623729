import React, { useState } from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row
} from "reactstrap";
import { useFetch2, formatDuration } from "components/utils/authenticatedHook";

import { map, capitalize, clone } from "lodash";

const UpdatePrescribe = ({ profile }) => {
  const [{ response, error, isLoading }, doFetch, sendError] = useFetch2(null);

  const { prescribeTestRate = null, _id: patientId = null } =
    response && response.data ? response.data : profile;
  const [values, setValues] = useState(prescribeTestRate);
  if (!prescribeTestRate) {
    return <div className="col-6 mx-auto">Something Went Wrong</div>;
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    const splitName = name.split("_");
    const prescribeTestRateInitial = clone(prescribeTestRate);

    prescribeTestRateInitial[splitName[0]][splitName[1]] = parseInt(value)
      ? parseInt(value)
      : value;
    setValues(prescribeTestRate);
  };
  const submitPrescribe = e => {
    e.preventDefault();
    const formData = values;

    if (formData) {
      doFetch({
        url: `/prescribe-testrate`,
        method: "POST",
        body: { user: patientId, prescribeTestRate: formData }
      });
    }
  };
  const prescribeFrom = map(prescribeTestRate, (e, k) => {
    const { numberOfTimes, every, readingType } = e;

    return (
      <Row form>
        <Col md={12}>
          <h4>{capitalize(k)}</h4>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label for="exampleEmail">Number of reading upload</Label>
            <Input
              type="number"
              name={`${k}_numberOfTimes`}
              defaultValue={numberOfTimes}
              placeholder="Number of reading upload"
              onChange={handleInputChange}
              required={true}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="exampleSelect">Every</Label>
            <Input
              type="select"
              name={`${k}_every`}
              defaultValue={every}
              onChange={handleInputChange}
              required={true}
            >
              <option value="week">Week</option>
              <option value="day">Day</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    );
  });
  return (
    <div className="">
      <h2 className="text-center">Update Prescribe Rate</h2>
      {/* <Form>{prescribeFrom}</Form> */}
      <Form onSubmit={submitPrescribe}>
        {prescribeFrom}
        <button type="submit" class="btn btn-block btn-info">
          Submit
        </button>
      </Form>
    </div>
  );
};

export default UpdatePrescribe;
