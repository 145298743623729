import React, { PureComponent } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Col,
  Row,
  Label,
  Input,
  FormText,
  FormFeedback,
  CustomInput,
  Tooltip
} from "reactstrap";
import userImage from "../../files/user.png";
import BootstrapTable from 'react-bootstrap-table-next';
import ImageUploader from "react-images-upload";
import { connect } from "react-redux";
import moment from "moment";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../src/files/animation/loading.json';
import isChecked from "../../files/ic_privacy_policy.svg";
import isUnChecked from "../../files/ic_privacy_policy_un_checked.svg";
import isNoSignature from "../../files/ic_no_signature.svg";
import Swal from 'sweetalert2';
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import ErrorDisplay from "../Reusable/ErrorDisplay";
import { primaryInsuranceProviders, allowNotificationType } from "../../constants/general.constant";
import { USERS_ROLE } from "../../constants/role.constant";
const _ = require("lodash");
class ProfileDetail extends PureComponent {
  constructor(props) {
    super(props);
    let confirmedLogs = [];
    if(this.props.profile && this.props.profile.confirmedLogs && this.props.profile.confirmedLogs.length > 0){
      confirmedLogs = this.props.profile.confirmedLogs;
    }    
    const results = _.chain(confirmedLogs)
      .groupBy("key")
      .map(users => ({
        key: users[0].key,
        status: _.first(_.orderBy(users, ['createdAt'], ['desc'])).status,
      }))
      .value();
    // Signature define
    
    const messagesEndRef = React.createRef()
    this.state = {
      fname: this.props.profile ? this.props.profile.fname : null,
      lname: this.props.profile ? this.props.profile.lname : null,
      nickname: this.props.profile ? this.props.profile.nickname : null,
      username: this.props.profile ? this.props.profile.username : null,
      email: this.props.profile ? this.props.profile.email : null,
      phone: this.props.profile ? this.props.profile.phone : null,
      homeNumber: this.props.profile ? this.props.profile.homeNumber : null,
      workNumber: this.props.profile ? this.props.profile.workNumber : null,
      fax: this.props.profile ? this.props.profile.fax : null,
      address: this.props.profile ? this.props.profile.address : null,
      city: this.props.profile ? this.props.profile.city : null,
      state: this.props.profile ? this.props.profile.state : null,
      zip: this.props.profile ? this.props.profile.zip : null,
      weight: this.props.profile ? this.props.profile.weight : null,
      bodytraceBPIMEI: this.props.profile
        ? this.props.profile.bodytraceBPIMEI
        : null,
      bodytraceWSIMEI: this.props.profile
        ? this.props.profile.bodytraceWSIMEI
        : null,
      height: this.props.profile ? this.props.profile.height : null,
      gender: this.props.profile ? this.props.profile.gender : null,
      bday: this.props.profile ? this.props.profile.bday : null,
      memberId: this.props.profile ? this.props.profile.memberId : null,
      organization: this.props.profile ? this.props.profile.organization : null,
      pin: this.props.profile ? this.props.profile.pin : null,
      subOrganization: this.props.profile
        ? this.props.profile.subOrganization
        : null,
      organization: this.props.profile ? this.props.profile.organization : null,
      profileImage:
        this.props.profile && this.props.profile.profileImage
          ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + this.props.profile.profileImage
          : null,
      insuranceProvider: this.props.profile
        ? this.props.profile.insuranceProvider
        : null,
      secondaryInsuranceProvider: this.props.profile
        ? this.props.profile.secondaryInsuranceProvider
        : null,
      mobileDevice: this.props.profile
        ? this.props.profile.mobileDevice
        : null,
      weekAssigned: this.props.profile ? this.props.profile.weekAssigned : null,
      enrolledPrograms: this.props.profile
        ? this.props.profile.enrolledPrograms
        : null,
      expectationsToFollow: this.props.profile
        ? this.props.profile.expectationsToFollow
        : null,
      confirmedEmail: results ? _.find(results, model => model.key == "confirmedEmail") ? _.find(results, model => model.key == "confirmedEmail").status : false : false,
      confirmedDOB: results ? _.find(results, model => model.key == "confirmedDOB") ? _.find(results, model => model.key == "confirmedDOB").status : false : false,
      confirmedCellularPhoneNumber: results ? _.find(results, model => model.key == "confirmedCellularPhoneNumber") ? _.find(results, model => model.key == "confirmedCellularPhoneNumber").status : false : false,
      confirmedAlternatePhoneNumber: results ? _.find(results, model => model.key == "confirmedAlternatePhoneNumber") ? _.find(results, model => model.key == "confirmedAlternatePhoneNumber").status : false : false,
      confirmedStreetAddress: results ? _.find(results, model => model.key == "confirmedStreetAddress") ? _.find(results, model => model.key == "confirmedStreetAddress").status : false : false,
      confirmedCity: results ? _.find(results, model => model.key == "confirmedCity") ? _.find(results, model => model.key == "confirmedCity").status : false : false,
      confirmedState: results ? _.find(results, model => model.key == "confirmedState") ? _.find(results, model => model.key == "confirmedState").status : false : false,
      confirmedZipCode: results ? _.find(results, model => model.key == "confirmedZipCode") ? _.find(results, model => model.key == "confirmedZipCode").status : false : false,
      confirmedHeight: results ? _.find(results, model => model.key == "confirmedHeight") ? _.find(results, model => model.key == "confirmedHeight").status : false : false,
      confirmedWeight: results ? _.find(results, model => model.key == "confirmedWeight") ? _.find(results, model => model.key == "confirmedWeight").status : false : false,
      confirmedInsuranceProvider: results ? _.find(results, model => model.key == "confirmedInsuranceProvider") ? _.find(results, model => model.key == "confirmedInsuranceProvider").status : false : false,
      confirmedSecondaryInsuranceProvider: results ? _.find(results, model => model.key == "confirmedSecondaryInsuranceProvider") ? _.find(results, model => model.key == "confirmedSecondaryInsuranceProvider").status : false : false,
      confirmedMobileDeviceType: results ? _.find(results, model => model.key == "confirmedMobileDeviceType") ? _.find(results, model => model.key == "confirmedMobileDeviceType").status : false : false,      
      confirmModalError: false,
      selectedLogs: "",
      selectedType: "",
      confirmedLogs: confirmedLogs,
      bodytraceBPError: undefined,
      bodytraceWSError: undefined,
      staffRole: this.props.profile ? this.props.profile.staffRole : null,
      isAgreedAggrement: this.props.profile ? this.props.profile.isAgreedAggrement : false,
      isAgreedPrivacyPolicy: this.props.profile ? this.props.profile.isAgreedPrivacyPolicy : false,
      isEditable: true,
      errorSignature: undefined,
      baseSignature: undefined,
      educationCampaign: this.props.profile ? this.props.profile.educationCampaign : "1",
      isAllowNotification: this.props.profile ? this.props.profile.isAllowNotification : Object.values(allowNotificationType),
      activeTooltip : null,
      uploadImage : null,
      role: this.props.profile ? (this.props.profile.isStaff && this.props.profile.isOtherRole ? this.props.profile.staffRole : this.props.profile.role) : null,
      otherRole: undefined,
      isOtherRole: this.props.profile ? this.props.profile.isOtherRole : null,
    };
    this.submitUser = this.submitUser.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeForCheckBox = this.handleInputChangeForCheckBox.bind(this);
    this.toggleConfiredLogs = this.toggleConfiredLogs.bind(this);
    this.toggleSignature = this.toggleSignature.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.clickViewLogs = this.clickViewLogs.bind(this);
    this.sigCanvas = React.createRef();
    this.allowhandleCheckBox = this.allowhandleCheckBox.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleRole = this.handleRole.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  handleKeyDown(e){
    const inputField = e.target;
    const inputValue = inputField.value;
    const caretPosition = inputField.selectionStart;
    if (e.keyCode === 32) {
      // Check if it's the first character and if there are no non-space characters before it
      if (caretPosition === 0 || /^\s+$/.test(inputValue.substring(0, caretPosition))) {
        e.preventDefault();
      }
    }
  }
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  handleRole(e){
    if(e.target.name != 'otherRole'){
      this.setState({ role: e.target.value, otherRole: undefined });
      return;
    }
    if(this.state.role == 'Other' && e.target.name == 'otherRole'){
      this.setState({ otherRole: e.target.value });
    }else if(this.state.role != 'Other' && e.target.name != 'otherRole'){
      this.setState({ otherRole: undefined });
    }
  }
  async onDrop(pictureFiles, pictureDataURLs) {
    try {
      const fileBase64 = await this.getBase64(pictureFiles[0]);
      this.setState({
        profileImage: fileBase64,
        uploadImage : pictureFiles[0]
      });
    } catch (error) {
      this.setState({
        profileImage: null,
        uploadImage : null
      });
    }
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    switch(name){
      case "bodytraceBPIMEI":
        this.isValidBodytraceBPIMEI(value,event);
        break;
      case "bodytraceWSIMEI":
        this.isValidBodytraceWSIMEI(value,event);
        break;
    }   
    this.setState({
      [name]: value
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;
      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
    if(value != "Other" && name == 'role'){
      this.setState({ otherRole: undefined });
    }
  }
  setStateAsync(obj,state) {
    return new Promise( ( resolve ) =>
        obj.setState( state , resolve )
    )
}

  async isValidBodytraceBPIMEI(value,event) {    
    this.props.dispatch({
      type: "DO_GET_VALUE_CHECKER_CLEAR",
      payload: {
        bodytraceBPIMEI: value
      }
    });
    if (value === undefined || value === null || value.trim().length == 0) {
      this.setState({
        bodytraceBPError: null
      });
    } else if (event.target.validity.patternMismatch) {
      this.setState({
        bodytraceBPError: 'Please enter a valid IMEI number.'
      });
    } else if (value.trim().length < 15 || value.trim().length > 15) {
      this.setState({
        bodytraceBPError: 'The IMEI number should be 15 digit long.'
      });
    } else {
      this.setState({
        bodytraceBPError: null
      });
      if(value != this.props.profile.bodytraceBPIMEI){
        let baseURL = process.env.REACT_APP_HOST_URL_PREFIX; 
               
        fetch(baseURL + "/check-availability?bodytraceBPIMEI=" + value).then(response => {
            if(!response.ok){ 
            this.setState({...this.state, bodytraceBPError: 'This IMEI has already been used by another patient.'})
          }          
        });
      }            
    }
  };

  async isValidBodytraceWSIMEI(value,event) {
    if (value === undefined || value === null || value.trim().length == 0) {
      this.setState({
        bodytraceWSError: null
      });
    } else if (event.target.validity.patternMismatch) {
      this.setState({
        bodytraceWSError: 'Please enter a valid IMEI number.'
      });
    } else if (value.trim().length < 15 || value.trim().length > 15) {
      this.setState({
        bodytraceWSError: 'The IMEI number should be 15 digit long.'
      });
    } else {
      this.setState({
        bodytraceWSError: null
      });
      if(value != this.props.profile.bodytraceWSIMEI){
        let baseURL = process.env.REACT_APP_HOST_URL_PREFIX;        
        fetch(baseURL + "/check-availability?bodytraceWSIMEI=" + value).then(response => {
            if(!response.ok){ 
            this.setState({...this.state, bodytraceWSError: 'This IMEI has already been used by another patient.'})
          }          
        });
      }
    }
  };
  /**
   * OnChange event for confired the information.
   * @param {*} event 
   */
  handleInputChangeForCheckBox(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var validation = false;
    var fieldName = false;
    if(value){
      switch (name){
        case "confirmedEmail":
          if (this.state.email === undefined || this.state.email === null || this.state.email.trim().length == 0) {     
            fieldName = "Email";
            validation = false;
          } else {
            validation = true;
          }            
          break;
        case "confirmedDOB":
          if (this.state.bday === undefined || this.state.bday === null || this.state.bday.trim().length == 0) {          
            fieldName = "DOB";
            validation = false;
          } else {
            validation = true;
          } 
          break;
        case "confirmedCellularPhoneNumber":
          if (this.state.phone === undefined || this.state.phone === null || this.state.phone.trim().length == 0) {  
            fieldName = "Cellular Phone Number";
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedAlternatePhoneNumber": 
          if (this.state.homeNumber === undefined || this.state.homeNumber === null || this.state.homeNumber.trim().length == 0) {  
            fieldName = "Alternate Phone Number";
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedStreetAddress":
          if (this.state.address === undefined || this.state.address === null || this.state.address.trim().length == 0) {   
            fieldName = "Street Address";
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedCity":
          if (this.state.city === undefined || this.state.city === null || this.state.city.trim().length == 0) {  
            fieldName = "City";        
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedState":
          if (this.state.state === undefined || this.state.city === null || this.state.city.trim().length == 0) {     
            fieldName = "State";             
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedZipCode":
          if (this.state.zip === undefined || this.state.zip === null || this.state.zip.trim().length == 0) {  
            fieldName = "Zip Code";
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedHeight":
          if (this.state.height === undefined || this.state.height === null || this.state.height.trim().length == 0) {     
            fieldName = "Height";     
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedWeight":
          if (this.state.weight === undefined || this.state.weight === null || this.state.weight.trim().length == 0) {         
            fieldName = "Weight"; 
            validation = false;
          } else {
            validation = true;
          }
          break;
        case "confirmedInsuranceProvider":
          if (this.state.insuranceProvider === undefined || this.state.insuranceProvider === null || this.state.insuranceProvider.trim().length == 0) {    
            fieldName = "Primary Insurance Provider";       
            validation = false;
          } else {
            validation = true;
          }
          break;
          case "confirmedSecondaryInsuranceProvider":
            if (this.state.secondaryInsuranceProvider === undefined || this.state.secondaryInsuranceProvider === null || this.state.secondaryInsuranceProvider.trim().length == 0) {    
              fieldName = "Secondary Insurance Provider";       
              validation = false;
            } else {
              validation = true;
            }
          break; 
          case "confirmedMobileDeviceType":
          if (this.state.mobileDevice === undefined || this.state.mobileDevice === null || this.state.mobileDevice.trim().length == 0) {    
            fieldName = "Mobile Device";       
            validation = false;
          } else {
            validation = true;
          }
          break;           
      }
    } else {
      validation = true;
    }
    if(validation){
      const model = {
        createdBy: _.assign(
          _.pick(this.props.userDetails,[
            "fname",
            "lname",
            "email",
          ],
          ),{
            profileImage:this.state.profileImage,
            isActive:true
          }                
        ),
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        user: this.props.currentUserId,
        key: name,
        status: value,
      }
      this.setState({
        [name]: value,
        confirmedLogs:[...this.state.confirmedLogs, model]      
        
      }, () => {      
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_INFO_LOGS",
          payload: {
            user: this.props.currentUserId,
            key: name,
            status: value,
          }
        });
      });
    } else {
      Swal.fire({
        title: 'Warning!',
        text: "Invalid value! Please check the "+fieldName+ " fields first, then the confirmed information.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay'
      });
    }  
  }
  toggle(){
    this.setState({activeTooltip : null})
  }
  allowhandleCheckBox(event){
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var fieldName = 'allow sms message';
    if(name){
     if(this.state && Array.isArray(this.state.isAllowNotification) && this.state.isAllowNotification.includes(name) && !value){
     const allowNotificationPop = this.state.isAllowNotification.filter(item => item !== name)
     this.setState({isAllowNotification : allowNotificationPop, activeTooltip : name})
     }else if(this.state && Array.isArray(this.state.isAllowNotification) && !this.state.isAllowNotification.includes(name) && value){
      const allowNotificationPush = [...this.state.isAllowNotification];
      // Push the new name to the cloned array
      allowNotificationPush.push(name);
      this.setState({isAllowNotification : allowNotificationPush, activeTooltip : null})
     }
    } else {
      Swal.fire({
        title: 'Warning!',
        text: "Invalid value! Please check the "+fieldName+ " fields first.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay'
      });
    }  
  }
  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalUser: false,
      fname: "",
      lname: "",
      nickname:"",
      email: "",
      username: ""
    });
  }
  submitUser(e) {
    e.preventDefault();
    if(this.state.bodytraceBPError != null && this.state.bodytraceBPError.length > 0 || this.state.bodytraceWSError != null && this.state.bodytraceWSError.length > 0){           
      return;
    }
    if(this.state.uploadImage){
      const formData = new FormData();
      formData.append('profileImage', this.state.uploadImage);
      formData.append('user', this.props.currentUserId);
      this.props.dispatch({
        type: "DO_SAVE_PROFILE_IMAGE",
        payload : formData
    })
    }
    if (!this.props.hideTitle) {
      this.props.dispatch({
        type: "DO_SAVE_PROFILE",
        payload: {
          fname: this.state.fname,
          // profileImage: this.state.profileImage,
          lname: this.state.lname,
          nickname: this.state.nickname,
          username: this.state.username,
          email: this.state.email,
          phone: this.state.phone,
          homeNumber: this.state.homeNumber,
          workNumber: this.state.workNumber,
          fax: this.state.fax,
          address: this.state.address,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          weight: this.state.weight,
          bodytraceBPIMEI: this.state.bodytraceBPIMEI,
          bodytraceWSIMEI: this.state.bodytraceWSIMEI,
          height: this.state.height,
          gender: this.state.gender,
          bday: this.state.bday,
          organization: this.state.organization,
          pin: this.state.pin,
          subOrganization: this.state.subOrganization,
          organization: this.state.organization,
          memberId: this.state.memberId,
          unitMeasurement: {
            umBloodPressure: this.props.profile.unitMeasurement.umBloodPressure,
            umGlucose: this.props.profile.unitMeasurement.umGlucose,
            umHeight: this.props.profile.unitMeasurement.umHeight,
            umWeight: this.props.profile.unitMeasurement.umWeight,
            umTemperature: this.props.profile.unitMeasurement.umTemperature
          },
          insuranceProvider: this.state.insuranceProvider,
          secondaryInsuranceProvider : this.state.secondaryInsuranceProvider,
          mobileDevice: this.state.mobileDevice,
          weekAssigned: this.state.weekAssigned,
          enrolledPrograms: this.state.enrolledPrograms,
          expectationsToFollow: this.state.expectationsToFollow,
          staffRole: this.state.staffRole,
          educationCampaign : this.state.educationCampaign,
          isAllowNotification: this.state.isAllowNotification,
          role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
          isOtherRole: this.state.role != 'Other' ? false : true,
        }
      });        
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE"
        });
      }, 1000);
    } else {
      let addClient;
      if (this.props.isClient) {
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_PATIENT",
          payload: {
            user: this.props.currentUserId,
            fname: this.state.fname,
            // profileImage: this.state.profileImage,
            lname: this.state.lname,
            nickname: this.state.nickname,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            homeNumber: this.state.homeNumber,
            workNumber: this.state.workNumber,
            fax: this.state.fax,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            weight: this.state.weight,
            bodytraceBPIMEI: this.state.bodytraceBPIMEI,
            bodytraceWSIMEI: this.state.bodytraceWSIMEI,
            height: this.state.height,
            gender: this.state.gender,
            bday: this.state.bday,
            organization: this.state.organization,
            pin: this.state.pin,
            subOrganization: this.state.subOrganization,
            organization: this.state.organization,
            memberId: this.state.memberId,
            isClient: true,
            unitMeasurement: {
              umBloodPressure:
                this.props.profile.unitMeasurement.umBloodPressure,
              umGlucose: this.props.profile.unitMeasurement.umGlucose,
              umHeight: this.props.profile.unitMeasurement.umHeight,
              umWeight: this.props.profile.unitMeasurement.umWeight,
              umTemperature: this.props.profile.unitMeasurement.umTemperature
            },
            insuranceProvider: this.state.insuranceProvider,
            secondaryInsuranceProvider : this.state.secondaryInsuranceProvider,
            mobileDevice: this.state.mobileDevice,
            weekAssigned: this.state.weekAssigned,
            enrolledPrograms: this.state.enrolledPrograms,
            expectationsToFollow: this.state.expectationsToFollow,
            staffRole: this.state.staffRole,
            isAllowNotification: this.state.isAllowNotification,
            role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
            isOtherRole: this.state.role != 'Other' ? false : true,
          }
        });
      } else {
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_PATIENT",
          payload: {
            user: this.props.currentUserId,
            fname: this.state.fname,
            // profileImage: this.state.profileImage,
            lname: this.state.lname,
            nickname: this.state.nickname,
            username: this.state.username,
            email: this.state.email,
            phone: this.state.phone,
            homeNumber: this.state.homeNumber,
            workNumber: this.state.workNumber,
            fax: this.state.fax,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            weight: this.state.weight,
            bodytraceBPIMEI: this.state.bodytraceBPIMEI,
            bodytraceWSIMEI: this.state.bodytraceWSIMEI,
            height: this.state.height,
            gender: this.state.gender,
            bday: this.state.bday,
            organization: this.state.organization,
            pin: this.state.pin,
            subOrganization: this.state.subOrganization,
            organization: this.state.organization,
            memberId: this.state.memberId,
            unitMeasurement: {
              umBloodPressure:
                this.props.profile.unitMeasurement.umBloodPressure,
              umGlucose: this.props.profile.unitMeasurement.umGlucose,
              umHeight: this.props.profile.unitMeasurement.umHeight,
              umWeight: this.props.profile.unitMeasurement.umWeight,
              umTemperature: this.props.profile.unitMeasurement.umTemperature
            },
            insuranceProvider: this.state.insuranceProvider,
            secondaryInsuranceProvider : this.state.secondaryInsuranceProvider,
            mobileDevice: this.state.mobileDevice,
            weekAssigned: this.state.weekAssigned,
            enrolledPrograms: this.state.enrolledPrograms,
            expectationsToFollow: this.state.expectationsToFollow,
            staffRole: this.state.staffRole,
            educationCampaign : this.state.educationCampaign,
            confirmedLogs: _.assign(
              _.pick(this.state, [
                "confirmedEmail",
                "confirmedDOB",
                "confirmedCellularPhoneNumber",
                "confirmedAlternatePhoneNumber",
                "confirmedStreetAddress",
                "confirmedCity",
                "confirmedState",
                "confirmedZipCode",
                "confirmedHeight",
                "confirmedWeight",
                "confirmedInsuranceProvider",
                "confirmedSecondaryInsuranceProvider"
              ])
            ),
            isAllowNotification: this.state.isAllowNotification,
            role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
            isOtherRole: this.state.role != 'Other' ? false : true,
          }
        });
      }

      if (this.props.isClient) {
        setTimeout(() => {
          this.props.dispatch({
            type: "DO_GET_PROFILE_PATIENT",
            payload: { user: this.props.currentUserId, isClient: true }
          });
        }, 1000);        
      } else {        
        setTimeout(() => {
          this.props.dispatch({
            type: "DO_GET_PROFILE_PATIENT",
            payload: { user: this.props.currentUserId }
          });
        }, 1000); 
      }
    }
  }

  toggleConfiredLogs() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }

  async toggleSignature() {
    this.setState({
      errorSignature: undefined
    });
    this.setState({
      signatureModal: !this.state.signatureModal
    });
  }

  /**
   * Signature Submit's
   * @returns 
   */
  onSubmitSignature(){
    if(this.sigCanvas.current.isEmpty()){
      this.setState({
        errorSignature: "Please sign your digital signature."
      });
      return;
    }
    this.toggleSignature();
    this.setState({
      errorSignature: undefined
    });
    const signature = this.sigCanvas.current.getSignaturePad().toDataURL("image/png");
    this.props.dispatch({
      type: "DO_UPDATE_SIGNATURE",
      payload: {
        user: this.props.currentUserId,
        signature: signature
      }
    });
    if (this.props.isClient) {
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId, isClient: true }
        });
      }, 1000);        
    } else {        
      setTimeout(() => {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
      }, 1000); 
    }
  }
  componentDidMount() {
    if (this.props.isClient) {
      if (!this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE",
          payload: { isClient: true }
        });
      }
      if (this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId, isClient: true }
        });
      }
    } else {
      if (!this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE"
        });
      }
      if (this.props.hideTitle) {
        this.props.dispatch({
          type: "DO_GET_PROFILE_PATIENT",
          payload: { user: this.props.currentUserId }
        });
      }
    }
    this.handleChange();
  }
  componentDidUpdate() {
    if (!this.state.fname) {
      this.setState({
        fname: this.props.profile ? this.props.profile.fname : null,
        lname: this.props.profile ? this.props.profile.lname : null,
        nickname: this.props.profile ? this.props.profile.nickname : null,
        username: this.props.profile ? this.props.profile.username : null,
        email: this.props.profile ? this.props.profile.email : null,
        phone: this.props.profile ? this.props.profile.phone : null,
        homeNumber: this.props.profile ? this.props.profile.homeNumber : null,
        workNumber: this.props.profile ? this.props.profile.workNumber : null,
        fax: this.props.profile ? this.props.profile.fax : null,
        address: this.props.profile ? this.props.profile.address : null,
        city: this.props.profile ? this.props.profile.city : null,
        state: this.props.profile ? this.props.profile.state : null,
        zip: this.props.profile ? this.props.profile.zip : null,
        weight: this.props.profile ? this.props.profile.weight : null,
        bodytraceBPIMEI: this.props.profile
          ? this.props.profile.bodytraceBPIMEI
          : null,
        bodytraceWSIMEI: this.props.profile
          ? this.props.profile.bodytraceWSIMEI
          : null,
        height: this.props.profile ? this.props.profile.height : null,
        gender: this.props.profile ? this.props.profile.gender : null,
        bday: this.props.profile ? this.props.profile.bday : null,
        memberId: this.props.profile ? this.props.profile.memberId : null,
        organization: this.props.profile
          ? this.props.profile.organization
          : null,
        subOrganization: this.props.profile
          ? this.props.profile.subOrganization
          : null,
        organization: this.props.profile
          ? this.props.profile.organization
          : null,
        profileImage:
          this.props.profile && this.props.profile.profileImage
            ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + this.props.profile.profileImage
            : null,
        insuranceProvider: this.props.insuranceProvider
          ? this.props.insuranceProvider
          : null,
          secondaryInsuranceProvider: this.props.secondaryInsuranceProvider
          ? this.props.secondaryInsuranceProvider
          : null,
          mobileDevice: this.props.mobileDevice
          ? this.props.mobileDevice
          : null,
        weekAssigned: this.props.weekAssigned ? this.props.weekAssigned : null,
        enrolledPrograms: this.props.enrolledPrograms
          ? this.props.enrolledPrograms
          : null,
        expectationsToFollow: this.props.expectationsToFollow
          ? this.props.expectationsToFollow
          : null,
        staffRole: this.props.profile ? this.props.profile.staffRole : null
      });
      this.handleChange();
    }
  }
  clickViewLogs() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }
  async handleChange(){
    const checkOther = USERS_ROLE.filter((item) => item.value === (this.props.profile ? (this.props.profile.isStaff && this.props.profile.isOtherRole ? this.props.profile.staffRole : this.props.profile.role) : null));
    if((checkOther && checkOther.length == 0) || this.props.profile.role == 'Other' || this.props.profile.isOtherRole == true){
      this.setState({
        role: "Other",
        otherRole: this.props.profile ? ((this.props.profile.isStaff && this.props.profile.isOtherRole) ? this.props.profile.staffRole : this.props.profile.role) : null,
      });
    }else{
      if(this.props.profile){
        this.setState({
          role: this.props.profile.isStaff && this.props.profile.isOtherRole ? this.props.profile.staffRole : this.props.profile.role ,
        }) 
      }
    }
  }
  render() {
    let roleOptions = [];
    if(this.props.profile && this.props.profile.role == 'careprovider' && !this.props.profile.isStaff){
      roleOptions = USERS_ROLE.filter((item) => item.type == "Careprovider");
    }else if(this.props.profile && this.props.profile.role == 'careprovider' && this.props.profile.isStaff){
      if(!this.props.hideTitle && (this.props.profile.isOtherRole == false && this.props.profile.staffRole == 'Shared/Family Account')){
        roleOptions = USERS_ROLE.filter((item) => item.type == "Shared");
      }else{
        if(!this.props.hideTitle){
          roleOptions = USERS_ROLE.filter((item) => item.type == "Staff" && item.value != 'Shared/Family Account');
        }else{
          roleOptions = USERS_ROLE.filter((item) => item.type == "Staff");
        }
      }
    }else if(this.props.profile && this.props.profile.role == 'patient' && !this.props.profile.isStaff){
      roleOptions = USERS_ROLE.filter((item) => item.type == "Patient");
    }else{
      if(this.props.profile && !this.props.profile.isStaff){
        roleOptions = USERS_ROLE.filter((item) => item.type == "Patient");
      }else{
        if(!this.props.hideTitle && (this.props.profile.isOtherRole == false && this.props.profile.staffRole == 'Shared/Family Account')){
          roleOptions = USERS_ROLE.filter((item) => item.type == "Shared");
        }else{
          if(!this.props.hideTitle){
            roleOptions = USERS_ROLE.filter((item) => item.type == "Staff" && item.value != 'Shared/Family Account');
          }else{
            roleOptions = USERS_ROLE.filter((item) => item.type == "Staff");
          }
        }
      }
    }
    return (
      <div
        className={this.props.hideTitle ? "col-12 mx-auto remove-table-padding" : "col-10 mx-auto"}>
        {!this.props.hideTitle && (        
          <Row className="header-device-list">
            <Col sm="12" className="center-label remove-table-padding div-row-center">
              <h3 className="text-center title-device">
              <i class="mdi mdi-account"></i> Update your profile details
              </h3>
            </Col>
          </Row>          
        )}
        {this.props.hideTitle && (
          <>
          <Row className="header-device-list">
            <Col sm="12" className="center-label remove-table-padding">
              <h3 className="text-center title-device">
                <i class="mdi mdi-account"></i> Profile Details
              </h3>
            </Col>
          </Row>          
          </>
        )}
        {this.props.ongoingPatientProfileRequest && (
            <LottieLoader
              loadLoop={true}
              autoplayLoop={true}
              source={lottieLoaderFile}
              Lheight={150}
              Lwidth={150}
              subTitle={"Please Wait ..."}
            />
          )}
        {!this.props.ongoingPatientProfileRequest && this.props.profile && this.state.fname && (
          <Form onSubmit={this.submitUser}>
            <Row>
              <Col sm="5" className="center-label">
                <FormGroup className="text-left" style={{ width: "96%", float: "left" }}>
                  <Label for="exampleText" className="profile-title">Profile Image</Label>
                  <ImageUploader
                    fileContainerStyle={{ backgroundColor: "#f5f5f5" }}
                    singleImage={true}
                    withIcon={true}
                    withPreview
                    defaultImages={
                      this.state.profileImage ? [this.state.profileImage] : []
                    }
                    buttonText="Choose image"
                    onChange={this.onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                    maxFileSize={10485760}
                    label="Max file size: 10mb, accepted: jpg|jpeg|png|gif"
                    buttonStyles={{
                      display: this.state.profileImage ? "none" : "block"
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="7" className="profile-signture-image">
                <FormGroup className="remove-margin responsive-form-group">
                  <Label className="profile-title">
                    Agreement's Status
                  </Label>
                  <div className="display-auth-status-div">
                    <Row>
                      <Col sm="6" className="center-label-aggrement">
                        <FormGroup className="div-text" style={{ marginLeft: "7%", marginTop: 24 }}>
                          {this.state.isAgreedPrivacyPolicy && (
                            <img src={isChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          {!this.state.isAgreedPrivacyPolicy && (
                            <img src={isUnChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          <Label className="form-check-label pr-4 margin-for-label">
                            Privacy Policy
                          </Label>
                        </FormGroup>
                        <FormGroup className="div-text" style={{ marginLeft: "7%", marginTop: 24 }}>
                          {this.state.isAgreedAggrement && (
                            <img src={isChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          {!this.state.isAgreedAggrement && (
                            <img src={isUnChecked} style={{ marginRight: 5, width: 25 }} />
                          )}
                          <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                            HIPPA Agreement
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup className="text-left" style={{ width: "96%", float: "left" }}>
                          <div className="signature-view-profile">
                            <Label for="exampleText" className="profile-title" style={{ fontWeight: 600, margin:0 }}>Signature on the Agreement</Label>
                            {this.props.userDetails && this.props.profile && this.props.userDetails._id == this.props.profile._id && (
                              <div className="pull-right">
                                <a
                                  onClick={() => {
                                    this.toggleSignature();
                                  }}
                                  className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom signature-edit-btn"
                                  style={{ float: 'right' }}>
                                  <i class="mdi mdi-pencil" style={{ marginRight: 5 }}></i>
                                  Edit
                                </a>
                              </div>
                            )}                            
                          </div>
                          <div className="signature-display">
                            {this.props.profileSignature && (
                              <img
                                width={155}
                                src={this.props.profileSignature}
                              />
                            )}
                            {!this.props.profileSignature && (
                              <>
                                <div>
                                  <img className="no-signature"
                                    src={isNoSignature}
                                  />
                                  <Label className="no-signature-text">
                                    Signature could not be found!
                                  </Label>
                                </div>
                              </>
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="exampleText">
                MemberId (Reference ID for CLIENT'S DB)
              </Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="memberId"
                placeholder="Enter MemberId"
                disabled={!this.props.hideTitle}
                value={this.state.memberId}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Email</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="Email"
                        name="confirmedEmail"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedEmail}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedEmail",
                              selectedType: "Email"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}                  
                </Col>
              </Row>
              <Input
                type="email"
                name="email"
                placeholder="Enter Email Address"
                className="input-date-filter remove-bottom-margin"
                disabled={this.props.enableUserEdit}
                value={this.state.email}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Username</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="username"
                placeholder="Enter Username"
                disabled={this.props.enableUserEdit}
                value={this.state.username}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            {this.props.profile && !this.props.profile.isCareProvider && (
              <FormGroup>
                <Label for="exampleText">Pin</Label>
                <Input
                  type="text"
                  className="input-date-filter remove-bottom-margin"
                  name="pin"
                  placeholder="Enter Pin"
                  disabled={this.props.enableUserEdit}
                  value={this.state.pin}
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
            )}

            <FormGroup>
              <Label for="exampleText">Legal First Name</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="fname"
                placeholder="Enter First Name"
                value={this.state.fname}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Legal Last Name</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="lname"
                placeholder="Enter Last Name"
                value={this.state.lname}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>

            <FormGroup>
              <Label for="exampleText">Legal Nickname</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="nickname"
                placeholder="Enter Nicknames"
                value={this.state.nickname}
                required={false}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>

            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">DOB</Label>
                </Col>
                <Col sm="9" >
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedDOB"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedDOB}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedDOB",
                              selectedType: "Date Of Birth"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="date"
                className="input-date-filter remove-bottom-margin"
                name="bday"
                placeholder="Enter Date Of Birth"
                required={true}
                value={moment(this.state.bday).format("YYYY-MM-DD")}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="isAllowNotification">
                Receive Messages
              </Label>
              <div>
                <Tooltip
                  target="sms"
                  toggle={this.toggle}
                  isOpen={this.state.activeTooltip === 'Sms'}
                >
                  If you uncheck the SMS checkbox, you will not receive SMS messages.
                </Tooltip>

                <Tooltip
                  target="email"
                  toggle={this.toggle}
                  isOpen={this.state.activeTooltip === 'Email'}
                >
                  If you uncheck the Email checkbox, you will not receive Emails.
                </Tooltip>

                <Tooltip
                  target="voice"
                  toggle={this.toggle}
                  isOpen={this.state.activeTooltip === 'Voice'}
                >
                  If you uncheck the Voice checkbox, you will not receive Voice messages.
                </Tooltip>
                <CustomInput
                  type="checkbox"
                  id="sms"
                  name={allowNotificationType.SMS}
                  onChange={this.allowhandleCheckBox}
                  label="Sms"
                  value={allowNotificationType.SMS}
                  inline
                  checked={this.state && Array.isArray(this.state.isAllowNotification) && this.state.isAllowNotification.includes(allowNotificationType.SMS)}
                />
                <CustomInput
                  type="checkbox"
                  id="email"
                  name={allowNotificationType.EMAIl}
                  onChange={this.allowhandleCheckBox}
                  label="Email"
                  value={allowNotificationType.EMAIl}
                  inline
                  checked={this.state && Array.isArray(this.state.isAllowNotification) && this.state.isAllowNotification.includes(allowNotificationType.EMAIl)}
                />
                <CustomInput
                  type="checkbox"
                  id="voice"
                  name={allowNotificationType.VOICE}
                  onChange={this.allowhandleCheckBox}
                  label="Voice"
                  value={allowNotificationType.VOICE}
                  inline
                  checked={this.state && Array.isArray(this.state.isAllowNotification) && this.state.isAllowNotification.includes(allowNotificationType.VOICE)}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Cellular Phone Number</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedCellularPhoneNumber"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedCellularPhoneNumber}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedCellularPhoneNumber",
                              selectedType: "Cellular Phone Number"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="phone"
                placeholder="Enter Cellular Phone Number"
                value={this.state.phone}
                required={true}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <div className="form-group">
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Alternate Phone Number</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedAlternatePhoneNumber"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedAlternatePhoneNumber}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedAlternatePhoneNumber",
                              selectedType: "Alternate Phone Number"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Home Number"
                name="homeNumber"
                value={this.state.homeNumber}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Work Number</label>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Alternate Phone Number"
                name="workNumber"
                value={this.state.workNumber}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Fax Number</label>
              <input
                type="text"
                className="form-control form-control-lg input-date-filter remove-bottom-margin"
                placeholder="Enter Fax Number"
                name="fax"
                value={this.state.fax}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select
                name="gender"
                className="form-control input-date-filter remove-bottom-margin"
                value={this.state.gender}
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Street Address</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedStreetAddress"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedStreetAddress}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedStreetAddress",
                              selectedType: "Street Address"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                value={this.state.address}
                name="address"
                placeholder="Enter Street Address"
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">City</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedCity"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedCity}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedCity",
                              selectedType: "City"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                name="city"
                placeholder="Enter City"
                className="input-date-filter remove-bottom-margin"
                value={this.state.city}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">State</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedState"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedState}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedState",
                              selectedType: "State"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="state"
                placeholder="Enter State"
                value={this.state.state}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Zip Code</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedZipCode"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedZipCode}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedZipCode",
                              selectedType: "Zip Code"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="zip"
                placeholder="Enter Zip Code"
                value={this.state.zip}
                onChange={this.handleInputChange}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Sub Organization</Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="subOrganization"
                placeholder="Enter Sub Organization"
                onChange={this.handleInputChange}
                value={this.state.subOrganization}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Height (ft)</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedHeight"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedHeight}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedHeight",
                              selectedType: "Height"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="height"
                placeholder="Enter Height"
                onChange={this.handleInputChange}
                value={this.state.height}
                rows={10}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Weight (lb)</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedWeight"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedWeight}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedWeight",
                              selectedType: "Weight"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="weight"
                placeholder="Enter Weight"
                onChange={this.handleInputChange}
                value={this.state.weight}
                rows={10}
              />
            </FormGroup>
            <div ref={this.messagesEndRef} />
            <FormGroup>
              <Label for="exampleText">
                Bodytrace Blood Pressure Monitor IMEI
              </Label>
              <Input
                type="phone"
                className="input-date-filter remove-bottom-margin"
                name="bodytraceBPIMEI"
                placeholder="Enter IMEI Number"
                disabled={this.props.isPatientLogin}
                onChange={this.handleInputChange}
                value={this.state.bodytraceBPIMEI}                
                pattern="[0-9]*"
                rows={10}
              />              
              {this.state.bodytraceBPError && this.state.bodytraceBPError != null && this.state.bodytraceBPError.length > 0 && (
                <Label
                    style={{
                      color: "red",
                      textAlign: "left",
                      padding: 5
                    }}>{this.state.bodytraceBPError}</Label>
              )}
              {/* {this.props.dataAvailability &&this.props.dataAvailability.status && (
                <Label
                style={{
                  color: "red",
                  textAlign: "left",
                  padding: 5
                }}>{this.props.dataAvailability.message}</Label>
              )} */}
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">Bodytrace Weight Scale IMEI</Label>
              <Input
                type="phone"
                className="input-date-filter remove-bottom-margin"
                name="bodytraceWSIMEI"
                placeholder="Enter IMEI Number"
                disabled={this.props.isPatientLogin}
                onChange={this.handleInputChange}
                value={this.state.bodytraceWSIMEI}
                pattern="[0-9]*"
                rows={10}
              />
              {this.state.bodytraceWSError && this.state.bodytraceWSError != null && this.state.bodytraceWSError.length > 0 && (
                <Label
                    style={{
                      color: "red",
                      textAlign: "left",
                      padding: 5
                    }}>{this.state.bodytraceWSError}</Label>
              )}              
            </FormGroup>
            <div className="form-group">
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Mobile Device Type</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedMobileDeviceType"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedMobileDeviceType}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedMobileDeviceType",
                              selectedType: "Mobile Device Type"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <select
                name="mobileDevice"
                className="form-control input-date-filter remove-bottom-margin"
                value={this.state.mobileDevice}
                onChange={this.handleInputChange}
              >
                <option value="">Select Device Type</option>
                <option value="Android">Android</option>
                <option value="iPhone">iPhone</option>
              </select>
            </div>
            <div className="form-group">
              <Row>
                <Col sm="3" className="center-label">
                  <Label for="exampleText">Primary Insurance Provider</Label>
                </Col>
                <Col sm="9">
                  {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                    <FormGroup className="remove-margin float-right">
                      <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                        Confirmed the information
                      </Label>
                      <Input
                        type="checkbox"
                        id="defaultCheck1"
                        name="confirmedInsuranceProvider"
                        className="form-check-input mt-0 check-box-style margin-for-check-box"
                        checked={this.state.confirmedInsuranceProvider}
                        onChange={this.handleInputChangeForCheckBox}
                        rows={10}
                      />
                      <div className="pull-right">
                        <a
                          onClick={() => {
                            this.setState({
                              confirmModal: !this.state.confirmModal,
                              selectedLogs: "confirmedInsuranceProvider",
                              selectedType: "Primary Insurance Provider"
                            });
                          }}
                          className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                          style={{ float: 'right' }}>
                          <i class="mdi mdi-account-card-details"></i>
                          Confirmed Logs
                        </a>
                      </div>
                    </FormGroup>
                  )}
                </Col>
              </Row>
              <select
                name="insuranceProvider"
                className="form-control input-date-filter remove-bottom-margin"
                value={this.state.insuranceProvider}
                disabled={this.props.isPatientLogin}
                onChange={this.handleInputChange}
              >
                {primaryInsuranceProviders && primaryInsuranceProviders.length > 0 &&
                  primaryInsuranceProviders.map((item) => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                  ))
                }
              </select>
            </div>
            <div className="form-group">
            <Row>
              <Col sm="3" className="center-label">
                <Label for="exampleText">Secondary Insurance Provider</Label>
              </Col>
              <Col sm="9">
                {this.props.hideTitle && this.props.profile.role === "patient" && !this.props.userDetails.region && (
                  <FormGroup className="remove-margin float-right">
                    <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                      Confirmed the information
                    </Label>
                    <Input
                      type="checkbox"
                      id="defaultCheck1"
                      name="confirmedSecondaryInsuranceProvider"
                      className="form-check-input mt-0 check-box-style margin-for-check-box"
                      checked={this.state.confirmedSecondaryInsuranceProvider}
                      onChange={this.handleInputChangeForCheckBox}
                      rows={10}
                    />
                    <div className="pull-right">
                      <a
                        onClick={() => {
                          this.setState({
                            confirmModal: !this.state.confirmModal,
                            selectedLogs: "confirmedSecondaryInsuranceProvider",
                            selectedType: "Secondary Insurance Provider"
                          });
                        }}
                        className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                        style={{ float: 'right' }}>
                        <i class="mdi mdi-account-card-details"></i>
                        Confirmed Logs
                      </a>
                    </div>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <select
              name="secondaryInsuranceProvider"
              className="form-control input-date-filter remove-bottom-margin"
              value={this.state.secondaryInsuranceProvider}
              disabled={this.props.isPatientLogin}
              onChange={this.handleInputChange}
            >
            {primaryInsuranceProviders && primaryInsuranceProviders.length > 0 &&
              primaryInsuranceProviders.map((item) => (
                <option key={item.value} value={item.value}>{item.label}</option>
              ))
            }
            </select>
          </div>
            <div className="form-group">
              <label>Phone Consultation Week Assigned</label>
              <select
                name="weekAssigned"
                className="form-control input-date-filter remove-bottom-margin"
                disabled={this.props.isPatientLogin}
                value={this.state.weekAssigned}
                onChange={this.handleInputChange}
              >
                <option value="">None</option>
                <option value="1">Week 1</option>
                <option value="2">Week 2</option>
                <option value="3">Week 3</option>
                <option value="4">Week 4</option>
              </select>
            </div>

            <div className="form-group">
              <label>Enrolled Program(s)</label>
              <select
                name="enrolledPrograms"
                className="form-control input-date-filter remove-bottom-margin"
                disabled={this.props.isPatientLogin}
                value={this.state.enrolledPrograms}
                onChange={this.handleInputChange}
              >
                <option value="">None</option>
                <option value="CCM">CCM</option>
                <option value="RPM">RPM</option>
                <option value="CCM&RPM">CCM & RPM</option>
              </select>
            </div>

            <div className="form-group">
              <label>Client Expectations to Follow</label>
              <select
                name="expectationsToFollow"
                className="form-control input-date-filter remove-bottom-margin"
                disabled={this.props.isPatientLogin || this.props.isClientLogin}
                value={this.state.expectationsToFollow}
                onChange={this.handleInputChange}
              >
                <option value="">No Adherence Logic</option>
                <option value="CCM Only">CCM Only</option>
                <option value="Adherence on Hold">Adherence on Hold</option>
                <option value="MVV">MVV Adherence</option>
                <option value="PICC">ANS Adherence</option>
                <option value="Care Provider">
                  Care Provider Adherence
                </option>
              </select>
            </div>

            {this.props.profile && (this.props.profile.role == 'patient' || this.props.isPatientLogin) && (<div className="form-group">
              <label>Education Campaign</label>
              <select
                name="educationCampaign"
                className="form-control input-date-filter remove-bottom-margin"
                disabled={this.props.isPatientLogin || this.props.isClientLogin}
                value={this.state.educationCampaign}
                onChange={this.handleInputChange}
              >
                <option value="1">Month 1</option>
                <option value="2">Month 2</option>
                <option value="3">Month 3</option>
                <option value="4">Month 4</option>
                <option value="5">Month 5</option>
                <option value="6">Month 6</option>
                <option value="7">Month 7</option>
                <option value="8">Month 8</option>
                <option value="9">Month 9</option>
                <option value="10">Month 10</option>
                <option value="11">Month 11</option>
                <option value="12">Month 12</option>
              </select>
            </div>)}

            <div className="form-group">
              <label>Select Role</label>
              <select
                name="role"
                className="form-control"
                onChange={this.handleInputChange}
                required
              >
                <option value="">
                  Select Role
                </option>
                {roleOptions.map((option) => (
                  <option value={option.value} selected={option.value == this.state.role ? true : false}>{option.role}</option>
                ))}
              </select>
            </div>

            {(this.state.role == 'Other' || this.state.otherRole != undefined) && (
            <FormGroup>
              <Label for="exampleText">
              Other Role *
              </Label>
              <Input
                type="text"
                className="input-date-filter remove-bottom-margin"
                name="otherRole"
                placeholder="Enter Other Role"
                value={this.state.otherRole}
                onChange={this.handleRole}
                onKeyDown={this.handleKeyDown}
                required={this.state.role == 'Other' ? true : false}
              />
            </FormGroup>
            )}

            <button type="submit" className="btn btn-block btn-info">
              SAVE CHANGES
            </button>
          </Form>
        )}
        
        <Modal
          style={{ width: "35%" }}
          isOpen={this.state.confirmModal}
          toggle={this.toggleConfiredLogs}
          className="modal-lg modal-dialog-centered"
        >
          <ModalHeader
            style={{ float: 'right' }}
            toggle={this.toggleConfiredLogs}
            className="close_button">
            <Label className="align-items-center justify-content-center text-center">Confirmation Logs - {this.state.selectedType}</Label>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.toggleConfiredLogs()
              }}
              className="btn btn-info ml-2 close-click-cls"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <BootstrapTable
              keyField='id'
              data={this.state.confirmedLogs ? _.orderBy(_.filter(this.state.confirmedLogs, model => model.key == this.state.selectedLogs), ['createdAt'], ['desc']) : []}
              noDataIndication={() => (
                <div>
                  <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={200}
                      Lwidth={200}
                      titleSmall={"Sorry! No Confirmation Logs :("}
                      subTitleSmall={"We can't find any confirmation logs."}
                    />            
                </div>
              )}
              columns={[{
                dataField: 'createdBy',
                text: 'Modify By',
                formatter: (cell, row) => {
                  var link = cell.profileImage;
                  if(link && !link.indexOf("http") == 0 ) {                    
                    link = "/" + cell.profileImage
                  }
                  const profile = cell.profileImage ? link :  userImage;
                  return (
                    <div className={cell.isActive ? "" : " text-danger"}>
                      <span>
                        <img src={profile} className="mr-2" alt="image" />
                        {cell && (
                          <>
                            {cell.lname.charAt(0).toUpperCase() + cell.lname.slice(1)}
                            {" "}
                            {cell.fname.charAt(0).toUpperCase() + cell.fname.slice(1)}
                          </>
                        )}
                      </span>
                    </div>
                  );
                },
              }, {
                dataField: 'status',
                text: "Status",
                formatter: (cell, row) => {
                  return row.status ? <div className='text-center confirmed-style'><span>Confirmed</span></div> : <div className='text-center disconfirmed-style'><span>Unconfirmed</span></div>;
                },
                headerStyle: (colum, colIndex) => {
                  return { width: '150px', textAlign: 'center' };
                }
              }, {
                dataField: 'createdAt',
                text: 'Date',
                formatter: (cell, row) => {
                  return row.createdAt ? moment(row.createdAt).format("MMMM DD, YYYY hh:mm:ss A") : "";
                },
              }
              ]}
            />
          </ModalBody>
        </Modal>
        <Modal
          style={{ width: "35%" }}
          isOpen={this.state.signatureModal}
          toggle={this.toggleSignature}>
          <ModalHeader
            style={{ float: 'right' }}
            toggle={this.toggleSignature}
            className="close_button">
            <Label className="align-items-center justify-content-center text-center">Edit Signature</Label>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.toggleSignature()
              }}
              className="btn btn-info ml-2 close-configration"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20,
                marginRight:8
              }}></i>
              Close
            </button>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.onSubmitSignature();
              }}
              className="btn btn-info ml-2 save-configration"
            >
              <i class="mdi mdi-check-circle" style={{
                fontSize: 20
              }}></i>
              Save
            </button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <div className="signature-div-center">
              <div>
                <div className="signature-div-style signature-margin">
                  <SignatureCanvas
                    ref={this.sigCanvas}
                    penColor="black"
                    canvasProps={{ width: 272, height: 150 }}
                  />
                  {this.state.isEditable && (
                    <>
                      <code><hr className="header-line-style" />
                        <button className="clear-signature" onClick={() => {
                          this.sigCanvas.current.clear()
                        }}>Clear</button></code>
                    </>
                  )}
                </div>
                {this.state.errorSignature && (
                  <div style={{ textAlign: "center" }}>
                    <ErrorDisplay message={this.state.errorSignature} />
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

}
const mapStateToProps = ({ user, careprovider }) => {
  const { usersOfferedAccess, timerStarts,ongoingRequest,ongoingPatientProfileRequest } = careprovider;
  const { authenticated, userDetails, isRegister, profile } = user;
  const isPatientLogin = userDetails.role === "patient" && !userDetails.role.isCareProvider && !userDetails.role.isStaff;
  var signatureLink = profile ? profile.signatureImage : undefined;
  if (signatureLink && !signatureLink.indexOf("http") == 0) {
    signatureLink = "/" + signatureLink +"?" + new Date()
  }
  const isClientLogin = userDetails && userDetails?.CLIENT_ID ? true : false;
  const profileSignature = signatureLink ? signatureLink :  undefined;
  return {
    authenticated,
    userDetails,
    isPatientLogin,
    ongoingRequest,
    isRegister,
    usersOfferedAccess,
    timerStarts,
    profile,
    ongoingPatientProfileRequest,
    profileSignature,
    isClientLogin
  };
};

export default connect(mapStateToProps)(ProfileDetail);
