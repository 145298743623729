import React from "react";
const _ = require("lodash");
const DisplayCardThreshold = (props) => {
  const border = props.isHideBorder ? "0px 0px 0px 0px" : "0px 0px 12px 12px";
  const leftAline = props.leftAline ? "center" : "center";
  return (
    <>
      <table class="table table-theshold" style={{ margin: 0, background: "#a4a4a436", borderRadius: border }}>
        <tr>
          {props.readingThreshold && _.map(props.readingThreshold, model => {
            if (!model.isWeight) {
              const name = model.readingSubTypeName;
              const low = model.lowThreshold ? model.lowThreshold : <div className="theshold-No-CarePlan">&nbsp;No Care Plan</div>;
              const high = model.highThreshold ? model.highThreshold : <div className="theshold-No-CarePlan">&nbsp;No Care Plan</div>;
              if ((model.lowThreshold != undefined && model.highThreshold != undefined)
                || (model.lowThreshold == undefined && model.highThreshold != undefined) || (model.lowThreshold != undefined && model.highThreshold == undefined)) {
                return (
                  <>
                    <td className="reading-chart-threshold-title" style={{ textAlign: leftAline }}>{name} Range</td>
                    <td className="reading-chart-threshold-range-details display-fax-center">{low} &nbsp;-&nbsp; {high}</td>
                  </>
                )
              } else {
                return (
                  <>
                    <td className="reading-chart-threshold-title" style={{ textAlign: leftAline }}>{name} Range</td>
                    <td className="reading-chart-threshold-range-details display-fax-center"><div className="theshold-No-CarePlan">&nbsp;No Care Plan</div></td>
                  </>
                )
              }
            } else {
              const name = model.readingSubTypeName;
              const weightGain = model.weightGain ? model.weightGain : <div className="theshold-No-CarePlan">&nbsp;No Care Plan</div>;
              return (
                <>
                  <td className="reading-chart-threshold-title" style={{ textAlign: leftAline }}>{name} Range</td>
                  <td className="reading-chart-threshold-range-details display-fax-center">Less than {weightGain}lbs gain or loss within 24 hours</td>
                </>
              )
            }
          })}
        </tr>
      </table>
    </>
  );
};

export default DisplayCardThreshold;
