// Here Decalre All Helper Functions

export const queryStringToObject = (queryString) => {
    return queryString.split("&").reduce((acc, pair) => {
        const [key, value] = pair.split("=");
        acc[decodeURIComponent(key)] = decodeURIComponent(value);
        return acc;
    }, {});
};

export const objectToQueryString = (obj) => {
    return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
    .join("&");
};

