import moment from "moment";
import React, { useEffect, useState } from "react";
import { filter, orderBy, size } from "lodash";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { _ } from "lodash";
import ViolationsSearchBar from "./Filters/ViolationsSearchBar";
import ReadingTypeFilter from "./Filters/ReadingTypeFilter";
import RuleTypeFilter from "./Filters/RuleTypeFilter";
import { MyExportCSV } from "./violationHelpers";
import { useSelector } from "react-redux";

const ViolationFilters = (props, context) => {
  const { usersViolationsRecordsCount } = useSelector((state) => state.careprovider);
  const [display, setDisplay] = useState("Events");
  const [dateRange, setDateRange] = useState("7");
  const [errorMessage, seterrorMessage] = useState("");
  const [endDateError, setEndDateError] = useState(null);
  const [form, setValues] = useState({
    startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  }); 
  var displayMessaeg = "";
  const { handleFilterChange = null } = props;

  const updateField = (e) => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  //Update date input effect
  useEffect(() => {
    isEndDateValid(form.endDate);
    const { endDate, startDate } = form;
    if (dateRange == "custom") {
      if (
        startDate &&
        endDate &&
        moment(endDate).isSameOrAfter(moment(startDate))
      ) {
        if (moment(startDate).isValid && moment(endDate).isValid) {
          handleFilterChange({
            startDate: moment(startDate).startOf("day").utc().format(),
            endDate: moment(endDate).endOf("day").utc().format(),
          });
        }
      }
    }
  }, [form]);

  /**
   * Checking end date is greater then start date validation
   * @param {*} value enter end date's
   * @returns validation status
   */
  function isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      setEndDateError(undefined);
      return false;
    } else {
      var date = moment(new Date(form.startDate), "MM/DD/YYYY");
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, "MM/DD/YYYY");
      var enteredDate = moment(dateEntered, "MM/DD/YYYY");
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        setEndDateError(
          "Please select end date is greater than equal to start date."
        );
        return false;
      } else if (today.isSame(enteredDate)) {
        setEndDateError(undefined);
        return true;
      } else {
        setEndDateError(undefined);
        return true;
      }
    }
  }

  // Selected date rance effect's
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!props.myRules || props.myRules.length > 0) {
        seterrorMessage("No records found");
      }
    }, 8000);
    return () => clearTimeout(timer);
  }, []);

  var eventsCard = { backgroundColor: "#198ae3", color: "white" };
  var vitalsCard = { backgroundColor: "white", color: "black" };
  var adherencesCard = { backgroundColor: "white", color: "black" };
  var questionnaireCard = { backgroundColor: "white", color: "black" };
  var eventsAndVitalsCard = { backgroundColor: "white", color: "black" };

  if (display == "Events&Exceptions") {
    displayMessaeg = "events & exceptions";
    eventsAndVitalsCard = { backgroundColor: "#198ae3", color: "white" };
    adherencesCard = { backgroundColor: "white", color: "black" };
    eventsCard = { backgroundColor: "white", color: "black" };
    vitalsCard = { backgroundColor: "white", color: "black" };
    questionnaireCard = { backgroundColor: "white", color: "black" };
  } else if (display == "Events") {
    displayMessaeg = "events exceptions";
    eventsCard = { backgroundColor: "#198ae3", color: "white" };
    vitalsCard = { backgroundColor: "white", color: "black" };
    adherencesCard = { backgroundColor: "white", color: "black" };
    eventsAndVitalsCard = { backgroundColor: "white", color: "black" };
    questionnaireCard = { backgroundColor: "white", color: "black" };
  } else if (display == "Vitals") {
    displayMessaeg = "vitals exceptions";
    vitalsCard = { backgroundColor: "#198ae3", color: "white" };
    adherencesCard = { backgroundColor: "white", color: "black" };
    eventsAndVitalsCard = { backgroundColor: "white", color: "black" };
    eventsCard = { backgroundColor: "white", color: "black" };
    questionnaireCard = { backgroundColor: "white", color: "black" };
  } else if (display == "Adherences") {
    displayMessaeg = "adherences exceptions";
    adherencesCard = { backgroundColor: "#198ae3", color: "white" };
    eventsAndVitalsCard = { backgroundColor: "white", color: "black" };
    vitalsCard = { backgroundColor: "white", color: "black" };
    eventsCard = { backgroundColor: "white", color: "black" };
    questionnaireCard = { backgroundColor: "white", color: "black" };
  } else if (display == "Questionnaires") {
    displayMessaeg = "questionnaires exceptions";
    adherencesCard = { backgroundColor: "white", color: "black" };
    eventsAndVitalsCard = { backgroundColor: "white", color: "black" };
    vitalsCard = { backgroundColor: "white", color: "black" };
    eventsCard = { backgroundColor: "white", color: "black" };
    questionnaireCard = { backgroundColor: "#198ae3", color: "white" };
  }

  return (
    <div>
      <Row>
        <Col className="grid-margin mt-4">
          <Row>
            <Col>
              {" "}
              <div
                className="card overflow-hidden card-design"
                style={{ pointerEvents: "yes" }}
                onClick={() => {
                  setDisplay("Events&Exceptions");
                  props.setActiveCard("Events&Exceptions");
                  handleFilterChange({ ruleTypes: "Vitals,Weight,Trending Violation" });
                }}
              >
                <div className="card-content" style={eventsAndVitalsCard}>
                  <div className="card-body clearfix" style={{ height: 170 }}>
                    <div className="media align-items-stretch h-100">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 mr-2" />
                      </div>
                      <div className="media-body d-flex justify-content-center align-items-center flex-column">
                        <h4>Events & Exceptions</h4>
                        <h1>{usersViolationsRecordsCount?.vitalExpectation}</h1>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              {" "}
              <div
                className="card overflow-hidden card-design"
                style={{ pointerEvents: "yes" }}
                onClick={() => {
                  setDisplay("Events Exceptions");
                  handleFilterChange({ ruleTypes: "" });
                }}
              >
                <div className="card-content" style={eventsCard}>
                  <div className="card-body cleartfix" style={{ height: 170 }}>
                    <div className="media align-items-stretch">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 mr-2" />
                      </div>
                      <div className="media-body text-center">
                        <h4>Events</h4>
                        <h1>{usersViolationsRecordsCount?.Events}</h1>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              {" "}
              <div
                className="card overflow-hidden card-design"
                style={{ pointerEvents: "yes" }}
                onClick={() => {
                  setDisplay("Vitals");
                  props.setActiveCard("Vitals Exceptions");
                  handleFilterChange({ ruleTypes: "Vitals,Weight,Trending Violation" });
                }}
              >
                <div className="card-content" style={vitalsCard}>
                  <div className="card-body cleartfix" style={{ height: 170 }}>
                    <div className="media align-items-stretch">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 mr-2" />
                      </div>
                      <div className="media-body text-center">
                        <h4>Vital Exceptions</h4>
                        <h1>{usersViolationsRecordsCount?.vitalExpectation}</h1>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              {" "}
              <div
                className="card overflow-hidden card-design"
                style={{ pointerEvents: "yes" }}
                onClick={() => {
                  setDisplay("Adherences");
                  props.setActiveCard("Adherences Exceptions");
                  handleFilterChange({ ruleTypes: "Adherence,Time Adherence" });
                }}
              >
                <div className="card-content" style={adherencesCard}>
                  <div className="card-body cleartfix" style={{ height: 170 }}>
                    <div className="media align-items-stretch">
                      <div className="align-self-center">
                        <i className="icon-pencil primary font-large-2 mr-2" />
                      </div>
                      <div className="media-body text-center">
                        <h4>Adherence Exceptions</h4>
                        <h1>
                          {usersViolationsRecordsCount?.adherenceExpectation}
                        </h1>
                      </div>
                      <div className="align-self-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {props.userRole != "patient" && (
              <Col>
                {" "}
                <div
                  className="card overflow-hidden card-design"
                  style={{ pointerEvents: "yes" }}
                  onClick={() => {
                    setDisplay("Questionnaires");
                    props.setActiveCard("Questionnaires Exceptions");
                    handleFilterChange({ ruleTypes: "Questionnaire" });
                  }}
                >
                  <div className="card-content" style={questionnaireCard}>
                    <div className="card-body clearfix" style={{ height: 170 }}>
                      <div className="media align-items-stretch h-100">
                        <div className="align-self-center">
                          <i className="icon-pencil primary font-large-2 mr-2" />
                        </div>
                        <div className="media-body">
                          <h4>Questionnaire Exceptions</h4>
                          <h1>
                            {
                              usersViolationsRecordsCount?.questionnaireExpectation
                            }
                          </h1>
                        </div>
                        <div className="align-self-center"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <div className={`text-center col-md-6 mx-auto`}>
        <h5 className="mb-4">Date Range</h5>
      </div>
      <div className="content-wrap mb-4">
        <div className="text-center">
          <select
            name="dateRange"
            className="date-range-option"
            defaultValue={dateRange}
            onChange={(event) => {
              const target = event.target;
              const value =
                target.type === "checkbox" ? target.checked : target.value;

              let dateFrom;
              if (value == 7) {
                dateFrom = moment().subtract(7, "d").format();
              }
              if (value == 1) {
                dateFrom = moment().subtract(1, "M").format();
              }
              if (value == 6) {
                dateFrom = moment().subtract(6, "M").format();
              }
              if (value == "custom") {
                dateFrom = "";
              }
              setValues({
                startDate: moment(dateFrom).startOf("day").utc().format(),
                endDate: moment().endOf("day").utc().format(),
              });
              setDateRange(value);
              if(value !== 'custom'){
                handleFilterChange({
                  startDate: moment(dateFrom).startOf("day").utc().format(),
                  endDate: moment().endOf("day").utc().format(),
                });
              }
            }}
          >
            <option value="7">7 Days</option>
            <option value="1">1 Month</option>
            <option value="6">6 Months</option>
            <option value="custom">Custom Date</option>
          </select>
        </div>
        <div>
          <Row
            form
            hidden={dateRange !== "custom"}
            className="text-center col-md-6 mx-auto"
            style={{ marginTop: 10 }}
          >
            <Col md={6}>
              <FormGroup className="text-left">
                <Label for="exampleEmail">Start Date</Label>
                <Input
                  className="input-date-filter"
                  type="date"
                  name="startDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={updateField}
                  value={form.startDate}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="text-left">
                <Label for="examplePassword">End Date</Label>
                <Input
                  className="input-date-filter"
                  type="date"
                  name="endDate"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={updateField}
                  value={form.endDate}
                />
                <Label
                  style={{
                    color: "red",
                    textAlign: "left",
                    padding: 5,
                  }}
                >
                  {endDateError}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <Row>
          <Col md={4}>
            <ViolationsSearchBar
              setSearchQuery={props.setSearchQuery}
              searchQuery={props.searchQuery}
              isCareProvider={props.isCareProvider}
              isAdminPatient={props.isAdminPatient}
            />
          </Col>
          <Col md={3}>
            <ReadingTypeFilter
              onChange={handleFilterChange}
              value={props.filterData?.readingType}
            />
          </Col>
          <Col md={3}>
            <RuleTypeFilter
              onChange={handleFilterChange}
              value={props.filterData?.ruleType}
            />
          </Col>
          <Col md={2}>
            <MyExportCSV onExport={props.onExport} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViolationFilters;
