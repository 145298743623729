import React from "react";
import { Label, FormGroup } from "reactstrap";
import Select from "react-select";

const ruleTypeOptions = [
  { label: "Reminder", value: "Reminder" },
  { label: "Time Adherence", value: "Time Adherence" },
  { label: "Vitals", value: "Vitals" },
  { label: "Adherence", value: "Adherence" },
  { label: "Weight", value: "Weight" },
  { label: "Trending Violation", value: "Trending Violation" },
];

const RuleTypeFilter = ({ onChange, value }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
    }),
  };

  return (
    <FormGroup className="reading_select">
      <Select
        onChange={(obj) => {
          onChange({ ruleType: obj && obj.value != null ? obj.value : "" });
        }}
        isClearable={true}
        options={ruleTypeOptions}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        placeholder="Select RuleType"
        styles={customStyles}
        value={
          value ? ruleTypeOptions.find((item) => item.value === value) : ""
        }
      />
    </FormGroup>
  );
};

export default RuleTypeFilter;
