import React from 'react';
import {
  Row,
  Col} from "reactstrap";
import moment from "moment";
import userImage from "../files/user.png";
import userImageError from "../files/imageError.svg";
import doctorImage from "../files/doctor.png";
const ProfileHeader = ({ profile, children, imgStyling, noMargin, latestReadingUser,ccmTotalDuration, rpmTotalDuration }) => {
  if (profile) {
    const { fname, lname, isActive, profileImage, isCareProvider, email } = profile;
    const grey = isActive
      ? { ...imgStyling }
      : {
        ...imgStyling,        
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)"
      };

    function capitalize(cell) {
      if (cell) {
        return cell.charAt(0).toUpperCase() + cell.slice(1);
      }
    }
    const imageDefault = isCareProvider ? doctorImage : userImage;
    const isMobile = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
    return (
      <div className='col-12 remove-table-padding' style={{marginBottom:5}}>
      {isMobile ? 
          <Row className="text-center" style={{ margin: 5, padding: 0 }}>
            <Col className="notes-pdf-title">
              <div className="text-left reading-card-for-all-reading float-right">
                <img
                  style={{ borderRadius: '100%', ...grey }}
                  width={45}
                  height={45}
                  src={profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + profileImage : imageDefault}
                  className={noMargin ? "" : "mr-2"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = imageDefault;
                  }}
                  alt="image"
                />
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-account-circle" style={{ color: "#607d8b" }}></i> Patient:</td>
                        <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                          {`${capitalize(lname)} ${capitalize(fname)}`}</td>
                      </tr>
                      {/* <tr>
                  <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-email" style={{ color: "#607d8b" }}></i> Email:</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                    {profile && profile.email ? profile.email : " - "}</td>
                </tr> */}
                      <tr>
                        <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-calendar" style={{ color: "#607d8b" }}></i> Status :</td>
                        {profile.isActive && (
                          <button
                            className="btn btn-info ml-2 is-active-profile" style={{ margin: 3 }}>
                            Active
                          </button>
                        )}
                        {!profile.isActive && (
                          <button
                            className="btn btn-info ml-2 is-in-active-profile" style={{ margin: 3 }}>
                            InActive
                          </button>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            <Col style={{ marginRight: 80 }} xs={12} sm={12}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-face" style={{ color: "#607d8b" }}></i> Nickname:</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {profile && profile.nickname ? profile.nickname : " - "}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-account" style={{ color: "#607d8b" }}></i> Main Care Provider:</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {profile && profile.mainCareProvider && profile.mainCareProvider.fname ?
                        profile.mainCareProvider.fname + " " +
                        profile.mainCareProvider.lname : " - "}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-phone" style={{ color: "#607d8b" }}></i> Phone No:</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {profile && profile.phone ? profile.phone : " - "}</td>
                  </tr>

                </tbody>
              </table>
            </Col>
            <Col style={{ marginRight: 80 }} xs={12} sm={12}>
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-calendar" style={{ color: "#607d8b" }}></i> Last Reading :</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {latestReadingUser && latestReadingUser.deviceReadingDate
                        ? moment(latestReadingUser.deviceReadingDate).format("MMMM DD, YYYY") : "NONE"}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-clock" style={{ color: "#607d8b" }}></i> Total RPM :</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {rpmTotalDuration ? rpmTotalDuration : "00:00:00"}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "start", fontSize: 14 }}><i class="mdi mdi-clock" style={{ color: "#607d8b" }}></i> Total CCM :</td>
                    <td style={{ fontWeight: 600, textAlign: "start", fontSize: 14 }}>{"\u00a0\u00a0"}
                      {ccmTotalDuration ? ccmTotalDuration : "00:00:00"}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row> : 
        <Row className="text-center" style={{ margin: 5, padding: 0 }}>
          <Col className="notes-pdf-title">
            <div className="text-left reading-card-for-all-reading float-right">
              <img
                style={{ borderRadius: '100%', ...grey }}
                width={50}
                height={50}
                src={profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + profileImage : imageDefault}
                className={noMargin ? "" : "mr-2"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = imageDefault;
                }}
                alt="image"
              />
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-account-circle" style={{ color: "#607d8b" }}></i> Patient:</td>
                      <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                      {`${capitalize(lname)} ${capitalize(fname)}`}</td>
                    </tr>
                    {/* <tr>
                      <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-email" style={{ color: "#607d8b" }}></i> Email:</td>
                      <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                        {profile && profile.email ? profile.email : " - "}</td>
                    </tr> */}
                    <tr>
                      <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#607d8b" }}></i> Status :</td>
                      {profile.isActive && (
                        <button
                        className="btn btn-info ml-2 is-active-profile" style={{margin:3}}>
                        Active
                        </button>
                      )}
                      {!profile.isActive && (
                         <button
                         className="btn btn-info ml-2 is-in-active-profile" style={{margin:3}}>
                         InActive
                         </button>
                      )}
                    </tr>
                  </tbody>
              </table>
              </div>
            </div>
          </Col>
          <Col className="notes-pdf-details" style={{marginRight:80}}>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-face" style={{ color: "#607d8b" }}></i> Nickname:</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {profile && profile.nickname ? profile.nickname : " - "}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-account" style={{ color: "#607d8b" }}></i> Main Care Provider:</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {profile && profile.mainCareProvider && profile.mainCareProvider.fname ?
                      profile.mainCareProvider.fname + " " +
                      profile.mainCareProvider.lname : " - "}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-phone" style={{ color: "#607d8b" }}></i> Phone No:</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {profile && profile.phone ? profile.phone : " - "}</td>
                </tr>
                
              </tbody>
            </table>
          </Col> 
          <Col className="notes-pdf-details" style={{marginRight:80}}>
            <table>
              <tbody>
                <tr>
                  <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-calendar" style={{ color: "#607d8b" }}></i> Last Reading :</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {latestReadingUser && latestReadingUser.deviceReadingDate
                      ? moment(latestReadingUser.deviceReadingDate).format("MMMM DD, YYYY") : "NONE"}</td>
                </tr>
                <tr>
                <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-clock" style={{ color: "#607d8b" }}></i> Total RPM :</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {rpmTotalDuration ? rpmTotalDuration : "00:00:00"}</td>
                </tr>
                <tr>
                <td style={{ textAlign: "start", fontSize: 16 }}><i class="mdi mdi-clock" style={{ color: "#607d8b" }}></i> Total CCM :</td>
                  <td style={{ fontWeight: 600, textAlign: "start", fontSize: 16 }}>{"\u00a0\u00a0"}
                    {ccmTotalDuration ? ccmTotalDuration : "00:00:00"}</td>
                </tr>
              </tbody>
            </table>
          </Col>         
        </Row> 
      }
      </div>
    );
  } else {
    return (
      <div >
       
      </div>
    );
  }

};
export default ProfileHeader;
