import _ from "lodash";
import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Row,
  Col
} from "reactstrap";
import AdherenceIcon from "../../files/adherence.svg";
import ReminderIcon from "../../files/reminder.jpg";
import TimeIcon from "../../files/timeadherence.jpg";
import TrendingIcon from "../../files/trending.svg";
import VitalsIcon from "../../files/vitals.jpg";
import WeightIcon from "../../files/weight.svg";
import ExpandedRule from "./ExpandedRule";

function withIcon(cell) {
  let icon;
  switch (cell) {
    case "Vitals":
      icon = VitalsIcon;
      break;
    case "Time Adherence":
      icon = TimeIcon;
      break;
    case "Adherence":
      icon = AdherenceIcon;
      break;
    case "Reminder":
      icon = ReminderIcon;
      break;
    case "Trending Violation":
      icon = TrendingIcon;
      break;
    case "Weight":
      icon = WeightIcon;
      break;

    default:
      break;
  }
  return (
    <span>
      <img src={icon} className="mr-2" alt="image" />
      {cell == "Weight" ? "Weight Difference" : cell}
    </span>
  );
}

function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
function showButtons(cell, row, rowIndex, formatExtraData) {
  const authenticatedUser = formatExtraData.userId;
  if (row) {
    const isSameUser = authenticatedUser !== row.receivedBy._id;
    const approvedButton =
      isSameUser && !row.isApproved ? (
        <button
          type="button"
          onClick={() => formatExtraData.approve(row._id)}
          className="btn btn-success btn-fw"
        >
          Accept Request
        </button>
      ) : (
        ""
      );
    return (
      <span>
        {row.isApproved ? (
          <button
            type="button"
            onClick={() => formatExtraData.delete(row._id)}
            className="btn btn-danger btn-fw"
          >
            Delete
          </button>
        ) : (
          approvedButton
        )}
      </span>
    );
  }
}
function checkSubType(cell, row, index, myRules) {
  if (row.ruleType === "Trending Violation") {
    const rule = _.find(myRules, { _id: row.rule[0].ruleId });
    if (rule) {
      return <div><b>Expectation Name:</b> {rule.name}</div>;
    }
    return "";
  }

  return row.ruleType == "Weight" ? "Weight" : cell;
}
function ruleCheck(cell, row, index) {
  const name = cell ? cell.replace(/\s+/g, ' ').trim() :"";
  const { sharedUsers = [], conditionGroup = [] } = row;
  if (_.size(sharedUsers) === 0 && _.size(conditionGroup) === 0) {
    return (
      <div>
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target={"test" + row._id}
        >
          <PopoverHeader>Need Setup</PopoverHeader>
          <PopoverBody>
            This expectation is a default one that needs to add a patient to
            monitor or a condition group which has multiple patients, you can
            modify it and delete if you want.
          </PopoverBody>
        </UncontrolledPopover>
        {name} {"  "}
        <Button
          id={"test" + row._id}
          type="button"
          className="text-danger btn btn-dark btn-rounded float-right"
        >
          <i class="mdi mdi-alert-decagram btn-icon-prepend text-danger"></i>
        </Button>
      </div>
    );
  }
  return name;
}
const expandRow = {
  renderer: (row) => (
    <div style={{margin:17}}>
      <ExpandedRule ruleDetails={row} key={row._id + "water"} />
    </div>
  ),
  showExpandColumn: true
};
const RuleList = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: props.myRules.length
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };
  // Define custom sorting function
  const sortedArrayView = _.sortBy(props.myRules, (obj) => {
    var name = obj.readingSubTypeId;
    var vitalsObject;
    if (obj.ruleType == "Trending Violation") {
      vitalsObject = _.find(props.myRules, v => v.ruleType == "Vitals" && obj.rule[0].ruleId == v._id);
      if (vitalsObject) {
        name = vitalsObject.readingSubTypeId;
      }
    }
    if (!name) {
      return 18;
    }
    if (name.includes("5be56b848c5d7a064721798f")) return 1;
    if (name.includes("5be56beb3863cd0647d79890")) return 2;
    if (name.includes("5be56c1a3863cd0647d79891")) return 3;
    if (name.includes("5be56c293863cd0647d79892")) return 4;
    if (name.includes("5be56c383863cd0647d79893")) return 5;
    if (name.includes("5be56c493863cd0647d79894")) return 6;
    if (name.includes("5be56cad3863cd0647d79895")) return 7;
    if (name.includes("5be56cbe3863cd0647d79896")) return 8;
    if (name.includes("5be56cdf3863cd0647d79897")) return 9;
    if (name.includes("5be56d0b3863cd0647d79898")) return 10;
    if (name.includes("5be56d233863cd0647d79899")) return 11;
    if (name.includes("5be56d4c3863cd0647d7989a")) return 12;
    if (name.includes("5be56d593863cd0647d7989b")) return 13;
    if (name.includes("5be56d623863cd0647d7989c")) return 14;
    if (name.includes("5facda01d3c27132448a0ba5")) return 15;
    if (name.includes("5facda06d3c27132448a0ba6")) return 16;
    if (name.includes("63343d03db4e75cc4566c516")) return 17;
    return 18; // For items without V, T, or W
  });


  return (
    <>
      <div className="care-plan-header-background" style={{ marginTop: 25 }}>
        <Row>
          <Col sm="12" className="center-label remove-table-padding">
            <h3 className="text-center title-device" style={{ marginLeft: 25 }}>
              <i class="mdi mdi-marker-check"></i> Expectations
            </h3>
          </Col>
        </Row>
      </div>
      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="_id"
        noDataIndication="No Data Found"
        data={sortedArrayView}
        filter={filterFactory()}
        bordered={true}
        hover={true}
        condensed={true}
        expandRow={expandRow}
        pagination={paginationFactory(options)}
        columns={[
          {
            dataField: "name",
            text: "Expectation Name",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter'
            }),
            filterValue: (cell, row) => {
              const name = row.name.replace(/\s+/g, ' ').trim();
              return name ?? '';
            },
            formatter: ruleCheck,
            headerFormatter: priceFormatter
          },
          {
            dataField: "ruleType",
            text: "Expectation Type",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter'
            }),
            formatter: withIcon,
            headerFormatter: priceFormatter
          },
          {
            dataField: "readingSubType",
            text: "Reading Type",
            sort: true,
            filter: textFilter({
              className: 'input-date-filter'
            }),
            formatter: checkSubType,
            headerFormatter: priceFormatter,
            formatExtraData: props.myRules
          },
          {
            dataField: "condition",
            text: "Expectation",
            formatter: (cell, row) => {
              switch (row.ruleType) {
                case "Vitals":
                  if (row.condition == "<=") {
                    return "Less than or equal to";
                  } else if (row.condition == ">=") {
                    return "Greater than or equal to";
                  } else if (row.condition == "between") {
                    return "Between";
                  } else if (
                    row.name.includes("Daily") ||
                    row.name.includes("1 X")
                  ) {
                    return "1 x per day";
                  } else if (row.name.includes("2 X")) {
                    return "2 x per day";
                  } else {
                    return <div className="text-center">-</div>;
                  }
                  break;
                case "Adherence":
                  return row.repeatPerDay && row.repeatPerDay == 1 ? "Daily" : row.repeatPerDay + " Days";
                case "Trending Violation":
                  return row.numberOfTimes + " Times With In " + row.repeatPer.charAt(0).toUpperCase() + row.repeatPer.slice(1);
                case "Weight":
                  return row.gainLossPerDay + " Per Day";
                case "Reminder":
                case "Time Adherence":
                  if (row.time && _.size(row.time) > 0) {
                    let text = []
                    _.map(row.time, (model, index) => {
                      if (_.size(row.time) - 1 == index) {
                        text.push(<span>{model}</span>);
                      } else {
                        text.push(<span>{model}, </span>);
                      }

                    });
                    return (
                      <div> {text}</div>
                    )
                  } else {
                    return <div className="text-center">-</div>;
                  }
                default:
                  if (row.condition == "<=") {
                    return "Less than or equal to";
                  } else if (row.condition == ">=") {
                    return "Greater than or equal to";
                  } else if (
                    row && (row.name && row.name.includes("Daily") ||
                    row.name && row.name.includes("1 X"))
                  ) {
                    return "1 x per day";
                  } else if (row && row.name && row.name.includes("2 X")) {
                    return "2 x per day";
                  } else if (
                    row && (row && row.includes("PM") ||
                    row && row.includes("AM"))
                  ) {
                    return row;
                  }
                  return row.condition;
              }
            },
            headerStyle: (colum, colIndex) => {
              return { padding: "16px", fontSize: "16px", color: "#070707" };
            },
          },
          {
            dataField: "valueProperty",
            text: "Value",
            headerStyle: (colum, colIndex) => {
              return { width: '100px', textAlign: 'center', padding: "16px", fontSize: "16px", color: "#070707" };
            },
            formatter: (cell, row) => {
              if (
                row.valueProperty &&
                row["valueProperty"] !== undefined &&
                row.valueProperty.length > 0
              ) {
                if (row.condition != "between") {
                  return (
                    <div className="text-center">
                      {row.valueProperty[0].value}
                    </div>
                  );
                } else if (row.condition == "between") {
                  return (
                    <div className="text-center">
                      {row.valueProperty[0].minValue}{" "}
                      and{" "}
                      {row.valueProperty[0].maxValue}
                    </div>
                  );
                } else {
                  return <div className="text-center">-</div>;
                }
              } else {
                return <div className="text-center">-</div>;
              }
            }
          },
          {
            dataField: "",
            text: "Notifications",
            sort: false,
            headerStyle: (colum, colIndex) => {
              return { width: '320px', textAlign: 'center', padding: "16px", fontSize: "16px", color: "#070707" };
            },
            formatter: (cell, row) => {
              if ((row.notifyReadingOwner && row.notifyReadingOwner.length > 0) ||
                (row.notifySelf && row.notifySelf.length) > 0 ||
                (row.notifyStaff && row.notifyStaff.length) > 0) {
                return (
                  <div className="text-center">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <th className="text-center">CareProvider</th>
                        <th className="text-center">Staff</th>
                        <th className="text-center">Patient</th>
                      </tr>
                      <tr>
                        <td>
                          {(row.notifySelf && row.notifySelf.length > 0 && (
                            <div className="text-center">{_.map(_.uniqBy(row.notifySelf), v => _.startCase(v)).toString()} </div>
                          ))}
                          {(!row.notifySelf || row.notifySelf.length == 0 && (
                            <div className="text-center"> - </div>
                          ))}
                        </td>
                        <td>
                          {(row.notifyStaff && row.notifyStaff.length > 0 && (
                            <div className="text-center">{_.map(_.uniqBy(row.notifyStaff), v => _.startCase(v)).toString()} </div>
                          ))}
                          {(!row.notifyStaff || row.notifyStaff.length == 0 && (
                            <div className="text-center"> - </div>
                          ))}
                        </td>
                        <td>
                          {(row.notifyReadingOwner && row.notifyReadingOwner.length > 0 && (
                            <div className="text-center">{_.map(_.uniqBy(row.notifyReadingOwner), v => _.startCase(v)).toString()} </div>
                          ))}
                          {(!row.notifyReadingOwner || row.notifyReadingOwner.length == 0 && (
                            <div className="text-center"> - </div>
                          ))}
                        </td>
                      </tr>
                    </table>
                  </div>
                );
              } else {
                return <div className="text-center">-</div>;
              }
            }
          },
          {
            dataField: "updatedAt",
            text: "Last Updated",
            sort: true,
            formatter: (cell, row) => {
              if (row.updatedAt) {
                return moment(row.updatedAt).tz(moment.tz.guess()).format("MMMM DD, YYYY");
              } else {
                return "NONE";
              }
            },
            headerStyle: (colum, colIndex) => {
              return { padding: "16px", fontSize: "16px", color: "#070707" };
            },
          }
        ]}
      />
    </>
  );
};

export default RuleList;
