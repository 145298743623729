import React from "react";
import qs from "qs";

import { connect } from "react-redux";
import useAxios from "axios-hooks";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useFetch } from "components/utils/fetchHook";
import UserName from "../Reusable/UserName";
import Link from "components/ExtendLink";
import userImage from "../../files/user.png";
import doctorImage from "../../files/doctor.png";

import moment from "moment";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { USERS_ROLE } from "../../constants/role.constant";
const roleTypeInfo = USERS_ROLE.filter((item) => item.type == "Staff" || item.type == "Careprovider").map((item) => item.role);
const ViolationDetail = (props) => {
  const authToken = useSelector((state) => state.user.userDetails.token);
  const urlParams = window.location.search.replace("?", "");
  const convertObject = qs.parse(urlParams);
  const { identifier, vuid, client = null } = convertObject;
  const isMobile = window.innerWidth <= 768; // Check if the window width is less than or equal to 768px
  if (!authToken) {
    if (client) {
      props.history.push(
        `/${client}/login?redirect=` +
          encodeURIComponent(window.location.pathname + window.location.search)
      );
    } else {
      props.history.push(
        "/login?redirect=" +
          encodeURIComponent(window.location.pathname + window.location.search)
      );
    }

    // props.history.push("/login?redirect=analytics");
  }

  const goBack = () => {
    props.history.goBack();
  };
  var path = "";
  const role = props.role;
  if(role == 'patient'){
    path = "/patient/violations/"+ vuid;
  }else if(roleTypeInfo.includes(role)){
    path = "/careprovider/violations/"+ vuid;
  }else if (role == 'admin' || role == 'support') {
    path = "/admin/violations/" + vuid;
  } else {
    path = "/client/violations/" + vuid;
  }

  const { response, error, isLoading } = useFetch(
    `${path}`
  );
  const responseData = response ? response.data : response;
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  const {
    violatedBy,
    createdBy,
    name,
    message,
    reading,
    expectation = []
  } = responseData || [];
  if (!violatedBy) return <div>{response && response.notFound ? response.message : 'Violation does not exist'}</div>;

  if (expectation) {
    var vitalException = (
      <>
        <tr>
          <th className="text-right">Condition:</th>
          <td>{expectation.condition}</td>
        </tr>
        <tr>
          <th className="text-right">Value:</th>
          <td>
            {expectation.valueProperty && (
              <span>
                {expectation.valueProperty[0].minValue
                  ? "Min:" +
                    expectation.valueProperty[0].minValue +
                    ", Max:" +
                    expectation.valueProperty[0].maxValue
                  : expectation.valueProperty[0].value}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th className="text-right">Last Test Result:</th>
          {reading && reading.value && (
            <td>Reading Value: {reading.value.toString()},</td>
          )}
          {reading && reading.values && (
            <td>Reading Value: {reading.values.toString()},</td>
          )}
        </tr>
      </>
    );
  } else {
    var vitalException = "";
  }

  const imgStyling = {
    width: "120px",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto"
  };
  const noMargin = true;
  const { fname, lname, isActive, profileImage, isCareProvider } = violatedBy;
  const grey = isActive
    ? { ...imgStyling }
    : {
        ...imgStyling,
        "-webkit-filter": "grayscale(100%)",
        filter: "grayscale(100%)"
      };

  const imageDefault = isCareProvider ? doctorImage : userImage;

  let lastTestResult = "";
  if (responseData && reading) {
    switch (responseData.reading.readingType.abbrev) {
      case "GL":
        if (reading?.value) {
          lastTestResult = Object.entries(reading.readingValue).map(
            ([key, value]) => {
              if (key === "event" || key === "eventDescription") {
                return "";
              }
              return (
                <p className="exception-value">
                  {key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}: {value}
                  mg/dL
                </p>
              );
            }
          );
        }
        break;
      case "BP":
      case "BT_BP":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Systolic : {reading.value[0] ? reading.value[0] + "mmHg" : "0mmHg"}</p>
              <p className="exception-value">Diastolic : {reading.value[1] ? reading.value[1] + "mmHg" : "0mmHg"}</p>
              <p className="exception-value">Heart Rate : {reading.value[2] ? reading.value[2] + "bpm" : "0bpm"}</p>
            </>
          );
        } else if (reading?.values) {
          lastTestResult = (
            <>
              <p className="exception-value">
                Systolic:
                {reading.values.systolic ? reading.values.systolic + "mmHg" : "0mmHg"}
              </p>
              <p className="exception-value">
                Diastolic:{" "}
                {reading.values.diastolic ? reading.values.diastolic + "mmHg" : "0mmHg"}
              </p>
              <p className="exception-value">Heart Rate : {reading.values.pulse ? reading.values.pulse+ "bpm" : "0"}</p>
            </>
          );
        }
        break;
      case "WS":
      case "BT_WS":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Weight : {Math.round(reading.readingValue.weight * 2.20462)}lbs</p>
              <p className="exception-value">BMI : {Math.round(reading.readingValue.bmi)} ({reading.readingValue.bmiDescription})</p>
            </>
          );
        } else if (reading?.values) {
          lastTestResult = <p className="exception-value">Weight : {reading.values.weight}lbs</p>;
        }
        break;
      case "PO":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Oxygen Saturation : {reading.value[0] ? reading.value[0] + "%" : "0%"}</p>
              {!responseData.isBloodPressureReading && (
                <p className="exception-value">Heart Rate : {reading.value[1] ? reading.value[1] + "bmi" : "0bmi"}</p>
              )}
              
            </>
          );
        }
        break;
      case "TM":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Temperature : {reading.value[0] ? Math.round((reading.value[0] * 1.8 + 32) * 10 / 10).toFixed(2) : "0"}F</p>
            </>
          );
        }
        break;
      case "AT":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Steps : {reading.value[0] ? reading.value[0] : "0"}</p>
            </>
          );
        }
        break;

      case "CO2":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">CO2 : {reading.value[0] ? reading.value[0] + "g/mol" : "0g/mol"}</p>
            </>
          );
        }
        break;

      case "ZOE":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Zoe: {reading.value[0] ? reading.value[0] + "fluid" : "0fluid"}</p>
            </>
          );
        }
        break;
      case "HR":
        if (reading?.value) {
          lastTestResult = (
            <>
              <p className="exception-value">Heart Rate: {reading.value[0] ? reading.value[0] : "0"}bpm</p>
            </>
          );
        }
        break;
    }
  }    
  return (
    <div>
      <span className={`align-items-center mb-4 ${isMobile ? 'd-flex flex-column flex-sm-row' : ''}`}>
        <button
          className={`text-white btn btn-gradient-dark btn-icon-text ${isMobile ? 'mb-2 mb-sm-0 mr-sm-auto' : 'mr-auto'}`}
          onClick={goBack}
        >
          <i className="mdi mdi-arrow-left btn-icon-prepend" />
          EXCEPTIONS
        </button>
        <Link
          to={`/dashboard/${violatedBy.fname
            .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase()}-${violatedBy.lname
            .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase()}/${violatedBy._id}`}
          className="text-white ml-3 btn btn-success btn-icon-text"
        >
          View Patient Charts
        </Link>
      </span>

      <Row className={isMobile ? 'm-auto' : ''}>
      <Col xs={12} sm={12} md={3} lg={3} style={{marginTop:15}}>
          <Card className="card-design">
            <CardBody className="card-body-remove-padding">
                {" "}
              <div
                className={isActive ? "" : " text-danger"}
              >
                <img
                  style={{ borderRadius: '100%', ...grey }}
                  src={profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + profileImage : imageDefault}
                  className={noMargin ? "" : "mr-2"}
                  alt="image"
                  width={145}
                  height={145}
                />
              </div>
              <div style={{
                padding: "9px 0px 0px 10px",
                textAlign:"left"
              }}>
                <span className="font-weight-bold">
                  {lname}, {fname}
                </span>
                <div>Nickname: {violatedBy.nickname ? violatedBy.nickname : '-'}</div>
                <div>Phone: {violatedBy.phone}</div>
                <div>Gender: {violatedBy.gender}</div>
                <div>
                  Main Care Provider: {createdBy.fname + " " + createdBy.lname}
                </div>
                <div>Main Care Provider Phone: {createdBy.phone || "---"}</div>
              </div>
            </CardBody>
          </Card>          
        </Col>
        <Col xs={12} sm={12} mt={9} lg={9} >
          <Row>
            <Col xs={12} sm={12} mt={12} lg={12}>
              <Row style={{marginTop:15}}>
                <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
                  <Card className="card-design">
                    <CardBody className="card-body-remove-padding">
                      <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                        Exception Description
                      </CardTitle>
                      <CardSubtitle className="reading-card-subtitle-details">
                        {responseData.name} ({responseData.ruleType})
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
                {responseData.reading && (
                  <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
                    <Card className="card-design">
                      <CardBody className="card-body-remove-padding-with-image">
                        <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                          Reading Monitored
                        </CardTitle>
                        <CardSubtitle>
                          <div>
                            <div className="text-left reading-card-for-all-reading-details">
                              <img
                                src={require(`../../files/Reading/${responseData.reading.readingType.abbrev}.svg`)}
                                alt="profile-image"
                                className="profile rounded-circle img-fluid mr-4 reading-icon-details"
                              /> <div>
                                  <p className="reading-card-title-details">{responseData.reading.readingType.description}</p>
                                  <p className="reading-card-subtitle-details">{responseData.readingSubType}</p>                                
                              </div>
                            </div>
                          </div>                       
                        </CardSubtitle>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {!responseData.reading && (
                  <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
                    <Card className="card-design">
                      <CardBody className="card-body-remove-padding-with-image">
                        <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                          Rule Type
                        </CardTitle>                       
                        <CardSubtitle className="reading-card-subtitle-details rule-type-vital-details">
                          {responseData.ruleType}
                        </CardSubtitle>                                            
                      </CardBody>
                    </Card>
                  </Col>
                )}
                
                <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
                  <Card className="card-design">
                    <CardBody className="card-body-remove-padding">                      
                      <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                        Occurred
                      </CardTitle>
                      <CardSubtitle className="reading-card-subtitle-details">
                        {" "}
                        {responseData?.createdAt
                          ? moment(responseData.createdAt).tz(moment.tz.guess()).format(
                              "MMMM DD, YYYY hh:mm A"
                            )
                          : "---"}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>          
          <Row style={{marginTop:5}}>
            <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
              <Card className="card-design">
                <CardBody className="card-body-remove-padding">
                  <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                    Patient Exception Message
                  </CardTitle>
                  <CardSubtitle className="reading-card-subtitle-details">
                    {responseData?.message ? responseData.message : "---"}
                  </CardSubtitle>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
              <Card className="card-design">
                <CardBody className="card-body-remove-padding">
                  <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                    CareProvider/Staff Exception Message
                  </CardTitle>
                  <CardSubtitle className="reading-card-subtitle-details">
                    {responseData?.expectation.messageCareprovider ? responseData.expectation.messageCareprovider : "---"}
                  </CardSubtitle>
                </CardBody>
              </Card>
            </Col>
            {lastTestResult && (
              <Col xs={12} sm={12} mt={4} lg={4} style={{padding:5}}>
                <Card className="card-design">
                  <CardBody className="card-body-remove-padding">
                    <CardTitle className="font-weight-bold card-title-vital-details" style={{fontSize:15}}>
                      Last Test Result
                    </CardTitle>
                    <CardSubtitle className="reading-card-subtitle-details">{lastTestResult}</CardSubtitle>
                  </CardBody>
                </Card>
              </Col>
            )}            
          </Row>
        </Col>
      </Row>
      {/* 
      <div className="col-12 page mx-auto bg-white py-5">
        <h2 className="text-center pb-5">{name} Exception</h2>
        <div className="row mb-4 text-center">
          <div className="media col-6">
            <div className="media-body">
              <UserName
                user={violatedBy}
                imgStyling={{
                  width: "150px",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                noMargin={true}
              ></UserName>
              <h2 className="mt-0">Patient ({violatedBy.subOrganization})</h2>
              <div>Phone: {violatedBy.phone}</div>
              <div>Email Address: {violatedBy.email}</div>
              <div>Gender: {violatedBy.gender}</div>
              <div>
                Main Care Provider: {createdBy.fname + " " + createdBy.lname}
              </div>
            </div>
          </div>
        </div>

        <table className="table mt-2">
          <thead></thead>
          <tbody>
            <tr>
              <th className="text-right">Exception Description:</th>
              <td>
                {responseData.name} ({responseData.ruleType})
              </td>
            </tr>
            <tr>
              <th className="text-right">Patient Exception Message:</th>
              <td>{responseData.message}</td>
            </tr>
            <tr>
              <th className="text-right">Reading SubType Monitored:</th>
              <td>{responseData.readingSubType}</td>
            </tr>
            {vitalException}
            <tr>
              <th className="text-right">Occurred:</th>
              <td>
                {moment(responseData.createdAt).format("MMMM DD, YYYY hh:mm A")}
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ careprovider, user, auth }) => {
  const { usersOfferedAccess, usersOfferedAccessOrganization } = careprovider;
  const role = user.userDetails.role;
  return {
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    role
  };
};

export default connect(mapStateToProps)(ViolationDetail);
