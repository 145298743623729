import React from "react";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import UserName from "../Reusable/UserName";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { notificationType } from "../../constants/general.constant";
const _ = require("lodash");

function fullname(cell, row) {
  if(cell){
    return <UserName user={cell} />;
  }else{
    return '-'
  }
}

function sentTo(cell, row){
  if(row){
    let value = '-'
    if(row.notificationType === notificationType.EMAIl){
      if(row?.otherData[0]?.recepientEmail){
        value = row?.otherData[0]?.recepientEmail;
      }else if(row?.recepient?.email){
       value = row?.recepient?.email;
      }
    }
    if(row.notificationType === notificationType.SMS || row.notificationType === notificationType.VOICE){
      if(row?.otherData[0]?.recepientPhone){
        value = row?.otherData[0]?.recepientPhone;
      }else if(row?.recepient?.phone){
       value = row?.recepient?.phone;
      }
    }if(row.notificationType === notificationType.INBOX){
        value = 'Inbox';
    }
    return value;
  }
}
const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {"   "}Showing {from} to {to} of {size} Results
  </span>
);
const NotificationLogs = (props) => {
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No notification logs record found :("}
          subTitle={"We can't find any notification log record."}
        />
      )}
    </div>
  );
  const options = {
    page: parseInt(props.notificationLogs.page || 0),
    sizePerPage: parseInt(props.notificationLogs.limit || 0),
    totalSize: parseInt(props.notificationLogs.totalCount || 0),
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.notificationLogs.totalCount) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.notificationLogs.totalCount) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "25",
        value: 25
      },
      {
        text: "50",
        value: 50
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "200",
        value: 200
      }
    ] // A numeric array is also available. the purpose of above example is custom the text
  };

  let columns = [
    {
      dataField: "messageDate",
      text: "Date and Time",
      formatter: (cell, row) => {
        return moment(row.messageDate).format("MMMM DD, YYYY hh:mm:ss A");
      },
      headerStyle: {
        width: '10%', wordBreak: "break-word"
      },
      sort: true
    },
    {
      dataField: "recepient",
      text: "Recepient",
      sort: true,
      formatter: fullname,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'left' };
      },
    },
    {
      dataField: "sender",
      text: "Sender",
      sort: true,
      formatter: fullname,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'left' };
      },
    },
    {
      dataField: "sendTo",
      text: "Sent to",
      sort: false,
      headerClasses: "ccmHeaderStyle",
      formatter: sentTo,
      headerStyle: {
        width: '10%', wordBreak: "break-word"
      },
    },
    {
      dataField: "refModel",
      text: "Rule type",
      headerClasses: "ccmHeaderStyle",
      formatter: (cell, row) => {
        return row.refModel ? row.refModel === 'OnBoarding' ? 'On Boarding' : row.refModel === 'OffBoarding' ? 'Off Boarding' : row.refModel === 'TimeAdherence' ? 'Time Adherence' : row.refModel === 'TrendingViolation' ? 'Trending Violation' :  row.refModel : '-';
      },
      headerStyle: {
        width: '10%'
      },
      sort: true
    },
    {
      dataField: "notificationType",
      text: "Notification type",
      headerClasses: "ccmHeaderStyle",
      formatter: (cell, row) => {
        return row.notificationType ? row.notificationType : "-";
      },
      headerStyle: {
        width: '10%', wordBreak: "break-word"
      },
      sort: true
    },
    {
      dataField: "body",
      text: "Message",
      sort: true,
      formatter: (cell, row) => {
        return row.body ? row.body : "-";
      },
      headerStyle: {
        width: '33%', wordBreak: "break-word"
      },
    },
    // {
    //   dataField: "notifyVisible",
    //   text: "Web / Mobile",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return row.notifyVisible === 'MobileApplication' ? 'Mobile' : row.notifyVisible === 'MvvDashboard' ? 'Web' : row.notifyVisible ?? "-";
    //   },
    //   headerStyle: {
    //     width: '7%', wordBreak: "break-word"
    //   },
    // },
  ]
  if (props.patientProfile) {
    columns = columns.filter(item => item.dataField !== 'recepient');
  }
  return (<div className="col-md-12 mx-auto mt-2 scrollbar-table-cls"> <BootstrapTable
    remote={{
      sort: false,
      pagination: true,
      filter: true,
      search: true
    }
    }
    wrapperClasses="table-responsive"
    keyField="_id"
    noDataIndication={() => <NoDataIndication />}
    data={props.notificationLogs?.data || []}
    pagination={options.page > 0 ? paginationFactory(options) : undefined}
    columns={columns}
    bordered
    onTableChange={props.pagination}
  /></div>)
}

export default NotificationLogs;