import React, { PureComponent } from "react";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import Swal from 'sweetalert2';
import moment from "moment";
import userImage from "../../files/user.png";
const _ = require("lodash");
class TestingFrequency extends PureComponent {
  constructor(props) {
    super(props);
    let confirmedLogs = [];
    if(this.props.profile && this.props.profile.confirmedLogs && this.props.profile.confirmedLogs.length > 0){
      confirmedLogs = this.props.profile.confirmedLogs;
    }
    const results = _.chain(confirmedLogs)
      .groupBy("key")
      .map(users => ({
        key: users[0].key,
        status: _.first(_.orderBy(users, ['createdAt'], ['desc'])).status,
      }))
      .value();
    this.state = {
      deviceFrequency: results ? _.find(results, model => model.key == "deviceFrequency") ? _.find(results, model => model.key == "deviceFrequency").status : false : false,
      confirmedLogs: confirmedLogs,
      allTestingFrequency: this.props.totalReadingFrequency,
      selectedType: "",
      selectedLogs: "",
    };
    this.handleCellEdit = this.handleCellEdit.bind(this);
    this.handleInputChangeForCheckBox = this.handleInputChangeForCheckBox.bind(this);
    this.toggleConfiredLogs = this.toggleConfiredLogs.bind(this);
    this.clickViewLogs = this.clickViewLogs.bind(this);
  }
  clickViewLogs() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }
  toggleConfiredLogs() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }
  /**
   * OnChange event for confired the information.
   * @param {*} event 
   */
  handleInputChangeForCheckBox(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var validation = false;
    var fieldName = false;
    if(value){
      if (!this.state.allTestingFrequency) {     
        fieldName = "prescribed testing frequency";
        validation = false;
      } else {
        validation = true;
      }
    } else {
      validation = true;
    }
    if(validation){
      const model = {
        createdBy: _.assign(
          _.pick(this.props.userDetails,[
            "fname",
            "lname",
            "email",
          ],
          ),{
            profileImage:this.state.profileImage,
            isActive:true
          }                
        ),
        createdAt: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        user: this.props.currentUserId,
        key: name,
        status: value,
      }
      this.setState({
        [name]: value,
        confirmedLogs:[...this.state.confirmedLogs, model]      
        
      }, () => {      
        this.props.dispatch({
          type: "DO_SAVE_PROFILE_INFO_LOGS",
          payload: {
            user: this.props.currentUserId,
            key: name,
            status: value,
          }
        });
      });
    } else {
      Swal.fire({
        title: 'Warning!',
        text: "Invalid value! Please Fill up at least one "+fieldName+ " field first, then the confirmed information.",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Okay'
      });
    }  
  }

  // Handle cell edits and send updated data to the backend
  handleCellEdit(newValue, row, column){
    // Make an API request to update the database with the edited data
    var performTest = row.performTest ?? "";
    var testingFrequency = row.testingFrequency ?? "";
    if(column.dataField == 'performTest'){
      performTest = newValue != "Select Perform Test" ? newValue : "";
    }else{
      testingFrequency = newValue != "Select Frequency" ? newValue : "";
    }
    this.props.dispatch({
      type: "DO_SAVE_PATIENT_FREQUENCY",
      payload: {
        readingType: row._id,
        user: this.props.currentUserId,
        performTest: performTest ?? "",
        testingFrequency: testingFrequency.toString(),
      }
    });
    setTimeout(() => {
      this.props.dispatch({
        type: "DO_GET_PATIENT_FREQUENCY",
        payload: {
          user: this.props.currentUserId,
        }
      });
      this.props.dispatch({
        type: "DO_GET_DEVICES",
        payload: {
          id: this.props.currentUserId,
        }
      });
    }, 1200);
  };
  componentDidMount(){
  }
  componentDidUpdate(){
    this.setState({
      allTestingFrequency : this.props.totalReadingFrequency
    });
  }
  render() {
    let testingFrequency = [];
    testingFrequency.push("Select Frequency");
    for(let i = 1; i <= 10; i++) {
      testingFrequency.push(i);
    };
    const performTest = ["Select Perform Test","Daily","Weekly","Monthly","Annually"];
    const readingFrequency = this.props.readingFrequency;
    return (
      <div
        className={this.props.patient ? (this.props.onCurrentLoginProfile ? "col-10 mx-auto mt-5" :  "col-12 mx-auto mt-5" ): "col-10 mx-auto"}>
        <Row className="header-device-list">
          <Col sm="4" className="center-label remove-table-padding">
            {this.props.hideTitle && (
              <h3 className="text-center title-device">
              <i class="mdi mdi-sign-text"></i> Prescribed Testing Frequency&nbsp;
            </h3>
            )}
          </Col>
          <Col sm="8">
            {this.props.patient && this.props.profile.role === "patient" && (
              <FormGroup className="remove-margin float-right">
                <Label for="defaultCheck1" className="form-check-label pr-4 margin-for-label">
                  Confirmed the information
                </Label>
                <Input
                  type="checkbox"
                  id="defaultCheck1"
                  name="deviceFrequency"
                  className="form-check-input mt-0 check-box-style margin-for-check-box"
                  checked={this.state.deviceFrequency}
                  onChange={this.handleInputChangeForCheckBox}
                  rows={10}
                />
                <div className="pull-right">
                  <a
                    onClick={() => {
                      this.setState({
                        confirmModal: !this.state.confirmModal,
                        selectedLogs: "deviceFrequency",
                        selectedType: "Prescribed Testing Frequency"
                      });
                    }}
                    className="btn btn-info ml-2 view-log-click-cls margin-for-button-bottom"
                    style={{ float: 'right' }}>
                    <i class="mdi mdi-account-card-details"></i>
                    Confirmed Logs
                  </a>
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
        {this.props.ongoingTestingFrequencyRequest && (
          <LottieLoader
           loadLoop={true}
           autoplayLoop={true}
           source={lottieLoaderFile}
           Lheight={150}
           Lwidth={150}
           subTitle={"Please Wait ..."}
         />
        )}
        {!this.props.ongoingTestingFrequencyRequest && (
          <div className="scrollbar-table-cls">
          <BootstrapTable
          wrapperClasses="table-responsive"
          keyField="id"
          filterPosition="top"
          data={readingFrequency && _.size(readingFrequency) > 0 ? readingFrequency : []}
          cellEdit={
            cellEditFactory({
            mode: 'click',
            blurToSave: false,
            })
          }
          noDataIndication={() => (
            <div>
              <LottieLoader
                  loadLoop={false}
                  autoplayLoop={true}
                  source={lottieEmptyFile}
                  Lheight={200}
                  Lwidth={200}
                  titleSmall={"Sorry! No Frequency found :("}
                  subTitleSmall={"We can't find any frequency."}
                />
            </div>
          )}
          columns={[
            {
              dataField: "description",
              text: "Reading Type",
              headerStyle: (colum, colIndex) => {
                return { 
                  padding: "16px 8px",
                  fontSize: "15px" 
                };
              },
              editable: false,
            },
            {
                dataField: "subTypes",
                text: "Reading Sub Type",
                headerStyle: (colum, colIndex) => {
                  return { 
                    padding: "16px 8px",
                    fontSize: "15px" 
                  };
                },
                formatter: (cell, row) => {
                  if(row.subTypes){
                    return row.subTypes;
                  }else{
                    return  <div className="text-center">-</div>;
                  }
                },
                editable: false,
              },
            {
                dataField: "performTest",
                text: "Perform Test",             
                headerStyle: (colum, colIndex) => {
                    return { 
                    padding: "16px 8px",
                    fontSize: "15px" 
                    };
                },   
                formatter: (cell, row) => {
                  if(row.performTest){
                    return  <div className="text-center">{row.performTest}</div>;
                  }else{
                    return  <div className="text-center">Select Perform Test</div>;
                  }
                },
                editor: {
                  type: Type.SELECT,
                  options: performTest
                },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                  return (
                    <select
                      className="form-control input-date-filter remove-bottom-margin"
                      value={value}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        this.handleCellEdit(newValue,row,column);
                      }}
                    >
                      {column.editor.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  );
                },
                sort: true
            },
            {
                dataField: "testingFrequency",
                text: "Testing Frequency",
                headerStyle: (colum, colIndex) => {
                    return { 
                    padding: "16px 8px",
                    fontSize: "15px" 
                    };
                },
                formatter: (cell, row) => {
                  if(row.testingFrequency){
                    return  <div className="text-center">{row.testingFrequency}</div>;
                  }else{
                    return  <div className="text-center">Select Frequency</div>;
                  }
                },
                editor: {
                  type: Type.SELECT,
                  options: testingFrequency
                },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                  return (
                    <select
                      className="form-control input-date-filter remove-bottom-margin"
                      value={value}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        this.handleCellEdit(newValue,row,column);
                      }}
                    >
                      {column.editor.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  );
                },
                sort: true
            }
          ]}
          filter={filterFactory()}
          />
          </div>
        )}
        <Modal
          style={{ width: "35%" }}
          isOpen={this.state.confirmModal}
          toggle={this.toggleConfiredLogs}
          className="modal-lg modal-dialog-centered"
        >
          <ModalHeader
            style={{ float: 'right' }}
            toggle={this.toggleConfiredLogs}
            className="close_button">
            <Label className="align-items-center justify-content-center text-center">Confirmation Logs - {this.state.selectedType}</Label>
            <button
              style={{ float: 'right' }}
              onClick={() => {
                this.toggleConfiredLogs()
              }}
              className="btn btn-info ml-2 close-click-cls"
            >
              <i class="mdi mdi-close" style={{
                fontSize: 20
              }}></i>
              Close
            </button>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
          <div className="scrollbar-table-cls">
            <BootstrapTable
              wrapperClasses="table-responsive"
              keyField='id'
              data={this.state.confirmedLogs ? _.orderBy(_.filter(this.state.confirmedLogs, model => model.key == this.state.selectedLogs), ['createdAt'], ['desc']) : []}
              noDataIndication={() => (
                <div>
                  <LottieLoader
                      loadLoop={false}
                      autoplayLoop={true}
                      source={lottieEmptyFile}
                      Lheight={200}
                      Lwidth={200}
                      titleSmall={"Sorry! No Confirmation Logs :("}
                      subTitleSmall={"We can't find any confirmation logs."}
                    />            
                </div>
              )}
              columns={[{
                dataField: 'createdBy',
                text: 'Modify By',
                formatter: (cell, row) => {
                  var link = cell.profileImage;
                  if(link && !link.indexOf("http") == 0 ) {                    
                    link = "/" + cell.profileImage
                  }
                  const profile = cell.profileImage ? link :  userImage;
                  return (
                    <div className={cell.isActive ? "" : " text-danger"}>
                      <span>
                        <img src={profile} className="mr-2" alt="image" />
                        {cell && (
                          <>
                            {cell.lname.charAt(0).toUpperCase() + cell.lname.slice(1)}
                            {" "}
                            {cell.fname.charAt(0).toUpperCase() + cell.fname.slice(1)}
                          </>
                        )}
                      </span>
                    </div>
                  );
                },
              }, {
                dataField: 'status',
                text: "Status",
                formatter: (cell, row) => {
                  return row.status ? <div className='text-center confirmed-style'><span>Confirmed</span></div> : <div className='text-center disconfirmed-style'><span>Unconfirmed</span></div>;
                },
                headerStyle: (colum, colIndex) => {
                  return { width: '150px', textAlign: 'center' };
                }
              }, {
                dataField: 'createdAt',
                text: 'Date',
                formatter: (cell, row) => {
                  return row.createdAt ? moment(row.createdAt).format("MMMM DD, YYYY hh:mm:ss A") : "";
                },
              }
              ]}
            />
          </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider }) => {
  const { readingFrequency,totalReadingFrequency, ongoingTestingFrequencyRequest, devices, deviceList } = careprovider;
  return {
    readingFrequency,
    totalReadingFrequency,
    devices,
    deviceList,
    ongoingTestingFrequencyRequest
  };
};

export default connect(mapStateToProps)(TestingFrequency);
