const notificationType = {
    INBOX : 'Inbox',
    SMS : 'Sms',
    VOICE : 'Voice',
    EMAIl : 'Email'
  }
  // this object for a notification logs filter in inbox table
  const ruleTypesNotificationLogs = [
    { label: 'On Boarding', value: "OnBoarding" },
    { label: 'Off Boarding', value: "OffBoarding" },
    { label: 'Reminder', value: "Reminder" },
    { label: 'Time Adherence', value: "Time Adherence" },
    { label: 'Vitals', value: "Vitals" },
    { label: 'Adherence', value: "Adherence" },
    { label: 'Weight', value: "Weight" },
    { label: 'Trending Violation', value: "Trending Violation" },
  ]
  
  const months = [
    { label: 'January', value: "1" },
    { label: 'February', value: "2" },
    { label: 'March', value: "3" },
    { label: 'April', value: "4" },
    { label: 'May', value: "5" },
    { label: 'June', value: "6" },
    { label: 'July', value: "7" },
    { label: 'August', value: "8" },
    { label: 'September', value: "9" },
    { label: 'October', value: "10" },
    { label: 'November', value: "11" },
    { label: 'December', value: "12" }
  ]
  
  const years = [
    { label: '2024', value: "2024" },
    { label: '2023', value: "2023" },
    { label: '2022', value: "2022" },
    { label: '2021', value: "2021" },
    { label: '2020', value: "2020" },
  ]
  
  const adminReportType = [
    { label: 'Select a Report', value: '' },
    { label: 'End of Month Report', value: '1' },
    { label: 'Notifications & Messages', value: '2' },
    { label: 'Version Report (Mobile App)', value: '3' }
  ]
  
  const dataConditions = [
    { label: 'Greater Than', value: '>=' },
    { label: 'Less Than', value: '<=' },
    { label: 'Between', value: 'between' },
    // { label: 'Not Between', value: 'notBetween' }
  ]

  // allow notification type
  const allowNotificationType = {
    SMS: 'Sms',
    EMAIl: 'Email',
    VOICE: 'Voice'
  }

  const primaryInsuranceProviders = [
    { value: "", label: 'None' },
    { value: "AETNA", label: 'AETNA' },
    { value: "CIGNA", label: 'CIGNA' },
    { value: "FHCP", label: 'FHCP' },
    { value: "HUMANA", label: 'HUMANA' },
    { value: "MEDICARE", label: 'MEDICARE' },
    { value: "TRICARE", label: 'TRICARE' },
    { value: "UNITED", label: 'UNITED' },
    { value: "UMR UNITED", label: 'UMR UNITED' },
    { value: "HEALTH FIRST", label: 'HEALTH FIRST' },
    { value: "WELL MED", label: 'WELL MED' },
    { value: "BLUE CROSS BLUE SHIELD", label: 'BLUE CROSS BLUE SHIELD' },
    { value: "FLORIDA BLUE", label: 'FLORIDA BLUE' },
    { value: "DEVOTED HEALTH", label: 'DEVOTED HEALTH' },
    { value: "OTHER", label: 'OTHER' }
  ]
  const patientsTab = {
    all : 'ALL',
    active : 'ACTIVE',
    demo : 'DEMO',
    inActive : 'IN_ACTIVE',
    cellurConnectivity : 'CELLUR_CONNENCTIVITY',
    needToFollowUp : 'NEED_TO_FOLLOW_UP',
    hospitalAdmission : 'HOSPITAL_ADMISSION',
    itIssue : 'IT_ISSUE',
    followUpReminders : 'FOLLOW_UP_REMINDERS',
    reminderSent : 'REMINDER_SENT',
    didNotQualifyLastMonth : 'DID_NOT_QUALIFY_LAST_MONTH'
  }
  const userStatus = [
    { label: 'Select a user status', value: '' },
    { value: "true", label: 'Active' },
    { value: "false", label: 'In Active' }
  ]
  const appStatus = [
    { label: 'Select a app status', value: '' },
    { value: 'New', label: 'New' },
    { value: 'Old', label: 'Old' }
  ]
  const devicePlatform = [
    { label: 'Select a platform', value: '' },
    { value: 'iOS', label: 'iOS' },
    { value: 'Android', label: 'Android' }
  ]
  const RPMOnBoardingCategory = [
   "64620873b1cebb9c4d5b70ea",
   "646208d0b1cebb9c4d5b70eb",
   "64f02ecb6d9efff481a93e67",
   "646208d3b1cebb9c4d5b70ec",
   "646208d6b1cebb9c4d5b70ed"
  ]
  const EHR_RPMOnBoardingCategory = [
    "673b0cc0ddc94130af8d513c",
    "673b0d0295dac2bd8a709055",
    "675c171c95826b52e5e689ec",
    "675c1a1295826b52e5e689ed",
    "673b0d7cddc94130af8d513e",
  ]
  const CCMOnBoardingCategory = [
    "65081c5b4e8e9173654eb40b",
    "65081cba4e8e9173654eb40c",
    "650a77061e37821fe76376dc",
  ]
  const RPMOffBoardingCategory = [
    "64620ebdb1cebb9c4d5b70f7",
    "667aa0e00bc7bcfe6b65aa0e",
    "64c781a345daedcb3562a359",
    "64c781b245daedcb3562a35a",
    "6705158ac1d421c2143a8632"
  ]

  const subjectOptionsList = [
    {value:"Adherence Request", label : "Adherence Request"},
    {value:"RPM Session", label : "RPM Session"},
    {value:"CCM Session", label : "CCM Session"},
    {value:"Patient Education", label : "Patient Education"},
    {value:"Need To Follow Up", label : "Need To Follow Up"},
    {value:"Follow Up Reminder", label : "Follow Up Reminder"},
    {value:"Need To Follow Up Completed", label : "Need To Follow Up Completed"},
    {value:"Patient was recently admitted to the Hospital", label : "Patient was recently admitted to the Hospital"},
    {value:"IT Issues", label : "IT Issues"},
    {value:"Other", label : "Other"}
  ]  
  const enrolledPrograms = [
    {value:"", label : "Select Enrolled Program"},
    {value:"CCM", label : "CCM"},
    {value:"RPM", label : "RPM"},
    {value:"CCM&RPM", label : "CCM&RPM"}
  ]
  // User status definitions
  const USER_STATUS = {
  APPROVED: 'Approved',
  };
  module.exports = {
    notificationType,
    ruleTypesNotificationLogs,
    months, 
    years,
    adminReportType,
    dataConditions,
    primaryInsuranceProviders,
    patientsTab,
    allowNotificationType,
    userStatus,
    devicePlatform,
    appStatus,
    subjectOptionsList,
    RPMOnBoardingCategory,
    EHR_RPMOnBoardingCategory,
    CCMOnBoardingCategory,
    RPMOffBoardingCategory,
    enrolledPrograms,
    USER_STATUS
  }
