import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { useSelector } from "react-redux";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import PatientList from "./PatientList";
import { Button,Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const _ = require("lodash");
function description(cell) {
  return <div className="font-weight-bold text-center">{cell}</div>;
}
function formatText(cell) {
  return <div className="font-weight-bold text-center">{cell}</div>;
}
//asdas
function priceFormatter(column, colIndex) {
  return (
    <p className="text-center"></p>
  );
}

function deleteButton(cell, row, rowIndex, extra) {
  return (
    <div className="text-center view-condition">
      <Button
          className="edit-click-condition"
          onClick={() => {
            extra.updateCondition(row, extra.patients)
          }}><i className="mdi mdi-pencil"></i> Edit </Button>
      <Button
          className="delete-note-condition"
          onClick={() => {
            extra.deleteCondition(row._id)
          }}><i className="mdi mdi-delete"></i> Delete </Button>
    </div>
  );
}

const ConditionTable = props => {

  const readingSubTypes = useSelector(state => state.auth.readingSubTypes);
  const expandRow = {
    renderer: row => {
      const patients = row.patients;
      return (
        <>
          <div style={{margin:15}}>
            <Card className="remove-card-shadow remove-card-radius card-condtions">
              <CardBody className='padding-card-body'>
                <PatientList
                  usersGivenAccessMyReading={patients}
                  readingSubTypes={readingSubTypes}
                />
              </CardBody>
            </Card>
          </div>
        </>

      );
    },
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: false
  };
  // Define custom sorting function
  const sortedArrayView = _.sortBy(props.conditions, (obj) => {
    var name = obj.condition;
    if (name.toLowerCase().includes("CUSTOM".toLowerCase())) return 1;    
    return 0; // For items without V, T, or W
  });
  return (
    <div className="scrollbar-table-cls">
    <BootstrapTable   wrapperClasses="table-responsive"
      bootstrap4
      filter={filterFactory()}
      keyField="_id"
      filterPosition="top"
      data={props.conditions ? _.orderBy(sortedArrayView, ['conditions'], ['acs']) : []}
      columns={[
        {
          dataField: "condition",
          text: "Condition Name",
          sort: true,
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by Condition"
          }),
          formatter: (cell, row) => {
            return <div className="font-weight-bold text-left">{cell}</div>;
          }
        },
        {
          dataField: "description",
          text: "Condition Description",
          filter: textFilter({
            className: 'input-date-filter',
            placeholder:"Search by Description"
          }),
          sort: true,
          formatter: (cell, row) => {
            return <div className="text-left">{cell}</div>;
          }
        },
        {
          dataField: "createdAt",
          text: "Action",
          formatter: deleteButton,
          headerStyle: (colum, colIndex) => {
            return { width: '150px', textAlign: 'center' };
          },
          formatExtraData: {
            updateCondition: props.updateCondition,
            deleteCondition: props.deleteCondition,
            patients: props.patients
          }
        }
      ]}
      expandRow={expandRow}
    />
    </div>
  );
};
export default ConditionTable;
