import React, { useState }from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { filter } from "lodash";
import SharedDetails from "./SharedDetails";
import familyAccount from "../../../files/ic_family_account.svg";
import LottieLoader from '../../../components/LottieLoader';
import lottieEmptyFile from '../../../files/animation/empty_new.json';
import lottieLoaderFile from '../../../files/animation/loading.json';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import DeleteUserComponent from "../../Reusable/DeleteUserComponent";
const _ = require("lodash");
const { SearchBar } = Search;
function fullname(cell, row) {
  return (
    <div className={row.user != undefined && row.user.isActive ? "" : " text-danger"}>
      <span>
        <img src={familyAccount} className="mr-2" alt="image" />
        {capitalize(row.lname)} {capitalize(row.fname)}
      </span>
    </div>
  );
}
function capitalize(cell) {
  if (cell) {
    return cell.charAt(0).toUpperCase() + cell.slice(1);
  }
}
function isApproved(cell, row) {
  return (
    <span>
      {cell ? (
        <label className="badge shared-account-created">ACCOUNT CREATED</label>
      ) : (
        <label className="badge badge-warning">PENDING INVITATION</label>
      )}
    </span>
  );
}
function formatDate(cell, row) {
  return moment(row.receivedBy.bday).format("MMMM DD, YYYY");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}
const rowComponent = (
  row,
  users,
  expectations,
  userInputChange,
  expectationInputChange,
  handleCareprovider,
  additionalCareproviders,
  handleAllPatients,
  ongoingAssignedUserRequest,
  handleAllAssignedCareproviders,
  userAssignedCareprovider,
  selectedOption,
  assignedAllUserUpdate,
  handleClearAllAssignedCareprovider
) => (
  <SharedDetails
    row={row}
    users={users}
    userInputChange={userInputChange}
    expectationInputChange={expectationInputChange}
    expectations={expectations}
    handleCareprovider={handleCareprovider}
    additionalCareproviderList={additionalCareproviders}
    handleAllPatients = {handleAllPatients}
    ongoingAssignedUserRequest={ongoingAssignedUserRequest}
    handleAllAssignedCareproviders={handleAllAssignedCareproviders}
    userAssignedCareprovider={userAssignedCareprovider}
    selectedOption={selectedOption}
    assignedAllUserUpdate={assignedAllUserUpdate}
    handleClearAllAssignedCareprovider={handleClearAllAssignedCareprovider}
  />
);
const SharedList = (props, context) => {
  const [deleteModal, setdeleteModal] = useState(false);
  const [userDetails, setuserDetails] = useState(null);
  const [deleteWarning, setdeleteWarning] = useState(false);
  const additionalCareproviders = props.additionalCareproviderList;
  function showButtons(cell, row, rowIndex, formatExtraData) {
    const fullname = row.lname + " " + row.fname;
    return (
      <div className='div-center'>
        { row.user && (
        <>
        <UncontrolledTooltip placement="bottom" target={"disablePatient" + row._id}>
          { row.user.isActive ? 'Disable' : 'Enable' } Account of {fullname}
        </UncontrolledTooltip>
        <button
          type="button"
          className={"btn-document " + (row.user.isActive ? "btn-warning" : "resetPassowrd-click" ) + " m-1"}
          onClick={() => {
            setdeleteModal(true);
            setuserDetails({
              fname: row.fname,
              lname: row.lname,
              userId: row.user._id,
              isCareProvider: true,
              profileImage: row.user.profileImage,
              isActive: row.user.isActive
            });
          }}
          id={"disablePatient" + row._id}
        >
          <i className={"mdi " + (row.user.isActive ? "mdi-account-off" : "mdi-account") } />
        </button>

        <UncontrolledTooltip placement="bottom" target={"deletePatient" + row._id}>
          Delete Account of {fullname}
        </UncontrolledTooltip>
        <button
          type="button"
          className="btn-document btn-danger m-1"
          onClick={() => {
            setdeleteModal(true);
            setdeleteWarning(true);
            setuserDetails({
              fname: row.fname,
              lname: row.lname,
              userId: row.user._id,
              isCareProvider: true,
              profileImage: row.user.profileImage,
              isActive: row.user.isActive
            });
          }}
          id={"deletePatient" + row._id}
        >
          <i className="mdi mdi-delete" />
        </button>
      </>
       )}
      {!row.isCreated && (        
        <Button
          className="account-delete-click"
          onClick={() => {
            formatExtraData.delete(row._id)
          }}><i className="mdi mdi-delete"></i> Delete
        </Button>
        )}
      </div>
    );
  }
  const initiateReset = () => {
    setdeleteModal(false);
    setuserDetails(null);
    setdeleteWarning(false);
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {"   "}Showing {from} to {to} of {size} Results
    </span>
  );
  const columns = [
    {
      dataField: "lname",
      text: "Name",
      formatter: fullname,
      sort: true,
      csvFormatter: (cell, row, rowIndex) => {
        return row.user ? ""+row.lname +" " + row.fname : "-";
      },
      filterValue: (cell, row) => {
        return row.lname + row.fname + row.organization;
      },
      headerFormatter: priceFormatter,
    },
    {
      dataField: "user.nickname",
      text: "Nickname",
      sort: true,
      csvFormatter: (cell, row, rowIndex) => {
        return (row.user ? (row.user.nickname ? row.user.nickname : "-") : "-");
      },
      headerStyle: (colum, colIndex) => {
        return { width: '280px', textAlign: 'center' };
      },
      formatter: (cell, row) => {
        return (row.user ? (row.user.nickname ? row.user.nickname : <div className="text-center">-</div>) : <div className="text-center">-</div>);
      },
      filterValue: (cell, row) => {
        return (row.user ? (row.user.nickname ? row.user.nickname : "-") : "-");
      },
      headerFormatter: priceFormatter,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,        
      headerFormatter: priceFormatter,
    },
    {
      dataField: "role",
      text: "Role",
      sort: true,
      formatter: capitalize,
      headerFormatter: priceFormatter,
    },

    {
      dataField: "isCreated",
      text: "Status",
      formatter: isApproved,
      headerStyle: (colum, colIndex) => {
        return { width: '100px', textAlign: 'center' };
      },
      csvExport: false,
      headerFormatter: priceFormatter,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: showButtons,
      headerFormatter: priceFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '100px', textAlign: 'center' };
      },
      csvExport: false,
      formatExtraData: {
        delete: props.deleteInvitation,
      },
    },
  ];
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "100",
        value: 100
      },
      {
        text: "All",
        value: props.totalSize
      }
    ]
  };
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No patient found :("}
          subTitle={"We can't find any patient."}
        />
      )}              
    </div>
  );
return (
  <Row className="col-sm-12 main-cls-modal remove-table-padding">
    <Col sm="12" className="mt-12">
    <div className="scrollbar-table-cls">
      <BootstrapTable
        remote={{ 
          sort: false,
          pagination:true,
          filter:true,
          search:true
         } 
        }
        keyField="_id"
        loading={props.loading}
        data={props.sharedAccount}
        columns={columns}
        pagination={paginationFactory(options)}
        onTableChange={props.onTableChange}
        noDataIndication={ () => <NoDataIndication /> }
        condensed
        bordered
        wrapperClasses="table-responsive"
        expandRow={{
          renderer: (row) =>
            rowComponent(
              row,
              filter(props.usersOfferedAccess, { isActive: true }),
              props.expectations,
              props.userInputChange,
              props.expectationInputChange,
              props.handleCareprovider,
              additionalCareproviders,
              props.handleAllPatients,
              props.ongoingAssignedUserRequest,
              props.handleAllAssignedCareproviders,
              props.userAssignedCareprovider,
              props.selectedOption,
              props.assignedAllUserUpdate,
              props.handleClearAllAssignedCareprovider
            ),
          onlyOneExpanding: true,
          showExpandColumn: true,
        }}
      />
    </div>
      {userDetails && (
        <DeleteUserComponent
          deleteModal={deleteModal}
          initiateReset={initiateReset}
          section="sharedFamilyList"
          userDetails={userDetails}
          deleteWarning={deleteWarning}
          dataSize={props.totalSize}
          page={props.page}
          limit={props.sizePerPage}
        ></DeleteUserComponent>
      )}
    </Col>
  </Row>
);
};

export default SharedList;