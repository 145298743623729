import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-datetime/css/react-datetime.css";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { TrendingViolationBody } from "./configBody";
import Heading from "./Heading";
import RequiredSelect from "./RequiredSelect";
import { TrendingIcon } from "./RuleIcon";

class TrendingViolation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      emailOffer: "",
      emailAccess: "",
      showAlertSuccess: false,
      myRules: "",
      countTime: 1,
      timezone: { label: moment.tz.guess(), value: moment.tz.guess() },
      body: TrendingViolationBody
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTimezone = this.handleTimezone.bind(this);
    this.handleUsers = this.handleUsers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
  }
  handleTimezone = (selectedOption) => {
    this.setState({
      body: {
        ...this.state.body,
        timezone: selectedOption.value
      },
      timezone: selectedOption
    });

    
  };
  handleChange = (selectedOption) => {
    this.setState({ rule: selectedOption });
  
    this.setState({
      body: {
        ...this.state.body,
        rule: { ruleId: selectedOption._id, ruleType: selectedOption.ruleType }
      }
    });
  };
  submit(e) {
    e.preventDefault();

    if (
      this.state.body.notifyReadingOwner.length > 0 &&
      this.state.body.message.trim().length === 0
    ) {
      toast.error(
        "A notification is checked in notify reading owner, please provide a message for the Patient"
      );
    } else if ((this.state?.body.notifyStaff.length > 0 ||
        this.state.body.notifySelf.length > 0) && this.state.body?.messageCareprovider?.trim().length === 0) {
      toast.error(
        "A notification is checked in notify me/staff, please provide a message for the care prodiver/Staff"
      );
    } else {
      this.props.dispatch({
        type: "DO_SAVE_TRENDING_VIOLATION_RULE",
        payload: this.state.body
      });
    }
  }
  handleCondition = (selectedOption) => {
    this.setState({ condition: selectedOption });
    this.setState({
      body: {
        ...this.state.body,
        repeatPer: selectedOption.value
      }
    });
  };
  handleConditions = (selectedOption) => {
    
    this.setState({
      body: {
        ...this.state.body,
        conditionGroup: _.map(selectedOption, "_id")
      }
    });
  };
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;

    const name = target.name;
    const notifySelf = this.state.body.notifySelf;
    const notifyReadingOwner = this.state.body.notifyReadingOwner;
    if (target.type === "checkbox") {
      let index;
      if (target.checked) {
        // add the numerical value of the checkbox to options array
        if (name === "notifySelf") {
          notifySelf.push(event.target.value);
        } else {
          notifyReadingOwner.push(event.target.value);
        }
      } else {
        // or remove the value from the unchecked checkbox from the array
        if (name === "notifySelf") {
          index = notifySelf.indexOf(event.target.value);
          notifySelf.splice(index, 1);
        } else {
          index = notifyReadingOwner.indexOf(event.target.value);
          notifyReadingOwner.splice(index, 1);
        }
      }
      this.setState({
        body: {
          ...this.state.body,
          notifySelf: notifySelf,
          notifyReadingOwner: notifyReadingOwner
        }
      });
      return;
    }

    if (name == "maxValue" || name == "minValue" || name == "value") {
      this.setState({
        body: {
          ...this.state.body,
          valueProperty: { ...this.state.body.valueProperty, [name]: value }
        }
      });
      return;
    }
    this.setState({ body: { ...this.state.body, [name]: value } });
  }

  handleUsers = (selectedOption) => {
    this.setState({ sharedUsers: selectedOption });
    // const receivedFrom = _.map(selectedOption, "receivedFrom");
    this.setState({
      body: {
        ...this.state.body,
        sharedUsers: _.map(selectedOption, "_id")
      }
    });

    
  };

  componentDidMount() {
    if (!this.props.isCareProvider) {
      this.setState({
        body: {
          ...this.state.body,
          sharedUsers: [this.props.userId]
        }
      });
    }
    this.props.dispatch({
      type: "GENERIC_ERROR",
      payload: { isGenericSuccess: false }
    });
    const timezoneList = moment.tz.names();
    const mapTimezones = _.map(timezoneList, (v) => {
      return { label: v, value: v };
    });
  
    this.setState({ timezoneList: mapTimezones });
  }

  render() {
    const removeTrending = _.dropRightWhile(this.props.myRules, {
      ruleType: "Trending Violation"
    });
    return (
      <div>
        <div className="row">
          <Heading
            backbutton="d-flex"
            mainbutton="d-none"
            isCareProvider={this.props.isCareProvider}
            title="Monitor Trending Violation Triggered x times Per x"
          >
            <img
              src={TrendingIcon}
              className="mr-2"
              style={{ width: "40px", height: "40px" }}
              alt="image"
            />
          </Heading>
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body project-tab  pt-2 mt-4">
                <Form onSubmit={this.submit}>
                  <FormGroup>
                    <Label for="exampleSelect">Select a Timezone</Label>
                    <RequiredSelect
                      SelectComponent={Select}
                      value={this.state.timezone}
                      onChange={this.handleTimezone}
                      options={this.state.timezoneList}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Expectation Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="exampleEmail"
                      required={true}
                      onChange={this.handleInputChange}
                      placeholder="Expectation Name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Patient)
                    </Label>
                    <Input
                      type="textarea"
                      name="message"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleText">
                      Notification Message (Careprovider)
                    </Label>
                    <Input
                      type="textarea"
                      name="messageCareprovider"
                      onChange={this.handleInputChange}
                      rows={10}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleSelect">Pick a Expectation</Label>
                    <RequiredSelect
                      SelectComponent={Select}
                      required={true}
                      value={this.state.rule}
                      onChange={this.handleChange}
                      getOptionLabel={(option) => option["name"]}
                      getOptionValue={(option) => option["_id"]}
                      options={removeTrending}
                    />
                  </FormGroup>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          Number of Times Violation Triggered
                        </Label>
                        <Input
                          type="number"
                          name="numberOfTimes"
                          onChange={this.handleInputChange}
                          placeholder="Number of Times"
                          onWheel={(e) => e.target.blur()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleSelect">Every</Label>
                        <Select
                          required={true}
                          value={this.state.condition}
                          onChange={this.handleCondition}
                          options={[
                            { value: "hour", label: "Hour" },
                            { value: "day", label: "Day" },
                            { value: "week", label: "Week" },
                            { value: "month", label: "Month" }
                          ]}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.props.isCareProvider && (
                    <>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Condition to Monitor
                        </Label>
                        <RequiredSelect
                          SelectComponent={Select}
                          // value={this.state.sharedUsers}
                          onChange={this.handleConditions}
                          options={this.props.conditions}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            `${option["condition"]}  (${option["description"]})`
                          }
                          getOptionValue={(option) => option["_id"]}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="exampleSelect">
                          Select a Patient to Monitor
                        </Label>
                        <Select
                          value={this.state.sharedUsers}
                          onChange={this.handleUsers}
                          options={this.props.usersOfferedAccess}
                          isMulti={true}
                          getOptionLabel={(option) =>
                            option["receivedFrom"]["fname"] +
                            " " +
                            option["receivedFrom"]["lname"]
                          }
                          getOptionValue={(option) => option["receivedFrom"]}
                          isLoading={this.props.usersOfferedAccess ? false : true}
                          isDisabled={this.props.usersOfferedAccess ? false : true}
                        />
                      </FormGroup>
                    </>
                  )}
                  {this.props.isCareProvider && (
                    <FormGroup>
                      <Label for="exampleCheckbox">Notify Me</Label>
                      <div>
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          value="inbox"
                          label="Inbox"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline2"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="SMS"
                          value="sms"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline3"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="VOICE"
                          value="voice"
                          inline
                        />
                        <CustomInput
                          type="checkbox"
                          id="exampleCustomInline4"
                          name="notifySelf"
                          onChange={this.handleInputChange}
                          label="EMAIL"
                          value="email"
                          inline
                        />
                      </div>
                    </FormGroup>
                  )}

                  <FormGroup>
                    {this.props.isCareProvider && (
                      <Label for="exampleCheckbox">Notify Readings Owner</Label>
                    )}

                    <div>
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline6"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        value="inbox"
                        label="Inbox"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline7"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="SMS"
                        value="sms"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline8"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="VOICE"
                        value="voice"
                        inline
                      />
                      <CustomInput
                        type="checkbox"
                        id="exampleCustomInline9"
                        name="notifyReadingOwner"
                        onChange={this.handleInputChange}
                        label="EMAIL"
                        value="email"
                        inline
                      />
                    </div>
                  </FormGroup>

                  <Button
                    type="submit"
                    className="btn btn-info btn-lg btn-block"
                  >
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={() => {
            this.setState({
              modal: false,
              modal2: false
            });
            this.props.dispatch({
              type: "GENERIC_ERROR",
              payload: { isGenericSuccess: false }
            });
            this.props.history.goBack();
          }}
          confirmBtnBsStyle="info"
        >
          Submitted Successfully
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR"
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    readingSubTypes,
    usersOfferedAccess,
    myRules,
    isGenericSuccess,
    isGenericError,
    conditions,
    genericErrorMessage
  } = careprovider;
  const userId = user.userDetails._id;
  const isCareProvider = user.userDetails.isCareProvider;
  return {
    readingSubTypes,
    usersOfferedAccess,
    userId,
    myRules,
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    conditions,
    isCareProvider
  };
};

export default connect(mapStateToProps)(TrendingViolation);
